import { Button, CircularProgress, FormControlLabel, IconButton, Switch, Tooltip, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { DownloadForOffline } from "@mui/icons-material";
import fileDownload from "js-file-download";
import moment from "moment";
import SubmitButton from "../../components/button/SubmitButton";
import { callApiAction } from "../../store/actions/commonAction";
import {salarySlipPdfApi} from "../../apis/payroll.api"
import { callSnackBar } from "../../store/actions/snackbarAction";
import { SNACK_BAR_VARIETNS } from "../../utils/constants";
import { closeModal } from "../../store/actions/modalAction";

const DownloadSalarySlipListPdf = ({ date , emp_id }) => {

    const {user} = useSelector(state => state)

    const [loading, setLoading] = useState(false)

    const dispatch = useDispatch()

    const onClick = () => {
        setLoading(true);
        dispatch(
            callApiAction(
                async () => await salarySlipPdfApi({ id: emp_id ,date : date.toISOString()}),
                (response) => {
                    setLoading(false);
                    console.log(response,"response in pdf ")
                    if (response.status === 0 || response.code === 400 || response.message === "no salary slip found") 
                    {
                        dispatch(callSnackBar("No salary slip found", SNACK_BAR_VARIETNS.error));
                    } 
                    else 
                    {
                        fileDownload(response, (emp_id) + "-salaryslip.pdf");
                        dispatch(callSnackBar("Pdf Downloaded Successfully", SNACK_BAR_VARIETNS.suceess))
                        dispatch(closeModal())
                    }
                },
                (err) => {
                    setLoading(false);
                    dispatch(callSnackBar("Cant download Pdf", SNACK_BAR_VARIETNS.error))
                },
                true
            )
        );
    }
    return   <Tooltip title="Download PDF" >
    <IconButton  sx={{color:"#1E90FF"}}  onClick={onClick}>
    {loading ? <CircularProgress size={24} /> : <DownloadForOffline />}
    </IconButton>
    </Tooltip> 
    
}
export default DownloadSalarySlipListPdf