import { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { callApiAction } from "../../store/actions/commonAction"
import AdvancePaymentsUI from "./AdvancePaymentsUI"
import {  getAdvancePaymentsListApi } from "../../apis/reimbersement.api"
import moment from "moment"
import { findObjectKeyByValue, titleCase } from "../../utils/helper"
import { REIMBURSEMENT_ADVANCE_PAYMENT_STATUS } from "../../utils/constants"
import { Box, Chip } from "@mui/material"
import AddAmountButton from "./AddAmountButton"
import SettlePaymentButton from "./SettlePaymentButton"
import ViewMoreButton from "./ViewMoreButton"
import DownloadAdvancePaymentPdfButton from "./DownloadAdvancePaymentPdfButton"
import { fetchAdvancePaymentDataAction } from "../../store/actions/reimbursementAction"

const AdvancePaymentsController = ({ userId }) => {
    const user = useSelector(state => state.user)
    const dispatch = useDispatch()

    const [state, setState] = useState({})
    const [loading, setLoading] = useState(false)

    const {reimbursement} = useSelector((state)=> state) 


    const columns = useMemo(() => [



        { id: 2, fieldName: 'payment_date', label: 'Date', align: "left", sort: true, minWidth: '150px', renderValue: (params) => moment(params.payment_date).format("DD/MM/YYYY") },
        { id: 4, fieldName: 'user_id', label: 'Employee Name', align: "left", minWidth: '150px', renderValue: (params) => params.user_id?.first_name ? titleCase(params.user_id?.first_name + " " + params.user_id?.last_name) : "You" },
        { id: 4, fieldName: 'purpose', label: 'Purpose', align: "left", minWidth: '150px' },
        { id: 1, fieldName: 'amount', label: 'Claimed Amount', sort: true, align: "left", sort: true, minWidth: '150px' },
        { id: 5, fieldName: 'used_amount', label: 'Used Amount', align: "left", minWidth: '150px' },
        { id: 7, fieldName: 'used_amount', label: 'Remained Amount', align: "left", minWidth: '150px', renderValue: (params) => Number(params.amount) - Number(params.used_amount ?? 0) },
        { id: 3, fieldName: 'status', label: 'Status', align: "left", sort: true, minWidth: '150px', renderValue: (params) => <Chip size="small" label={findObjectKeyByValue(params.status, REIMBURSEMENT_ADVANCE_PAYMENT_STATUS)} color={params.status == REIMBURSEMENT_ADVANCE_PAYMENT_STATUS.CLOSE ? "success" : "warning"} /> },
        {
            id: 6, fieldName: 'action', label: 'Action', align: "left", renderValue: (params, setParams) => <Box sx={{ display: "flex" }} >
          <ViewMoreButton params={params} setParams={setParams} />
                <AddAmountButton params={params} setParams={setParams} />
                <SettlePaymentButton params={params} setParams={setParams} />
                <DownloadAdvancePaymentPdfButton params={params}  />
            </Box>
        },

    ], [dispatch]);
    const [filters, setFilters] = useState({
        pageSize: 10,
        pageNo: 1,
        sort: "payment_date",
        sortDirection: -1,
        status: null
    })

    // const fetchList = () => {
    //     setLoading(true)
    //     dispatch(callApiAction(
    //         async () => await getAdvancePaymentsListApi(filters),
    //         (response) => {
    //             setState(response)
    //             setLoading(false)
    //         },
    //         (err) => {
    //             setLoading(false)
    //         }
    //     ))
    // }

    const getAdvancePaymentList = () => {
        if (!reimbursement.advance_payment_data || reimbursement.advance_payment_data.length === 0 || JSON.stringify(filters)!=JSON.stringify(reimbursement.advance_payment_filters)) {
            dispatch(fetchAdvancePaymentDataAction(filters));
        }
    }
    useEffect(() => {
        getAdvancePaymentList()
        // fetchList()
    }, [filters])

    return <AdvancePaymentsUI columns={columns} filters={filters} setFilters={setFilters}  callBack={getAdvancePaymentList} loading={reimbursement.advance_payment_loading} state={reimbursement.advance_payment_data} />
}
export default AdvancePaymentsController