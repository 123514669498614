import { actions } from "../../utils/constants"
import { callApiAction } from "./commonAction"
import {getUserApi} from "../../apis/user.api"
import {getInterviewCandidateApi} from "../../apis/interview.api"
import {getDocumentListApi} from "../../apis/resignation.api"


export const fetchUsersDataAction = (filters, onSuccess=()=>{}, onError=()=>{}) => {
    return async (dispatch, getState) => {
        dispatch({ type: actions.START_USERS_LOADING })
        dispatch(callApiAction(
            async () => await getUserApi(filters),
            (response) => {
                dispatch({ type: actions.SET_USERS_DATA, value: {data: response, filters: filters} })
                onSuccess(response)
            },
            (err) => {
                onError(err)
            }
        ))
    }
}

export const fetchInterviewDataAction = (filters, onSuccess=()=>{}, onError=()=>{}) => {
    return async (dispatch, getState) => {
        dispatch({ type: actions.START_INTERVIEW_LOADING })
        dispatch(callApiAction(
            async () => await getInterviewCandidateApi(filters),
            (response) => {
                dispatch({ type: actions.SET_INTERVIEW_DATA, value: {data: response, filters: filters} })
                onSuccess(response)
            },
            (err) => {
                onError(err)
            }
        ))
    }
}
export const setInterviewDataOnUpdateAction = (data, filters) => {
    return async (dispatch, getState) => {
      dispatch({type : actions.SET_INTERVIEW_DATA_ONUPDATE, value : {data: data, filters: filters}})
    }
}

export const fetchResignationDataAction = (filters, onSuccess=()=>{}, onError=()=>{}) => {
    return async (dispatch, getState) => {
        dispatch({ type: actions.START_RESIGNATION_LOADING })
        dispatch(callApiAction(
            async () => await getDocumentListApi(filters),
            (response) => {
                dispatch({ type: actions.SET_RESIGNATION_DATA, value: {data: response, filters: filters} })
                onSuccess(response)
            },
            (err) => {
                onError(err)
            }
        ))
    }
}

export const setResignationDataOnUpdateAction = (data, filters) => {
    return async (dispatch, getState) => {
      dispatch({type : actions.SET_RESIGNATION_DATA_ONUPDATE, value : {data: data, filters: filters}})
    }
}
