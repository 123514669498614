import {
  Box,
  Button,
  CircularProgress,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import moment from 'moment'
import { CenteredBox } from '../../../components/layouts/common/boxes'
import { Link } from 'react-router-dom'
import CustomDayPicker from '../../../components/layouts/common/CustomDayPicker'
import { Add, ListAlt } from '@mui/icons-material'

const ListContainer = styled(Box)(({ theme }) => ({
  width: "100%",


}))

const ListItemContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  overflowX: "auto",
  background: theme.palette.grey[200],
  width: "100%",
  borderRadius: 1,
  "*": {
    "ul": {
      paddingLeft: theme.spacing(4)
    },
    "ol": {
      paddingLeft: theme.spacing(4)
    },
    "b": {
      fontWeight: "bold"
    },
    "a": {
      color: "blue",
      cursor: "pointer"
    },

    "td": {
      border: "1px solid black",
      minWidth: "100px"
    }
  }

}))

const DailyUpdateUi = ({

  list,
  loading,
  filters,
  setFilters,
  onCreateButtonClick
}) => {


  const date = filters.date
  const setDate = (newDate) => { setFilters({ ...filters, date: newDate }) }

  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('sm'));
  return (
    <>

      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", overflow: "hidden" }}>

        <CustomDayPicker date={date} setDate={setDate} />

        <Box>

          <Button
to="../daily-update-report"
            LinkComponent={Link}
            sx={{ ml: 2 }}

            variant='outlined'
          >
            <ListAlt color="primary" />
            {
              desktop && "Summary"
            }
          </Button>
          {(date.valueOf() <= moment().valueOf() && date.valueOf() >= moment().add(-2, "days").valueOf()) && <Button

            onClick={onCreateButtonClick}
            sx={{ ml: 2 }}

            variant='outlined'
          >
            <Add color="primary" />
            {
              desktop && "Add Update"
            }
          </Button>}
        </Box>

      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          overflow: "hidden",
          position: 'relative',
          width: "100%"
        }}
        maxHeight="100%"
      >
        {
          loading && <CenteredBox><CircularProgress></CircularProgress></CenteredBox>
        }
        {!loading && <ListContainer>
          {list.length > 0 ? (
            list.map((item) => {
              return (
                <ListItemContainer mt={3}>
                  <Box dangerouslySetInnerHTML={{
                    __html: item.description,
                  }} sx={(theme) => ({})} p={2}>

                  </Box>

                </ListItemContainer>
              )
            })
          ) : (
            <CenteredBox
              mt={5}
              mb={5}
            >
              <Typography variant="h3" >
                No Updates Found
              </Typography>
            </CenteredBox>
          )}
        </ListContainer>}
      </Box>
    </>
  )
}
export default DailyUpdateUi
