import { useState } from "react"
import { TASK_STATUS } from "../../../../utils/task.constants"
import { CircularProgress, IconButton } from "@mui/material"
import { Delete } from "@mui/icons-material"
import { useDispatch, useSelector } from "react-redux"
import { deleteTaskApi } from "../../../../apis/task.api"
import { callApiAction } from "../../../../store/actions/commonAction"
import { callSnackBar } from "../../../../store/actions/snackbarAction"
import { SNACK_BAR_VARIETNS } from "../../../../utils/constants"

const TaskDeleteButtoon = ({ params, setParams }) => {
    const { user } = useSelector(state => state)
    const dispatch = useDispatch()

    const [loading, setLoading] = useState(false)
    const id = params._id

    const assignedTo = params.assigned_to?._id ? params.assigned_to?._id : params.assigned_to
    const allowedToUpdateOrDelete = assignedTo != user.data._id && params.status == TASK_STATUS.PENDING


    const onDelete = () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await deleteTaskApi({ id }),
            (response) => {

                setLoading(false)
                setParams({})
                dispatch(callSnackBar("Task Deleted Successfully", SNACK_BAR_VARIETNS.suceess))
            },
            (err) => {
                setLoading(false)
                dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error))
            }
        ))
    }
    if (loading) {
        return <CircularProgress size={20} />
    }
    if (!id  || !allowedToUpdateOrDelete|| params.status != TASK_STATUS.PENDING) {
        return <></>
    }

    return <IconButton onClick={onDelete}>
        <Delete color="error" />
    </IconButton>
}
export default TaskDeleteButtoon