import axios from "axios";
import { getHeaders } from "../utils/helper";
import endpoints from "./endpoints";



export const getNotifcationsApi = async params => {
    const callResponse = await axios({
        url: endpoints.notificationFetch,
        method: "get",
        headers: getHeaders(),
        params,
    })
        .then(response => response.data)
        .catch(err => ({status:0,response:err.response,code:err.response.status}));

    return callResponse;
};

export const readNotifcationsApi = async data => {
    const callResponse = await axios({
        url: endpoints.notificationBase,
        method: "PATCH",
        headers: getHeaders(),
        data,
    })
        .then(response => response.data)
        .catch(err => ({status:0,response:err.response,code:err.response.status}));

    return callResponse;
};