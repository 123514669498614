import {  Info } from "@mui/icons-material"
import {  IconButton } from "@mui/material"
import { useDispatch } from "react-redux"
import { closeModal, openModal } from "../../store/actions/modalAction"

import AdvancePaymentDetailsController from "./AdvancePaymentDetailsController"


const ViewMoreButton = ({ params, setParams }) => {
    const dispatch = useDispatch()
    

   
    const onClick = () => {
        dispatch(openModal({
            title:"Payment Details!",
            component:<AdvancePaymentDetailsController payment_id={params._id} />,
            
            size:"lg",
            
            onCancle:()=>dispatch(closeModal())
        }))
    }
    
    
    return <IconButton onClick={onClick}>
        <Info color="info" />
    </IconButton>
}
export default ViewMoreButton