import { actions } from "../../utils/constants";

const intitialState = {
    team_loading : false,
    team_data : {},
    team_filters : {},
}
const teamReducer = (state = intitialState, action)  => {
 switch(action.type) { 
    case actions.START_TEAM_LOADING : return {...state, team_loading : true, team_data : []};
    case actions.SET_TEAM_DATA : return {...state, team_loading : false, team_data : action.value.data, team_filters : action.value.filters}
    default : return {...state};
 }
}

export default teamReducer