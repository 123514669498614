import React, { useEffect, useState } from "react";
// import { createHoliday } from "../../apis/holiday.api";
import { useDispatch, useSelector } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";
import AddFeedbackFormUI from "./AddFeedbackFormUi";
import { fetchLeaveTypes, createLeaveType } from "../../apis/leaveType.api";
import { closeModal } from "../../store/actions/modalAction";
import { fetchLeaveTypeDataAction } from "../../store/actions/settingsAction";
import { createFeedback, fetchFeedback } from "../../apis/feedbackForm.api";

const AddFeedbackFormController = ({ callBack = () => { } }) => {
  const dispatch = useDispatch();
  const createFeedbackTypeApi = createFeedback;
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [feedbacktype, setFeedbacktype] = useState(null);
  const [name, setName] = useState("");
  const [priority, setPriority] = useState("");
  const [err, setErr] = useState("");

  const fetchFeedbacksApi = fetchFeedback;

  const addFeedback = (e) => {
    e.preventDefault();
    setLoading(true);

    const data = { name, title, description, feedbacktype };

    dispatch(
      callApiAction(
        async () => await createFeedbackTypeApi(data),
        async (response) => {
          await callBack(response);
          dispatch(closeModal());
          setLoading(false);
          // fetchFeedbacksApi();
        },
        (err) => {
          setLoading(false);
          setErr(err);
        }
      )
    );
  };

  // const fetchList = () => {
  //   setLoading(true);
  //   dispatch(
  //     callApiAction(
  //       async () => await fetchFeedbacksApi(),
  //       (response) => {
  //         setFeedback(response.result);
  //         setLoading(false);
  //       },
  //       (err) => {
  //         setLoading(false);
  //       }
  //     )
  //   );
  // };

  useEffect(() => {
    fetchFeedbacksApi()
  }, []);

  return (
    <AddFeedbackFormUI
    err={err}
      loading={loading}
      addFeedback={addFeedback}
      name={name}
      setName={setName}
      title={title}
      setTitle={setTitle}
      description={description}
      setDescription={setDescription}
      feedbacktype={feedbacktype}
      setFeedbacktype={setFeedbacktype}
      priority={priority}
      setPriority={setPriority}
    />
  );
};

export default AddFeedbackFormController;
