// import { fetchStockCount } from "../../apis/inventory.api"
import moment from "moment"
import { getLeaveCounts } from "../../apis/leave.api"
import { actions } from "../../utils/constants"
import { callApiAction } from "./commonAction"

export const fetchLeaveBalanceCountAction = (data, onSuccess=()=>{}, onError=()=>{}) => {
    return async (dispatch, getState) => {
        dispatch({ type: actions.START_LEAVE_BALANCE_COUNT_LOADING })

        dispatch(callApiAction(
            async () => await getLeaveCounts(data),
            (response) => {
                dispatch({ type: actions.SET_LEAVE_BALANCE_COUNT_DATA, value: {data : response, date: moment(data.date), userId : data.userId} })

                onSuccess(response)
            },
            (err) => {

                onError(err)
            }
        ))
    }
}

