import React, {  useState } from 'react'
import {Box } from '@mui/material'
import { useDispatch } from 'react-redux'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { createDailyUpdate } from '../../../apis/dailyupdate.api'
import { closeModal } from '../../../store/actions/modalAction'
import { callSnackBar } from '../../../store/actions/snackbarAction'
import SubmitButton from '../../../components/button/SubmitButton'

function DailyUpdateEditor({ date,  callBack = () => { } }) {
  
  const [data, setData] = useState('')

  const [loading, setloading] = useState(false)

  const dispatch = useDispatch()
  const onSubmit = async (e) => {
    e.preventDefault()
    console.log(date);
    setloading(true)
    const response = await createDailyUpdate({
      description: data.toString(),
      date,
      
    })
    
    if (response.status === 1) {
      
      callBack()    
      dispatch(callSnackBar('Daily Update Added', "success"))
      dispatch(closeModal())
    }
    setloading(false)
  }

  const OnClose = () => {
    dispatch(closeModal())
  }

  return (
    <Box className="App" >
     

      <Box component={'form'} onSubmit={onSubmit}>
        <CKEditor
          disabled={loading}
          editor={ClassicEditor}
          style={{ minHeight: '500px' }}
          config={{
                                placeholder: "Add Update...",
                                style: { height: "500px", minHeight: "500px" },
                                toolbar: { items:['p','heading','italic','bold','blockquote','link','table','undo','redo','numberedList','bulletedList']}
                                
                            }}

          onInit={(editor) => {
            
            
        }}
          data=""
          onReady={(editor) => { 

            editor.editing.view.change((writer) => {
              writer.setStyle(
                  "height",
                  "200px",
                  editor.editing.view.document.getRoot()
              );
              });
          }}
          onChange={(event, editor) => {
            const data = editor.getData()
            setData(data)
          }}
          onBlur={(event, editor) => { }}
          onFocus={(event, editor) => { }}
        />
        <Box mt={2}>
            <SubmitButton
              disabled={loading}
              type="submit"
              variant="contained"
             
              title="Add"
            >
              
            </SubmitButton>
            </Box>
      </Box>
    </Box>
  )
}

export default DailyUpdateEditor
