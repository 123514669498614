import { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getUserByIdApi, updateUserField } from "../../apis/user.api"
import { callApiAction } from "../../store/actions/commonAction"
import { loggedInUser } from "../../utils/helper"
import { fetchBranches, deleteBranch } from "../../apis/branch.api"
import BranchUI from "./BranchUi"
import DeleteBranchButton from "./DeleteBranchButton"
import { Box } from "@mui/material"
import UpdateBranchButton from "./UpdateBranchButton"
import { fetchBranchDataAction } from "../../store/actions/settingsAction"

const BranchController = ({ userId }) => {
    const user = useSelector(state => state.user)
    const dispatch = useDispatch()

    const [state, setState] = useState([])
    const [loading, setLoading] = useState(false)
    const {settings} = useSelector((state) =>  state)
    console.log("settingsReducer", settings)
    
    const fetchBranchesApi = fetchBranches    


    const columns = useMemo(() => [
        { id: 1, fieldName: 'name', label: 'Name', align: "left", sort: true, minWidth: '150px' },
        { id: 2, fieldName: 'address', label: 'Address', align: "left" },
        {
            id: 3, fieldName: 'action', label: 'Action', align: "left", renderValue: (params, setParams) => <Box sx={{ display: "flex" }}>
                <UpdateBranchButton params={params} setParams={setParams} />

                <DeleteBranchButton params={params} setParams={setParams} />

            </Box>
        },
    ], [dispatch]);
    const [filters, setFilters] = useState({
        pageSize: 10,
        pageNo: 1
    })

    const fetchList = () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await fetchBranchesApi(filters),
            (response) => {
                setState(response.result)

                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
    }

    

    const getBranchList = () => {
        if (!settings.branch_data || settings.branch_data.length === 0 || JSON.stringify(filters)!=JSON.stringify(settings.branch_filters)) {
            dispatch(fetchBranchDataAction(filters));
        }
    }
    useEffect(() => {
        getBranchList()
        // fetchList()
    }, [filters])

    return <BranchUI columns={columns} filters={filters} setFilters={setFilters} setState={setState} callBack={getBranchList} loading={settings.branch_loading}  state={settings.branch_data} />
}
export default BranchController