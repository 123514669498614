
import { Box, Chip, CircularProgress, Grid, Paper, Typography, styled } from "@mui/material"
import moment from "moment"
import { CenteredBox } from "../../components/layouts/common/boxes"
import { REIMBURSEMENT_ADVANCE_PAYMENT_STATUS, REIMBURSEMENT_STATUS, REIMBURSEMENT_TYPE } from "../../utils/constants"
import { findObjectKeyByValue } from "../../utils/helper"
import { Add, AddCircle, RemoveCircle } from "@mui/icons-material"

const ColoredBox = styled(Box)(({ theme, color, inverted }) => ({
    border: "1px solid " + theme.palette[color ?? "primary"].main,
    display: "flex",
    height: "100%",
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    overflow: "hidden",
    "::before": {
        content: "' '",
        position: "absolute",
        zIndex: 11,
        width: "100%",
        height: "100%",
        top: '0px',
        left: '0px',
        opacity: 0.2,
        backgroundColor: theme.palette[color ?? "primary"].main,
    }
}))

const LogsRow = ({ amount, date, by,isAdded ,reason}) => {


    return <>
        <Grid item xs={12} sm={12} >
            <Paper variant="outlined" sx={{ display: "flex" }} component={Box} p={2} >
                <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                    
                    <Typography>
                    {isAdded?<AddCircle color="success" fontSize="inherit" />:<RemoveCircle fontSize="inherit" color="error" />}   {amount} Rs.
                    </Typography>
                    <Typography variant="caption">
                      Remarks:  {reason}
                    </Typography>
                    <Typography variant="caption">
                        By: {by}
                    </Typography>

                </Box>
                <Box>
                    <Box  sx={{ display: "flex", flex: 'none',alignItems:"center" }}>
                    <Typography variant="caption" mr={2}>
                        {moment(date).format("DD/MM/YYYY")}
                    </Typography>
                   
                    </Box>
                    
                </Box>
            </Paper>



        </Grid>

    </>
}
const ReimburesementRow = ({ type, status, amount, date, purpose }) => {

    const color = status == REIMBURSEMENT_STATUS.paid ? "success" : status == REIMBURSEMENT_STATUS.rejected ? "error" : "warning"
    return <>
        <Grid item xs={12} sm={6} >

            <ColoredBox color={color} p={2} >
                <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                    <Typography>
                        {amount} Rs.
                    </Typography>
                    <Typography variant="caption">
                        {purpose}
                    </Typography>

                </Box>
                <Box>
                    <Typography variant="caption" mr={2}>
                        {moment(date).format("DD/MM/YYYY")}
                    </Typography>
                    <Chip label={findObjectKeyByValue(type, REIMBURSEMENT_TYPE)} color={color} size="small">

                    </Chip>
                </Box>

            </ColoredBox>


        </Grid>

    </>
}



const AdvancePaymentDetailsPageUi = ({ state, setState, fieldLoading, onUpdateField, loading, isFromModal }) => {




    return <>
        {!loading && state &&


            <Box p={3} >
                <Grid container spacing={2} rowSpacing={3}>

                    <Grid xs={6} item>
                        <Typography variant="h4">
                            Employee :
                        </Typography>
                        <Typography variant="h6" color="grey.main">
                            {
                                state.user_id && (state.user_id.first_name + " " + state.user_id.last_name)
                            }
                        </Typography>
                    </Grid>


                    <Grid xs={6} item>
                        <Typography variant="h4">
                            Date :
                        </Typography>
                        <Typography variant="h6" color="grey.main">
                            {
                                moment(state.payment_date).format("DD-MM-YYYY")
                            }
                        </Typography>
                    </Grid>





                    <Grid xs={6} item>
                        <Typography variant="h4">
                            Status
                        </Typography>
                        <Typography variant="h6" color="grey.main">


                            <Chip size="small" label={findObjectKeyByValue(state.status, REIMBURSEMENT_ADVANCE_PAYMENT_STATUS)}
                                color={state.status == REIMBURSEMENT_ADVANCE_PAYMENT_STATUS.OPEN ? "warning" : "success"}

                            />
                        </Typography>
                    </Grid>








                    <Grid xs={12} item>
                        <Typography variant="h4">
                            Amount:
                        </Typography>
                        <Typography variant="h6" color="grey.main">
                            ₹ {state.amount}
                        </Typography>
                    </Grid>


                    <Grid xs={12} item>
                        <Typography variant="h4">
                            Used  Amount:
                        </Typography>
                        <Typography variant="h6" color="grey.main">
                            ₹ {state.used_amount}
                        </Typography>
                    </Grid>


                </Grid>

                <Grid container spacing={2} mt={2}>
                    <Grid md={12} xs={12}>
                        <Typography variant="h4">
                            Reimburesements :
                        </Typography>
                        <Grid container spacing={1} >
                            {
                               state.reimburesements?.length>0? state.reimburesements.map((item) => <ReimburesementRow date={item.expenseDate} purpose={item.purpose} key={item._id} type={item.type} status={item.status} amount={item.amount} />)
                               :<><CenteredBox mt={3} sx={{width:"100%"}} component={Paper} variant="outlined" p={3}>
                                No Reimburesement
                                </CenteredBox> </>
                            }
                        </Grid>
                    </Grid>
                    <Grid md={12} xs={12}>
                        <Typography variant="h4" mt={3} mb={2}>
                            Logs :
                        </Typography>
                        <Grid container spacing={1} >
                            {
                                state.logs.sort((a,b)=>moment(b.date).valueOf()-moment(a.date).valueOf()).map((item) => <LogsRow date={item.date} reason={item.reason} by={item.by?.first_name + " " + item.by?.last_name} key={item._id} isAdded={item.amount_added} amount={item.amount} />)
                            }
                        </Grid>
                    </Grid>
                </Grid>

            </Box>


        }
        {
            !state && !loading && <CenteredBox><Typography variant="h3">Loading Data...</Typography></CenteredBox>
        }
        {
            loading && <CenteredBox sx={{ minHeight: "300px" }}><CircularProgress /></CenteredBox>
        }

    </>
}
export default AdvancePaymentDetailsPageUi