import { act } from "react-dom/test-utils"
import { actions } from "../../utils/constants"

const initialState = {
    calendar_loading : false,
    coff_data : {},
    wfh_data : {},
    leave_data : {},
    logs_data : {},
    calendar_filters : {},

    pending_leaves_data  : {},
    pending_leaves_loading : false,
    pending_leaves_filters : {},

    pending_coff_data  : {},
    pending_coff_loading : false,
    pending_coff_filters : {},

    pending_wfh_data  : {},
    pending_wfh_loading : false,
    pending_wfh_filters : {},

    
}
const leaveReducer = (state = initialState, action) =>  {
    switch(action.type) {
        case actions.START_CALENDAR_LOADING : return {...state, calendar_loading : true, coff_data : null, wfh_data : null, leave_data : null, logs_data : null}
        case actions.SET_LEAVE_LIST_DATA : return {...state, leave_data : action.value}
        case actions.SET_WFH_LIST_DATA : return {...state, wfh_data : action.value}
        case actions.SET_COFF_LIST_DATA : return {...state, coff_data : action.value}
        case actions.SET_LOGS_LIST_DATA : return {...state, logs_data : action.value}
        case actions.STOP_CALENDAR_LOADING : return {...state, calendar_loading : false, calendar_filters : action.filters}

        case actions.START_PENDING_LEAVES_LOADING : return {...state, pending_leaves_loading : true, pending_leaves_data : []}
        case actions.SET_PENDING_LEAVES_DATA : return {...state, pending_leaves_loading : false, pending_leaves_data : action.value.data, pending_leaves_filters : action.value.filters}

        case actions.START_COMPOFF_LOADING : return {...state, pending_coff_loading : true, pending_coff_data : []}
        case actions.SET_COMPOFF_DATA : return {...state,pending_coff_loading : false, pending_coff_data : action.value.data, pending_coff_filters : action.value.filters}

        case actions.START_WFH_LOADING : return {...state, pending_coff_loading : true, pending_wfh_data : []}
        case actions.SET_WFH_DATA : return {...state, pending_wfh_loading : false, pending_wfh_data: action.value.data, pending_wfh_filters : action.value.filters}

        default : return {...state}
    }
}

export default leaveReducer