import {  useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import {  TASK_STATUS } from "../../../../utils/task.constants"
import { findObjectKeyByValue } from "../../../../utils/helper"
import { Box, Button, ButtonGroup, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle,  Slide, Typography } from "@mui/material"
import { callApiAction } from "../../../../store/actions/commonAction"
import {  updateTasksStatusApi } from "../../../../apis/task.api"
import { callSnackBar } from "../../../../store/actions/snackbarAction"
import { SNACK_BAR_VARIETNS } from "../../../../utils/constants"
import moment from "moment"
import useValidate from "../../../../store/hooks/useValidator"
import { DesktopDatePicker } from "@mui/x-date-pickers"
import CustomInput from "../../../../components/inputs/CustomInput"
import SubmitButton, { ResetButton } from "../../../../components/button/SubmitButton"

const RejectStatus = ({  open, statusChangeFun, minDate, handleClose, selectedUser }) => {
    
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const validate = useValidate()
    const { holiday, user } = useSelector((state) => state)
    const [fields, setFields] = useState({ err: "", due_date: moment(), remarks: "" })

    const weeklyOf = selectedUser ? selectedUser.weekly_of : user.data.weekly_of

    const validationSchema = useMemo(
        () => [

            {
                required: true,
                value: fields.due_date,
                field: 'Due Date ',
                custom: () => {
                    return !disableDates(fields.due_date)
                }
            }
            ,

            {
                required: true,
                value: fields.remarks,
                field: 'Remarks'                
            }

        ],
        [fields],
    )

    const disableDates = (date) => {
        let isHoliday = false
        if (holiday.data && Array.isArray(holiday.data)) {
            for (let i = 0; i < holiday.data.length; i++) {
                const item = holiday.data[i]
                const incomingDate = moment(item.date)
                isHoliday =
                    incomingDate.get('dates') == date.get('dates') &&
                    incomingDate.get('months') == date.get('months') &&
                    incomingDate.get('years') == date.get('years')
                if (isHoliday) break
            }
        }
        let isWeeklyOf = false
        if (!isHoliday) {

            if (weeklyOf && Array.isArray(weeklyOf) && weeklyOf.includes(date.day())) {
                isWeeklyOf = true
            } else if (user.data && user.data.weekly_of && Array.isArray(user.data.weekly_of) && user.data.weekly_of.includes(date.day())) {
                isWeeklyOf = true
            }
        }
        return isWeeklyOf || isHoliday
    }

    const onSubmit = async (e) => {
        if (holiday.loading) {
            return
        }
        e.preventDefault()
        setLoading(true)
        const validationResponse = validate(validationSchema)

        if (validationResponse === true) {

            statusChangeFun(TASK_STATUS.REJECTED, { due_date: fields.due_date.valueOf(), remarks: fields.remarks }, () => handleClose())

        } else {
            dispatch(callSnackBar(validationResponse,SNACK_BAR_VARIETNS.error))
            setFields({ ...fields, err: validationResponse })
            setLoading(false)
        }
    }



    return <Dialog onClose={handleClose} open={open} TransitionComponent={Slide} >
        <DialogTitle>
            <Typography variant="h3">Reassign Task</Typography>

            <Typography variant="body1">{fields.err}</Typography>
        </DialogTitle>
        <DialogContent>
            <Typography variant='h5' >
                Task Due Date:*
            </Typography>
            <Box sx={{ display: "flex", flex: "none" }} >
                <DesktopDatePicker
                    shouldDisableDate={disableDates}
                    renderInput={(props) => {
                        return <CustomInput {...props} sx={{ height: '100%' }} />
                    }}
                    minDate={minDate}
                    inputFormat="DD-MM-yyyy"
                    disabled={
                        loading ||
                        !(holiday.data && Array.isArray(holiday.data)) ||
                        holiday.loading
                    }
                    value={fields.due_date}
                    onChange={(e) => {
                        const changedVal = {}
                        changedVal['due_date'] = e

                        setFields({ ...fields, err: '', ...changedVal })
                    }}
                    type="date"
                />
            </Box>

            <Typography variant='h5' mt={3} >
                Remarks:
            </Typography>
            <CustomInput
                multiline={true}
                rows={4}
                disabled={loading}
                loading={loading}
                value={fields.remarks}
                onChange={(e) => setFields({ ...fields, remarks: e.target.value })}
            />
        </DialogContent>
        <DialogActions>
            <ResetButton title="Back" onClick={handleClose} loading={loading}></ResetButton>
            <SubmitButton title={"Submit"} onClick={onSubmit} loading={loading} />
        </DialogActions>
    </Dialog>
}

const StatusButton = ({ status, id, selectedUser, minDate, assignedTo, assignedBy }) => {

    const [loading, setLoading] = useState(false)
    const { user } = useSelector(state => state)
    const dispatch = useDispatch()

    const [currentStatus, setCurrentStatus] = useState(status)
    const [open, setOpen] = useState(false)
    const handleClose = () => setOpen(false)


    const colors = {
        [TASK_STATUS.REJECTED]: "error",
        [TASK_STATUS.PENDING]: "warning",
        [TASK_STATUS.MARKED_AS_COMPLETED]: "default",
        [TASK_STATUS.COMPLETED]: "success"
    }

    const statusChangeFun = (val, params = {}, callBack = () => { }) => {
        setLoading(true)
        setCurrentStatus(val)
        dispatch(
            callApiAction(
                async () => await updateTasksStatusApi({ id, status: val, ...params }),
                async (response) => {
                    setLoading(false)
                    callBack()
                },
                (err) => {
                    dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error))
                },
            ),
        )
    }
    const onStatusChange = (val) => {


        if (val == TASK_STATUS.MARKED_AS_COMPLETED || val == TASK_STATUS.COMPLETED) {
            statusChangeFun(val)
        }

        if (val == TASK_STATUS.REJECTED) {
            // statusChangeFun(val)
            setOpen(true)
        }



    }
    const activeUser = selectedUser ? selectedUser : user.data
    const getStatusButton = () => {


        if (!activeUser || (assignedTo && assignedTo._id == activeUser._id)) {
            if (currentStatus == TASK_STATUS.PENDING) {

                if (assignedTo._id == assignedBy._id) {
                    return <Button size="medium" variant="contained" onClick={() => { onStatusChange(TASK_STATUS.COMPLETED) }}>Mark As Completed</Button>
                }
                return <Button variant="contained" size="medium" onClick={() => { onStatusChange(TASK_STATUS.MARKED_AS_COMPLETED) }}>Mark As Complete</Button>
            }

            return <Chip size="small" label={findObjectKeyByValue(currentStatus, TASK_STATUS)} color={colors[currentStatus] ? colors[currentStatus] : "default"} />
        } else {

            if (currentStatus == TASK_STATUS.MARKED_AS_COMPLETED) {

                return <ButtonGroup variant="outlined">
                    <Button size="medium" onClick={() => { onStatusChange(TASK_STATUS.COMPLETED) }}>Approve</Button>
                    <Button size="medium" onClick={() => { onStatusChange(TASK_STATUS.REJECTED) }}>Deny</Button>
                </ButtonGroup>
            }




            return <Chip size="small" label={findObjectKeyByValue(currentStatus, TASK_STATUS)} color={colors[currentStatus] ? colors[currentStatus] : "default"} />
        }
    }

    return <>
        <RejectStatus open={open} handleClose={handleClose} minDate={minDate} selectedUser={activeUser} statusChangeFun={statusChangeFun} id={id} />

        {
            loading ? <CircularProgress /> : getStatusButton(status)
        }
    </>
}
export default StatusButton