import { memo, useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { callApiAction } from "../../store/actions/commonAction"
import SalarySlipListUI from "./SalarySlipListUI"
import { deleteSalaryDetailsApi, fetchAllUserSalaryDetailsApi } from "../../apis/payroll.api"
import { IconButton, Typography } from "@mui/material"
import { Delete, Info } from "@mui/icons-material"
import { closeModal, openModal } from "../../store/actions/modalAction"
import SalarySlipUi from "./SalarySlipUi"
import moment from "moment"
import { callSnackBar } from "../../store/actions/snackbarAction"
import { SNACK_BAR_VARIETNS } from "../../utils/constants"
import DownloadSalarySlipListPdf from "../payrollSummary/DownloadSalarySlipListPdf"

const ActionButton = ({ params, setParams, date }) => {
    
    const [loading, setLoading] = useState()
    const dispatch = useDispatch()
    // const onClick = () => {
    //     // dispatch(openModal({ title: "Salary Sleep", component: <SalarySlipUi defaultDate={moment(date)} employee_id={params.employee_id} /> }))
    //     dispatch(openModal({ title: "Salary Sleep", component: <DownloadSalarySlipListPdf date={moment(date)} emp_id={params.employee_id} /> }))
    // }

    
    

    const onDelete = () => {
        setLoading(true);
        const month =moment(date).get('month')
        const year =moment(date).get('year')
        dispatch(
            callApiAction(
                async () => await deleteSalaryDetailsApi({ employee_id: params.employee_id,year,month}),
                (response) => {
                    
                    setLoading(false);
                    setParams({})
                    dispatch(callSnackBar("Salary Details Deleted", SNACK_BAR_VARIETNS.suceess))
                },
                (err) => {
                    setLoading(false);
                    dispatch(callSnackBar("Can not delete Branch", SNACK_BAR_VARIETNS.error))
                }
            )
        );
        dispatch(closeModal())
    }
    const onDltBtnCLick = () => {
        dispatch(openModal({
            title:"Alert!",
            component:<Typography>{"Are sure to want to delete "+params.employee_id+"'s salary details?"}</Typography>,
            onConfirm:onDelete,
            size:"sm",
            confirmText:"Delete",
            onCancle:()=>dispatch(closeModal())
        }))
    }
    return <>
    
    {/* <IconButton onClick={onClick}>
        <Info color="info" />
    </IconButton> */}
    <IconButton onClick={onDltBtnCLick}>
        <Delete  color="error" />
    </IconButton>
    </>
}

const SalarySlipListController = ({ date }) => {    
    const user = useSelector(state => state.user)
    const dispatch = useDispatch()

    const [state, setState] = useState([])
    const [loading, setLoading] = useState(false)

    const fetchAllUserSalary = fetchAllUserSalaryDetailsApi


    const columns = useMemo(() => [
        { id: 0, fieldName: 'employee_id', label: 'Employee Id', align: "left", sort: true },
        { id: 1, fieldName: 'lwp', label: 'LWP', align: "left", sort: true, minWidth: '150px' },
        { id: 2, fieldName: 'action', label: 'action', align: "left", minWidth: '150px', renderValue: (params, setParams) => <>
         <DownloadSalarySlipListPdf date={moment(date)} emp_id={params.employee_id} />
        <ActionButton key={date} date={date} params={params} setParams={setParams} />
       
        </> },
    ], [dispatch, date]);

    const [filters, setFilters] = useState({
        pageSize: 10,
        pageNo: 1,
        sort: "employee_id",
        searchable: ["employee_id"],
        sortDirection: -1
    })

    const fetchList = () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await fetchAllUserSalary({ ...filters, date: date }),
            (response) => {
                setState(response)

                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
    }


    useEffect(() => {
        fetchList()
    }, [filters, date])

    return <SalarySlipListUI date={date} columns={columns} filters={filters} setFilters={setFilters} setState={setState} callBack={fetchList} loading={loading} state={state} />
}
export default memo(SalarySlipListController)