import axios from "axios";
import { getFileHeaders } from "../utils/helper";
import { getHeaders } from "../utils/helper";
import endpoints from "./endpoints";

export const fetchHolidays = async () =>
{
    
    const callResponse = await axios({
        url: endpoints.holiday,
        method: "get",
        headers: getHeaders(),
    })
        .then(response => response.data)
        .catch(err => ({status:0,response:err.response,code:err.response.status}));

    return callResponse;
};

export const downloadSalaryCsv = async (params) =>
{
    
    const callResponse = await axios({
        url: endpoints.downloadSalaryCsv,
        method: "get",
        headers: getHeaders(),
        params
    })
        .then(response => response.data)
        .catch(err => ({status:0,response:err.response,code:err.response.status}));

    return callResponse;
};

export const UploadSheetApi = async (data) =>
{
    const callResponse = await axios({
        url: endpoints.salaryUpload,
        method: "post",
        headers:getFileHeaders(),
        data,
    })
        .then(response => response.data)
        .catch(err => ({status:0,response:err.response,code:err.response.status}));

    return callResponse;
};

export const deleteHoliday = async data =>
{
    const callResponse = await axios({
        url: endpoints.holiday,
        method: "delete",
        headers: getHeaders(),
        data,
    })
        .then(response => response.message)
        .catch(err => ({status:0,response:err.response,code:err.response.status}));

    return callResponse;
};

export const fetchSalaryDetailsApi =async(params)=>{
    return await axios({
        url: endpoints.fetchSalary,
        method: "GET",
        headers: getHeaders(),
        params,
      })
        .then(response => response.data)
        .catch(err => ({status:0,response:err.response,code:err.response.status}));          
}

export const deleteSalaryDetailsApi =async(data)=>{
    return await axios({
        url: endpoints.fetchSalary,
        method: "DELETE",
        headers: getHeaders(),
        data,
      })
        .then(response => response.data)
        .catch(err => ({status:0,response:err.response,code:err.response.status}));          
}

export const fetchAllUserSalaryDetailsApi =async(params)=>{
    return await axios({
        url: endpoints.fetchAllUserSalary,
        method: "GET",
        headers: getHeaders(),
        params,
      })
        .then(response => response.data)
        .catch(err => ({status:0,response:err.response,code:err.response.status}));          
}

export const salarySlipPdfApi = async params => {
    const callResponse = await axios({
        url: endpoints.salarySlipPdf,
        method: "get",
        headers: getHeaders(),
        params,
        responseType: "blob"
    })
        .then(response => response.data)
        .catch(err => ({ status: 0, response: err.response, code: err.response.status }));
  
    return callResponse;
  };