import { Outlet } from "react-router"
import EmployeeTaskButtons from "./EmployeeTaskButtons"
import { Box, Paper } from "@mui/material"
import { createContext, useCallback, useState } from "react"
import UserSearchBar from "../../components/inputs/SearchBar"
import { BasicProfileDetailsComponent } from "../profile/BasicProfileDetailsComponent"
import PerformanceButtons from "./Performance/PerformanceButtons"
import GeneralTaskController from "./TaskTimeline/GeneralTaskController"
import { selectEmployee } from "../../store/actions/selectedEmployeeAction"
import { useDispatch, useSelector } from "react-redux"

export const EmployeeTaskUserContext = createContext()

const EmployeeMain = ({forPerformance}) => {
    const dispatch = useDispatch()
  const { selectedEmployee, user } = useSelector(state => state)
  const onUserChange = useCallback((user) => {
    dispatch(selectEmployee(user))
  }, [])

    return <>

        <Box mt={3}>
            <UserSearchBar defaultVal={selectedEmployee.user} onUserChange={onUserChange} />
        </Box>


        {selectedEmployee.user && <>
            {selectedEmployee.user && selectedEmployee.user != null && <BasicProfileDetailsComponent data={selectedEmployee.user} />}
           {!forPerformance && <EmployeeTaskButtons />}
           {forPerformance && <PerformanceButtons />}
            <Paper component={Box} mb={4} p={3} sx={(theme) => ({ border: "1px solid " + theme.palette.grey[500] })}>
                <EmployeeTaskUserContext.Provider value={selectedEmployee.user}>
                    <Outlet />
                </EmployeeTaskUserContext.Provider>
            </Paper>
        </>}
        {
            !selectedEmployee.user && <GeneralTaskController />
        }

    </>
}
export default EmployeeMain