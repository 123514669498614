import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";
import { closeModal } from "../../store/actions/modalAction";
import { fetchFeedbackType,createFeedbackType } from "../../apis/feedbackType.api";
import AddFeedbackTypeUI from "./AddFeedbackTypeUI";

const AddFeedbackTypeController = ({ callBack }) => {
  const dispatch = useDispatch();
  const createFeedbackTypeApi = createFeedbackType;
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [err, setErr] = useState("");
  const [identifier, setIdentifier] = useState("");
  const [priority, setPriority] = useState("");

  const fetchLeaveTypesApi = fetchFeedbackType;

  const addFeedbackType = (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {name,identifier,priority};

    dispatch(
      callApiAction(
        async () => await createFeedbackTypeApi(data),
        async (response) => {
          await callBack(response);
          dispatch(closeModal());
          setLoading(false);
          fetchLeaveTypesApi();

        },
        (err) => {
          setLoading(false);
          setErr(err);
        }
      )
    );
  };

  useEffect(() => {
    fetchLeaveTypesApi();
  }, []);

  return (
    <AddFeedbackTypeUI
      loading={loading}
      addFeedbackType={addFeedbackType}
      name={name}
      setName={setName}
      identifier={identifier}
      setIdentifier={setIdentifier}
      priority={priority}
      setPriority={setPriority}
    />
  );
};

export default AddFeedbackTypeController;
