import axios from "axios";
import endpoints from "./endpoints";
import { getHeaders } from "../utils/helper";

export const fetchTeamListApi = async params => {
    const callResponse = await axios({
      // url: endpoints.fetchTeamList,
      url :endpoints.fetchTeamList,
      method: "get",
      headers: getHeaders(),
      params,
    })
      .then(response => response.data)
      .catch(err => ({status:0,response:err.response,code:err.response.status}));
  
    return callResponse;
};

export const fetchTeamMemberByIdApi = async params => {
  const callResponse = await axios({
    // url: endpoints.fetchTeamList,
    url :endpoints.fetchTeamMemberById,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => ({status:0,response:err.response,code:err.response.status}));

  return callResponse;
};