import React, { useState } from "react";
import { createHoliday } from "../../apis/holiday.api";
import { createEmployeeType } from "../../apis/employeeType.api";
import { createBranch } from "../../apis/branch.api";
import { useDispatch, useSelector } from "react-redux"
import { callApiAction } from "../../store/actions/commonAction";
import AddEmployeeTypeUI from "./AddEmployeeTypeUI";
import { closeModal } from "../../store/actions/modalAction";
import { fetchEmployeeTypeDataAction } from "../../store/actions/settingsAction";

const AddEmployeeTypeController = ({callBack}) => {
    const dispatch = useDispatch()
    const createEmployeeTypeApi = createEmployeeType
    const [loading, setLoading] = useState(false)
    const [name, setName] = useState("")
    const [err, setErr] = useState("")
    const {settings} = useSelector((state) => state)

    const addEmployeeType = (e) => {
        e.preventDefault()
        setLoading(true)
        dispatch(callApiAction(
            async () => await createEmployeeTypeApi({ name }),
            (response) => {
                dispatch(closeModal())
                callBack()
                dispatch(fetchEmployeeTypeDataAction(settings.employeeType_filters))
                setLoading(false)
            },
            (err) => {
                setLoading(false)
                setErr(err)
            }
        ))
    }
    return <AddEmployeeTypeUI loading={loading} addEmployeeType={addEmployeeType} setName={setName} err={err} setErr={setErr} name={name} />
}

export default AddEmployeeTypeController;