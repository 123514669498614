import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { callApiAction } from '../../store/actions/commonAction'
import { getUserById } from '../../apis/task.api'
import TeamMemberProfileUi from './TeamMemberProfileUi'
import { fetchTeamMemberByIdApi } from '../../apis/team.api'

function TeamMemberProfileController({id}) {
    const [state, setState] = useState(null)
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()

    const fetchList = () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await fetchTeamMemberByIdApi({ id }),
            (response) => {
                setState(response)
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
    }
    useEffect(() => {
        fetchList()
    },[])

  return (
    <TeamMemberProfileUi setState={setState} loading={loading} state={state}  />
  )
}

export default TeamMemberProfileController