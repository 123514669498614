import axios from "axios";
import { getHeaders } from "../utils/helper";
import endpoints from "./endpoints";



export const getReimberesementApi = async params => {
    const callResponse = await axios({
        url: endpoints.reimberesementBase,
        method: "get",
        headers: getHeaders(),
        params,
    })
        .then(response => response.data)
        .catch(err => ({ status: 0, response: err.response, code: err.response.status }));

    return callResponse;
};


export const getReimberesementPdfApi = async params => {
    const callResponse = await axios({
        url: endpoints.reimburesementPdf,
        method: "get",
        headers: getHeaders(),
        params,
        responseType: "blob"
    })
        .then(response => response.data)
        .catch(err => ({ status: 0, response: err.response, code: err.response.status }));

    return callResponse;
};

export const getGeneralReimberesementApi = async params => {
    const callResponse = await axios({
        url: endpoints.reimberesementGeneral,
        method: "get",
        headers: getHeaders(),
        params,
    })
        .then(response => response.data)
        .catch(err => ({ status: 0, response: err.response, code: err.response.status }));

    return callResponse;
};


export const getPendingReimberesementApi = async params => {
    const callResponse = await axios({
        url: endpoints.pendingReimberesementBase,
        method: "get",
        headers: getHeaders(),
        params,
    })
        .then(response => response.data)
        .catch(err => ({ status: 0, response: err.response, code: err.response.status }));

    return callResponse;
};
export const getReimberesementCountApi = async params => {
    const callResponse = await axios({
        url: endpoints.reimberesementCount,
        method: "get",
        headers: getHeaders(),
        params,
    })
        .then(response => response.data)
        .catch(err => ({ status: 0, response: err.response, code: err.response.status }));

    return callResponse;
};

export const getReimberesementByIdApi = async (id) => {
    const callResponse = await axios({
        url: endpoints.reimberesementById + id,
        method: "get",
        headers: getHeaders(),

    })
        .then(response => response.data)
        .catch(err => ({ status: 0, response: err.response, code: err.response.status }));

    return callResponse;
};


export const addReimberesementApi = async data => {
    const callResponse = await axios({
        url: endpoints.reimberesementBase,
        method: "POST",
        headers: getHeaders(),

        data,
    })
        .then(response => response.data)
        .catch(err => ({ status: 0, response: err.response, code: err.response.status }));

    return callResponse;
};

export const updateReimberesementField = async data => {
    const callResponse = await axios({
        url: endpoints.reimberesementBase,
        method: "PATCH",
        headers: getHeaders(),
        data,
    })
        .then(response => response.data)
        .catch(err => ({ status: 0, response: err.response, code: err.response.status }));

    return callResponse;
};


export const updateAllReimberesementAtOnceApi = async data => {
    const callResponse = await axios({
        url: endpoints.reimberesementByEmployee,
        method: "PATCH",
        headers: getHeaders(),
        data,
    })
        .then(response => response.data)
        .catch(err => ({ status: 0, response: err.response, code: err.response.status }));

    return callResponse;
};

//

export const createAdvancePaymentApi = async data => {
    const callResponse = await axios({
        url: endpoints.advancePaymentBase,
        method: "POST",
        headers: getHeaders(),

        data,
    })
        .then(response => response.data)
        .catch(err => ({ status: 0, response: err.response, code: err.response.status }));

    return callResponse;
};
export const AddAmountAdvancePaymentApi = async data => {
    const callResponse = await axios({
        url: endpoints.advancePaymentBase,
        method: "PATCH",
        headers: getHeaders(),

        data,
    })
        .then(response => response.data)
        .catch(err => ({ status: 0, response: err.response, code: err.response.status }));

    return callResponse;
};

export const settleAdvancePaymentApi = async data => {
    const callResponse = await axios({
        url: endpoints.settleAdvancePaymentBase,
        method: "POST",
        headers: getHeaders(),

        data,
    })
        .then(response => response.data)
        .catch(err => ({ status: 0, response: err.response, code: err.response.status }));

    return callResponse;
};
export const getAdvancePaymentsListApi = async params => {
    const callResponse = await axios({
        url: endpoints.advancePaymentBase,
        method: "get",
        headers: getHeaders(),
        params,
    })
        .then(response => response.data)
        .catch(err => ({ status: 0, response: err.response, code: err.response.status }));

    return callResponse;
};
export const downloadAdvancePaymentPdf = async params => {
    const callResponse = await axios({
        url: endpoints.advancePaymentDownloadReport,
        method: "get",
        headers: getHeaders(),
        params,
        responseType:"blob"
    })
        .then(response => response.data)
        .catch(err => ({ status: 0, response: err.response, code: err.response.status }));

    return callResponse;
};

export const getAdvancePaymentsByIdApi = async params => {
    const callResponse = await axios({
        url: endpoints.advancePaymentById,
        method: "get",
        headers: getHeaders(),
        params,
    })
        .then(response => response.data)
        .catch(err => ({ status: 0, response: err.response, code: err.response.status }));

    return callResponse;
};

export const getReimberesementCSVApi = async (params) => {
    const callResponse = await axios({
        url: endpoints.reimburesementCSV,
        method: "get",
        headers: getHeaders(),
        params,
    })
        .then(response => response.data)
        .catch(err => ({ status: 0, response: err.response, code: err.response.status }));

    return callResponse;
};
