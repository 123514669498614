import { actions } from "../../utils/constants";


const initialState = {
    loading: false,
    data: [],
   

}
const holidayReducer = (state = initialState, action) => {
    
    switch (action.type) {        
        case actions.START_HOLIDAY_COUNT_LOADING: return { ...state, loading: true, data: [] };
        case actions.SET_HOLIDAY_COUNT_DATA: return { ...state, loading: false, data: action.value };
        default: return { ...state }
    }

}
export default holidayReducer