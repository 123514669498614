import { Info } from "@mui/icons-material"
import { IconButton } from "@mui/material"
import { useDispatch } from "react-redux"
import { openModal } from "../../store/actions/modalAction"
import WFHDetailsController from "./WFHDetailsController"

const WFHDetailShowButton = ({params})=>{
const dispatch = useDispatch()
    const onClick = ()=>{
        dispatch(openModal({
            title:"WFH Details",
            component:<WFHDetailsController id={params._id} />,
            size:"sm"
        }))
    }
    return <IconButton onClick={onClick} >
        <Info color="info" />
    </IconButton>
}
export default WFHDetailShowButton