import { useDispatch } from "react-redux";
import { openModal } from "../../store/actions/modalAction";
import AddFeedbackFormController from "./AddFeedbackFormController";
import { useCallback } from "react";
import { IconButton, Tooltip } from "@mui/material";
import { AddComment } from "@mui/icons-material";

const FeedbackFormButton = () => {
    const dispatch = useDispatch()
    const addFeedback = useCallback(() => {
        dispatch(
            openModal({
                title: "Add Feedback",
                component: <AddFeedbackFormController />,
                size: "sm",
            })
        );
    }, []);
    return <Tooltip title="Add Feedback"  >
        <IconButton onClick={addFeedback}>
            <AddComment />
        </IconButton>
    </Tooltip>
}
export default FeedbackFormButton