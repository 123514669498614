import { Home } from "@mui/icons-material"
import { Link } from "react-router-dom"
import { ActionButton } from "../../components/button/SubmitButton"
import { Tooltip } from "@mui/material"

const WFHRaiseButton = () => {
    return <>
        {/* <Tooltip  title="Coming soon..." > */}
            <ActionButton
                
                
                
                
                LinkComponent={Link}
                icon={<Home color="inherit" />}
                title={"Apply WFH"}
                to="raise-wfh"
            ></ActionButton>
        {/* </Tooltip> */}
    </>
}
export default WFHRaiseButton