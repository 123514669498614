import React, { useMemo, useState } from "react";
import { useDispatch } from "react-redux"
import { callApiAction } from "../../store/actions/commonAction";
import AddAdvancePaymentsUI from "./AddAdvancePaymentsUI"
import { closeModal } from "../../store/actions/modalAction";
import useValidate from "../../store/hooks/useValidator";
import { AddAmountAdvancePaymentApi, createAdvancePaymentApi } from "../../apis/reimbersement.api";

const SettleAdvancePaymentController = ({ callBack, id }) => {

    const validate = useValidate()
    const dispatch = useDispatch()

    const [loading, setLoading] = useState(false)


    const [fields, setFields] = useState({
        amount: 0,
        id,
        remarks: ''
    })

    const validationSchema = useMemo(() => ([
        {
            required: true,
            value: fields.amount,
            field: ' Amount '
        },
        {
            required: true,
            value: fields.remarks,
            field: ' Remarks '
        }
    ]), [fields])

    const submitData = (e) => {
        e.preventDefault()
        const validationResponse = validate(validationSchema)

        if (validationResponse === true) {
            setLoading(true)
            dispatch(callApiAction(
                async () => await AddAmountAdvancePaymentApi({ ...fields }),
                (response) => {
                    callBack(fields)
                    setLoading(false)
                    dispatch(closeModal())
                },
                (err) => {
                    setLoading(false)
                    setFields({ ...fields, err })
                }
            ))
        } else {
            setFields({ ...fields, 'err': validationResponse })
        }
    }
    return <AddAdvancePaymentsUI
        loading={loading}
        onSubmit={submitData}
        fields={fields}
        setFields={setFields}
    />
}

export default SettleAdvancePaymentController;