import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";
import UpdateEmployeeTypeUI from "./UpdateEmployeeTypeUI";
import { updateEmployeeType } from "../../apis/employeeType.api";
import { closeModal } from "../../store/actions/modalAction";
import { setEmployeeTypeDataOnDeleteAction } from "../../store/actions/settingsAction";

const UpdateEmployeeTypeController = ({ id, initialName,callBack }) => {
  
  const dispatch = useDispatch();
  const UpdateEmployeeApi = updateEmployeeType;
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(initialName);
  const [err, setErr] = useState("");
  const {settings} = useSelector((state) => state)

  const UpdateEmployeeType = (e) => {
    e.preventDefault()
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await UpdateEmployeeApi({ id, name }),
        (response) => {
          dispatch(closeModal())
          callBack({
            name
          })
          const updatedData = settings.employeeType_data.map(item => (item._id == id ? {...item, name : name} : item))
          dispatch(setEmployeeTypeDataOnDeleteAction(updatedData, settings.employeeType_filters))
          setLoading(false);
        },
        (err) => {
          setLoading(false);
          setErr(err);
        }
      )
    );
  };
  return (
    <UpdateEmployeeTypeUI
      loading={loading}
      UpdateEmployeeType={UpdateEmployeeType}
      setName={setName}
      err={err}
      setErr={setErr}
      name={name}
    />
  );
};

export default UpdateEmployeeTypeController;
