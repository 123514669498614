import { Box, Button, Typography} from "@mui/material"
import { useDispatch } from "react-redux";

import DataTable from "../../../components/tables/DataTable";

const VisitPlacesListUi = ({ setState, filters, setFilters, callBack, loading, state, columns }) => {
    const dispatch = useDispatch();
   
    return <>

        <Box >
            {/* <Box>
                <Typography sx={{
                    fontWeight: 700,
                    fontSize: "24px",
                    lineHeight: "40px",
                    color: "#0D0D0D"
                }}>  </Typography>
               
            </Box> */}
            <Box sx={{ minHeight: "300px" }} >
                <DataTable columns={columns} rows={state.result} count={state?.total} filters={filters} setFilters={setFilters} loading={loading} />
            </Box>
        </Box>
    </>
}
export default VisitPlacesListUi