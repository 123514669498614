import {
  Box,
  Button,
  Checkbox,
  Collapse,

  Grid,

  Typography,
} from '@mui/material'
import { DesktopDatePicker } from '@mui/x-date-pickers'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import SubmitButton, { ResetButton } from '../../../components/button/SubmitButton'
import CustomInput from '../../../components/inputs/CustomInput'

import CustomRadioButtons from '../../../components/layouts/common/CustomRadioButtons'

import { useNavigate } from 'react-router-dom'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { TASK_PRIORITY, TASK_STATUS, TASK_TYPES } from '../../../utils/task.constants'
import { UserSearchBarNormal } from '../../../components/inputs/SearchBar'



const TaskUpdateUi = ({
  userId,
  onSubmit,
  loading,
  fields,
  setFields,
  disableDates,
}) => {
  const { holiday, user } = useSelector((state) => state)


  const navigate = useNavigate()

  return (
    <Box  component="form" onSubmit={onSubmit}>
      
      <Box>
                
          <Typography variant='h4' mb={2}>
            Task Description:*
          </Typography>


          <CKEditor
            config={{
                                placeholder:"Write Task Description...",
                                style: { height: "500px", minHeight: "500px" },
                                toolbar: { items:['p','heading','italic','bold','blockquote','link','table','undo','redo','numberedList','bulletedList']}
                                
                            }}
            name="description"
            id="description"
            placeholder="Write Task Description..."
            disabled={loading}
            editor={ClassicEditor}

            onInit={(editor) => {


            }}
            data={fields.description}
            onReady={(editor) => {

              editor.editing.view.change((writer) => {
                writer.setStyle(
                  "height",
                  "200px",
                  editor.editing.view.document.getRoot()
                );
              });
            }}
            onChange={(event, editor) => {
              const data = editor.getData()
              setFields({ ...fields, err: '', description: data })
            }}
            onBlur={(event, editor) => { }}
            onFocus={(event, editor) => { }}
          />


         

          <Grid container rowSpacing={3} mt={3} columnSpacing={3}>
            <Grid item xs={12} md={6}>

              <Typography variant='h4'>
                Task Start Date:*
              </Typography>
              <Box sx={{ display: "flex", flex: "none" }} >
                <DesktopDatePicker
                  shouldDisableDate={disableDates}
                  renderInput={(props) => {
                    return <CustomInput {...props} sx={{ height: '100%' }} />
                  }}
                  inputFormat="DD-MM-yyyy"
                  disabled={
                    loading ||
                    !(holiday.data && Array.isArray(holiday.data)) ||
                    holiday.loading
                  }
                  value={fields.start_date}
                  onChange={(e) => {
                    const changedVal = {}
                    changedVal['start_date'] = e
                    if (e.valueOf() >= fields['due_date'].valueOf()) {
                      changedVal['due_date'] = e
                    }
                    setFields({ ...fields, err: '', ...changedVal })
                  }}
                  type="date"
                />

              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant='h4' >
                Task Due Date:*
              </Typography>
              <Box sx={{ display: "flex", flex: "none" }} >
                <DesktopDatePicker
                  shouldDisableDate={disableDates}
                  renderInput={(props) => {
                    return <CustomInput {...props} sx={{ height: '100%' }} />
                  }}
                  inputFormat="DD-MM-yyyy"
                  disabled={
                    loading ||
                    !(holiday.data && Array.isArray(holiday.data)) ||
                    holiday.loading
                  }
                  value={fields.due_date}
                  onChange={(e) => {
                    const changedVal = {}
                    changedVal['due_date'] = e

                    setFields({ ...fields, err: '', ...changedVal })
                  }}
                  type="date"
                  minDate={fields.start_date}
                />
              </Box>
            </Grid>
          </Grid>

        

          <Box mt={3} sx={{ display: "flex", justifyContent: "flex-end" }}>

            <Box sx={{ display: "flex" }} mr={3}>
              {/* <ResetButton
                loading={loading}
                type="reset"
                variant="text"
                onClick={() => {  }}
                title="Cancel"
              >

              </ResetButton> */}
            </Box>
            <Box sx={{ display: "flex" }}>
              <SubmitButton

                loading={loading}
                type="submit"
                variant="contained"
                color="primary"
                title="Update Task"
              >

              </SubmitButton>
            </Box>
          </Box>
        
       


      </Box>
    </Box>
  )
}
export default TaskUpdateUi