import {
  Box,
  Paper,
  Checkbox,
  Collapse,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import SubmitButton, {
  ResetButton,
} from "../../components/button/SubmitButton";
import CustomInput from "../../components/inputs/CustomInput";

import { useNavigate } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
//   import { TASK_PRIORITY, TASK_STATUS, TASK_TYPES } from '../../../utils/task.constants'
import { UserSearchBarNormal } from "../../components/inputs/SearchBar";
const AnnouncementsFormUI = ({
  userId,
  onSubmit,
  loading,
  fields,
  setFields,
  disableDates,
}) => {
  // const { holiday, user } = useSelector((state) => state)
  const date_announce = moment().format("DD MMMM YYYY");

  const navigate = useNavigate();

  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up("sm"));
  return (
    <Paper
      component={Box}
      p={3}
      sx={(theme) => ({ border: "0px solid " + theme.palette.grey[500] })}
    >
      <Box p={2} mt={1} component="form" onSubmit={onSubmit}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            flexDirection: desktop ? "row" : "column",
          }}
        >
          <Box>
            <Typography align="left" variant="h2">
              New Announcement
            </Typography>
            <Typography variant="h6" color="error">
              {fields.err}
            </Typography>
          </Box>

          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h3" color="grey.main">
              Application Date:
            </Typography>
            <Typography variant="h3" ml={2}>
              {date_announce}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex", width: "100%" }}>
          <Box
            sx={{
              display: "flex",
              flex: 4,
              maxWidth: "100%",
              flexDirection: "column",
            }}
          >
            <Typography variant="h4" mt={3}>
              Announcement Title:*
            </Typography>

            <CustomInput
              name="announcement title"
              id="announcement_title"
              required
              helperText="Upto 100 Character"
              disabled={loading}
              value={fields.announcement_title}
              onChange={(e) => {
                if (!e.target.value || e.target.value.length <= 100)
                  setFields({
                    ...fields,
                    err: "",
                    announcement_title: e.target.value,
                  });
              }}
              type="text"
              placeholder="Write Announcement Title..."
            />

            <Typography variant="h4" mb={2} mt={3}>
              Announcement Description:*
            </Typography>

            <Box sx={{ width: "100%" }}>
              <CKEditor
                config={{
                  placeholder: "Write Announcement Description...",
                  style: { height: "500px", minHeight: "500px" },
                  toolbar: {
                    items: [
                      "p",
                      "heading",
                      "italic",
                      "bold",
                      "blockquote",
                      "link",
                      "table",
                      "undo",
                      "redo",
                      "numberedList",
                      "bulletedList",
                    ],
                  },
                }}
                name="announcement description"
                id="announcement_description"
                placeholder="Write Announcement Description..."
                disabled={loading}
                editor={ClassicEditor}
                style={{ width: "100%" }}
                onInit={(editor) => {}}
                data={fields.announcement_description}
                onReady={(editor) => {
                  editor.editing.view.change((writer) => {
                    writer.setStyle(
                      "height",
                      "200px",
                      editor.editing.view.document.getRoot()
                    );
                    writer.setStyle(
                      "min-width",
                      "0px",
                      editor.editing.view.document.getRoot()
                    );
                  });
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setFields({
                    ...fields,
                    err: "",
                    announcement_description: data,
                  });
                }}
                onBlur={(event, editor) => {}}
                onFocus={(event, editor) => {}}
              />
            </Box>

            <Box mt={3} sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Box sx={{ display: "flex" }} mr={3}>
                <ResetButton
                  loading={loading}
                  type="reset"
                  variant="text"
                  onClick={() => {
                    navigate("Announcements/Announcement-form");
                  }}
                  title="Cancel"
                ></ResetButton>
              </Box>
              <Box sx={{ display: "flex" }}>
                <SubmitButton
                  loading={loading}
                  type="submit"
                  variant="contained"
                  color="primary"
                  title="Make announcement"
                ></SubmitButton>
              </Box>
            </Box>
          </Box>
          {!desktop && <Box sx={{ flex: 1 }}></Box>}
        </Box>
      </Box>
    </Paper>
  );
};

export default AnnouncementsFormUI;
