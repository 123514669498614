import { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { callApiAction } from "../../store/actions/commonAction"
import VersionsUi from "./VersionsUi"
import DeleteVersionButton from "./DeleteVersionButton"
import { Box } from "@mui/material"
import { fetchVersiones } from "../../apis/version.api"
import moment from "moment"
import { findObjectKeyByValue, unEscapeStr } from "../../utils/helper"
import { APP_TYPES } from "../../utils/constants"
import { fetchVersionDataAction } from "../../store/actions/settingsAction"

const VersionsController = ({ userId }) => {
    const user = useSelector(state => state.user)
    const dispatch = useDispatch()

    const [state, setState] = useState([])
    const [loading, setLoading] = useState(false)
    const {settings} = useSelector((state) => state)

    const fetchVersionsApi = fetchVersiones


    const columns = useMemo(() => [
        { id: 0, fieldName: 'launch_date', label: 'Launch Date', align: "left", sort: true, minWidth: '150px', renderValue: (params) => moment(params.launch_date).format("DD/MM/YYYY") },
        { id: 1, fieldName: 'name', label: 'Name', align: "left", sort: true, minWidth: '150px' },
        
        { id: 4, fieldName: 'main_id', label: 'Version Code', align: "left", sort: true, minWidth: '150px', renderValue: (params) => params.main_version + "." + params.sub_version },
        { id: 1, fieldName: 'app_type', label: 'Type', align: "left", sort: true, renderValue:(params)=>findObjectKeyByValue(params.app_type,APP_TYPES) },
        { id: 2, fieldName: 'description', label: 'description', align: "left", renderValue: (params) => <Box
         dangerouslySetInnerHTML={{ __html: unEscapeStr(params.description) }} 
         
         
         /> },
        {
            id: 3, fieldName: 'action', label: 'Action', align: "left", renderValue: (params, setParams) => <Box sx={{ display: "flex" }}>

                <DeleteVersionButton params={params} setParams={setParams} />

            </Box>
        },
    ], [dispatch]);
    const [filters, setFilters] = useState({
        pageSize: 10,
        pageNo: 1,
        sort: "launch_date",
        sortDirection: -1
    })

    const fetchList = () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await fetchVersionsApi(filters),
            (response) => {
                setState(response.result)

                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
    }

    const getVersionList = () => {
        if (!settings.version_data || settings.version_data.length === 0 || JSON.stringify(filters)!=JSON.stringify(settings.version_filters)) {
            dispatch(fetchVersionDataAction(filters));
        }
    }
    useEffect(() => {
        getVersionList()
        // fetchList()
    }, [filters])

    return <VersionsUi columns={columns} filters={filters} setFilters={setFilters} setState={setState} callBack={getVersionList} loading={settings.version_loading} state={settings.version_data} />
}
export default VersionsController