import React, { useState } from "react";
import { createHoliday } from "../../apis/holiday.api";
import { createBranch ,updateBranch} from "../../apis/branch.api";
import { useDispatch, useSelector } from "react-redux"
import { callApiAction } from "../../store/actions/commonAction";
import UpdateBranchUI from "./UpdateBranchUI";
import { closeModal } from "../../store/actions/modalAction";
import { setBranchDataOnDeleteAction } from "../../store/actions/settingsAction";

const UpdateBranchController = ({id,intialName,callBack,initialAddress}) => {
    const dispatch = useDispatch()
    const UpdateBranchApi = updateBranch
    const [loading, setLoading] = useState(false)
    const [address, setAddress] = useState(initialAddress);
    const [name, setName] = useState(intialName)
    const [err, setErr] = useState("")
    const {settings} = useSelector((state) => state)

    const UpdateBranch = (e) => {
        e.preventDefault()
        setLoading(true)
        dispatch(callApiAction(
            async () => await UpdateBranchApi({ id,address, name }),
            (response) => {
                callBack({
                    name,
                    address
                })
                const updatedData = settings.branch_data.map(item => (item._id == id ? {...item, name : name, address : address} : item))
                dispatch(setBranchDataOnDeleteAction(updatedData, settings.branch_filters))
                setLoading(false)
                dispatch(closeModal())
            },
            (err) => {
                setLoading(false)
                setErr(err)
            }
        ))
    }
    return <UpdateBranchUI loading={loading} UpdateBranch={UpdateBranch} setAddress={setAddress} setName={setName} err={err} setErr={setErr} name={name} address={address} />
}

export default UpdateBranchController;