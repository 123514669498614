import { Box, ButtonBase, CircularProgress, Grid, Paper, Skeleton, Tooltip, Typography, useTheme } from '@mui/material'
import React from 'react'
import { CenteredBox } from '../../components/layouts/common/boxes'

import moment from 'moment'
import { AccessTime, Business, East, Fastfood, Fingerprint, NoFood, QueryBuilder, West } from '@mui/icons-material'


const OverViewBox = ({ title, value, icon, iconColor = "green" }) => {
    return (

        <CenteredBox flexDirection="column" >

            <CenteredBox mb={2} sx={{ width: "24px", height: "24px", border: 1, borderColor: iconColor, borderRadius: "100%", color: iconColor, position: "relative" }} >
                <Box sx={{ position: "absolute", opacity: 0.2, zIndex: 0, top: "0px", left: "0px", borderRadius: "100%", backgroundColor: iconColor, height: "100%", width: "100%" }}>

                </Box>
                {icon}

            </CenteredBox>
            <Typography variant="subtitle2" color="grey.main" align="center">
                {title}
            </Typography>
            <Typography variant="h5" align="center">
                {value}
            </Typography>
        </CenteredBox>
    )
}

function DashboardLogsUI({ filters, setFilters, date, list, loading }) {
    const theme = useTheme()
    return <>
        <Box p={3} sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
            {loading && <Grid container spacing={2}>
                <Grid item sm={4} xs={12}>
                    <Skeleton width="100%" height={"65px"} />
                </Grid>
                <Grid item sm={4} xs={12}>
                    <Skeleton width="100%" height={"65px"} />
                </Grid>
                <Grid item sm={4} xs={12}>
                    <Skeleton width="100%" height={"65px"} />
                </Grid>
            </Grid>}
            {!loading && list && list['allLogs'] && <Grid container spacing={3}>
                <Grid item sm={4} xs={4} >
                    <OverViewBox icon={<Fingerprint fontSize='17px' />} iconColor='#59B29D' title="Check In Time" value={list.firstCheckIn ? moment(list.firstCheckIn).format("HH:mm") : "NA"}></OverViewBox>
                </Grid>
                <Grid item sm={4} xs={4} >
                    <Box pr={3} sx={{ width: "100%", height: "100%", borderRight: 1, borderColor: theme.palette.grey.calender }} >
                        <OverViewBox icon={<Fingerprint fontSize='17px' />} iconColor='#FC4C4C' title="Check Out Time" value={list.lastCheckout ? moment(list.lastCheckout).format("HH:mm") : "NA"}></OverViewBox>
                    </Box>
                </Grid>

                <Grid item sm={4} xs={4} >
                    <OverViewBox icon={<QueryBuilder fontSize='17px' />} iconColor='#D2DF83' title="Working Hours" value={list.hours + "H " + list.minutes + "M"}></OverViewBox>
                </Grid>

            </Grid>

            }
            {/* {!loading && list && list['allLogs'] && <WorkingHourComponent title="Working Hours" value={list.hours + "H " + list.minutes + "M"}></WorkingHourComponent>} */}


            {!loading && (!list || Object.keys(list).length == 0) && <CenteredBox mt={4} mb={4}><Typography variant="h3" >No logs Found</Typography></CenteredBox>}
        </Box>
    </>
}

export default DashboardLogsUI