import axios from "axios"
import { getHeaders } from "../utils/helper"
import endpoints from "./endpoints"

export const createAnnouncement = async (data) => {
    const callResponse = await axios({
      url: endpoints.createAnnouncement,
      method: 'post',
      headers: getHeaders(),
      data,
    })
      .then((response) => response.data)
      .catch((err) => ({status:0,response:err.response,code:err.response.status}))
  
    return callResponse
  }


export const getAnnouncementsApi = async (params) => {
  const callResponse = await axios({
      url: endpoints.announcementFetch,
      method: "get",
      headers: getHeaders(),
      params,
  })
      .then(response => response.data)
      .catch(err => ({status:0,response:err.response,code:err.response.status}));

  return callResponse;
};



export const getFirstAnnouncementsApi = async (data) => {
  const callResponse = await axios({
      url: endpoints.  firstannouncementFetch,
      method: "get",
      headers: getHeaders(),
      data,
  })
      .then(response => response.data)
      .catch(err => ({status:0,response:err.response,code:err.response.status}));

  return callResponse;
};
