import { actions } from "../../utils/constants";
const initialState = {
    data: [

    ],

}
const birthdayReducer = (state = initialState, action) => {
    // console.log("states",action);
    switch (action.type) {
        case actions.SET_BIRTHDAY_DATA: return { ...state,  data: action.data};
       
        default: return { ...state }
    }

}

export default birthdayReducer





