import { DialogActions, DialogContent, DialogTitle } from "@mui/material"
import { Box } from "@mui/system"
import { useDispatch } from "react-redux"
import SubmitButton from "../../components/button/SubmitButton"
import { closeModal } from "../../store/actions/modalAction"
import ProfileController from "./ProfileController"

const ProfileDialog = (props , isFromViewMore) => {
    const dispatch = useDispatch()
    const handleClose = () => {
        dispatch(closeModal())
    }
    return (
        <>
            
                   <Box bgcolor={"#f2f2f2"} px={2} pb={2}>

                   <ProfileController {...props} isFromViewMore={isFromViewMore} />
                   </Box>
              

        </>
    )
}
export default ProfileDialog