import {
    Box,
    Button,
    Checkbox,
    Collapse,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    Slide,
    Typography,
    TextField,
    CircularProgress,
  } from "@mui/material";
  import CustomInput from "../../components/inputs/CustomInput";
  import { CenteredBox } from "../../components/layouts/common/boxes";
import SubmitButton from "../../components/button/SubmitButton";
  
  const AddEmployeeTypeUI = ({
    loading,
    addEmployeeType,
    setName,
    err,
    setErr,
    name
  }) => {
    return (
      <>
        <Box
          component="form"
          sx={{
            display: "flex",
            flexDirection: "column",
            overflowY: "auto",
            position: "relative",
          }}
          maxHeight="100%"
        >
          
            {loading && (
              <CenteredBox>
                <CircularProgress />
              </CenteredBox>
            )}
            {!loading && (
              <>
                <Box sx={{ marginTop: "10px", width: "100%" }}>
                  <Box>
                    <Typography fontWeight={500} variant="h4">
                      {" "}
                      Name :{" "}
                    </Typography>
                  </Box>
                  <Box sx={{ marginTop: "7px" }}>
                    <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        flexDirection: "column",
                      }}
                    >
                      <Box sx={{ display: "flex", width: "100%" }}>
                        <CustomInput
                          disabled={loading}
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          type="text"
                          placeholder="Write Name..."
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </>
            )}
          
          <DialogActions>
            <Box sx={{ float: "right", marginTop: "7px" }}>
              <SubmitButton
                loading={loading}
                type="submit"
                variant="contained"
                color="primary"
                onClick={addEmployeeType}
                title="Add Employee Type"
              >
                
              </SubmitButton>
            </Box>
          </DialogActions>
        </Box>
      </>
    );
  };
  
  export default AddEmployeeTypeUI;
  
  