import {  SvgIcon } from "@mui/material";
import { CenteredBox } from "./boxes";
import LogoImage from "./../../../assets/images/WEHEARHRMS.png";

import COSMOLogoImage from "./../../../assets/images/COSMOHRMS.png";

import { mode } from "../../../apis/endpoints";

export const Logo = ({ ...rest }) => {

  return (
    <CenteredBox {...rest}>

      <img
        src={mode == "cosmo" ? COSMOLogoImage : LogoImage}
        style={{ width: "100%", maxWidth: "160px", objectFit: "contain" }}
        alt=""
      />
    </CenteredBox>
  );
};


export const WorkMangementIcon = ({ ...rest }) => {
  return (
    <CenteredBox {...rest}>
      <SvgIcon viewBox="0 0 22 23" fill="none">
        <path
          fill="none"
          d="M11 11.5L1.33178 8.94554C0.709742 11.2999 0.968132 13.8002 2.05847 15.9776C3.14881 18.1549 4.99616 19.8596 7.25395 20.7718L11 11.5ZM11 11.5L11.1047 1.50055C8.88558 1.47731 6.72174 2.19293 4.95402 3.5347C3.1863 4.87647 1.91514 6.76815 1.34075 8.91181L11 11.5ZM21 11.5C21 17.0228 16.5229 21.5 11 21.5C5.47716 21.5 1.00001 17.0228 1.00001 11.5C1.00001 5.97715 5.47716 1.5 11 1.5C16.5229 1.5 21 5.97715 21 11.5Z"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </SvgIcon>
    </CenteredBox>
  );
};

export const ReimbursementIcon = ({ ...rest }) => {
  return (
    <CenteredBox {...rest}>
      <SvgIcon viewBox="0 0 22 23" fill="none">
        <path
          fill="none"
          d="M4.0002 8.3C4.0002 6.61984 4.0002 5.77976 4.32718 5.13803C4.6148 4.57354 5.07374 4.1146 5.63822 3.82698C6.27996 3.5 7.12004 3.5 8.8002 3.5H15.2002C16.8804 3.5 17.7204 3.5 18.3622 3.82698C18.9267 4.1146 19.3856 4.57354 19.6732 5.13803C20.0002 5.77976 20.0002 6.61984 20.0002 8.3V21.5L17.2502 19.5L14.7502 21.5L12.0002 19.5L9.2502 21.5L6.7502 19.5L4.0002 21.5V8.3Z"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fill="none"
          d="M10.0002 9.5H14.0002M10.0002 11.1667H14.0002M12.8335 15.5L10.0002 12.8333H11.0002C13.2225 12.8333 13.2225 9.5 11.0002 9.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />{" "}
      </SvgIcon>
    </CenteredBox>
  );
};

export const LeaveCalenderIcon = ({ ...rest }) => {
  return (
    <CenteredBox {...rest}>
      <SvgIcon viewBox="0 0 18 23" fill="none">
        <path
          fill="none"
          d="M19 9.5H1M14 1.5V5.5M6 1.5V5.5M7 15.5L9 17.5L13.5 13M5.8 21.5H14.2C15.8802 21.5 16.7202 21.5 17.362 21.173C17.9265 20.8854 18.3854 20.4265 18.673 19.862C19 19.2202 19 18.3802 19 16.7V8.3C19 6.61984 19 5.77976 18.673 5.13803C18.3854 4.57354 17.9265 4.1146 17.362 3.82698C16.7202 3.5 15.8802 3.5 14.2 3.5H5.8C4.11984 3.5 3.27976 3.5 2.63803 3.82698C2.07354 4.1146 1.6146 4.57354 1.32698 5.13803C1 5.77976 1 6.61984 1 8.3V16.7C1 18.3802 1 19.2202 1.32698 19.862C1.6146 20.4265 2.07354 20.8854 2.63803 21.173C3.27976 21.5 4.11984 21.5 5.8 21.5Z"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </SvgIcon>
    </CenteredBox>
  );
};

export const PayrollSummaryIcon = ({ ...rest }) => {
  return (
    <CenteredBox {...rest}>
      <SvgIcon viewBox="0 0 18 23" fill="none">
        <path
          fill="none"
          d="M11 1.76953V5.90007C11 6.46012 11 6.74015 11.109 6.95406C11.2049 7.14222 11.3578 7.2952 11.546 7.39108C11.7599 7.50007 12.0399 7.50007 12.6 7.50007H16.7305M11 16.5H5M13 12.5H5M17 9.48822V16.7C17 18.3802 17 19.2202 16.673 19.862C16.3854 20.4265 15.9265 20.8854 15.362 21.173C14.7202 21.5 13.8802 21.5 12.2 21.5H5.8C4.11984 21.5 3.27976 21.5 2.63803 21.173C2.07354 20.8854 1.6146 20.4265 1.32698 19.862C1 19.2202 1 18.3802 1 16.7V6.3C1 4.61984 1 3.77976 1.32698 3.13803C1.6146 2.57354 2.07354 2.1146 2.63803 1.82698C3.27976 1.5 4.11984 1.5 5.8 1.5H9.01178C9.74555 1.5 10.1124 1.5 10.4577 1.58289C10.7638 1.65638 11.0564 1.77759 11.3249 1.94208C11.6276 2.1276 11.887 2.38703 12.4059 2.90589L15.5941 6.09411C16.113 6.61297 16.3724 6.8724 16.5579 7.17515C16.7224 7.44356 16.8436 7.7362 16.9171 8.0423C17 8.38757 17 8.75445 17 9.48822Z"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </SvgIcon>
    </CenteredBox>
  );
};
