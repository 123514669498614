import EmployeeLeaveUi from './EmployeeLeaveUI'
import moment from 'moment'
import { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectEmployee } from '../../store/actions/selectedEmployeeAction'

const EmployyeLeavesController = () => {
  const [date, setDate] = useState(moment())
 
  const dispatch = useDispatch()
  const { selectedEmployee, user } = useSelector(state => state)
  const onUserChange = useCallback((user) => {
    dispatch(selectEmployee(user))
  }, [])
  return (
    <EmployeeLeaveUi
      date={date}
      setDate={setDate}
      onUserChange={onUserChange}
      defaultVal={selectedEmployee.user}
      
    />
  )
}
export default EmployyeLeavesController
