import { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import useValidate from '../../../store/hooks/useValidator'

import { useDispatch, useSelector } from 'react-redux'
import { callApiAction } from '../../../store/actions/commonAction'

import { Box, Chip, Typography } from '@mui/material'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { TASK_PRIORITY, TASK_STATUS } from '../../../utils/task.constants'
import { fetchGeneralTasksApi, fetchTasksApi } from '../../../apis/task.api'
import { callSnackBar } from '../../../store/actions/snackbarAction'
import { SNACK_BAR_VARIETNS } from '../../../utils/constants'
import TaskListUi from './TasksList'
import { EmployeeTaskUserContext } from '../EmployeeMain'
import { findObjectKeyByValue, getDateFiltersTime } from '../../../utils/helper'
import PriorityButton from './actionbuttons/PriorityButton'
import EditTaskButton from './actionbuttons/EditTaskButton'
import ViewTaskButton from './actionbuttons/ViewTaskButton'
import TaskDeleteButtoon from './actionbuttons/TaskDeleteButton'


// const PriorityButton = ({ params, setParams }) => {
//   let color = 'warning'
//   if (params.priority == TASK_PRIORITY.HIGH) {
//     color = 'error'
//   }
//   if (params.priority == TASK_PRIORITY.LOW) {
//     color = 'success'
//   }
//   return <Chip color={color} label={findObjectKeyByValue(params.priority, TASK_PRIORITY)} size='small' />
// }

const TaskController = ({ notMe, general, callBack = () => { } }) => {

  const selectedUser = useContext(EmployeeTaskUserContext)
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state)
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  const [list, setList] = useState({})
  const [tab, setTab] = useState(general ? 1 : 0)

  const dates = getDateFiltersTime('today')

  const [filters, setFilters] = useState({
    err: '',
    pageNo: 1,
    pageSize: 25,
    startDate: null,
    endDate: null,
    status: notMe ? TASK_STATUS.MARKED_AS_COMPLETED : undefined,
    onlyMe: !notMe,
    priority: null,
    status: general ? TASK_STATUS.PENDING : null,
searchable:['task_id','name'],
    startDate: general ? dates.startDate : null,
    endDate: general ? dates.endDate : null,
  })

  const columns = useMemo(() => [
    { id: 1, fieldName: 'task_id', label: 'Task Id', align: "left", sort: true, minWidth: '150px' },
    { id: 2, fieldName: 'due_date', sort: true, label: 'Due Date', align: "left", renderValue: (params, setParams) => moment(params.due_date).format("DD/MM/YYYY") },
    {
      id: 4, fieldName: 'name', label: 'Task Title', align: "left", minWidth: '150px', renderValue: (params, setParams) => <Typography fontSize="inherit"
        dangerouslySetInnerHTML={{ __html: params.name }}
      />
    },
    { id: 5, hide: selectedUser, fieldName: 'assigned_to', label: 'Assigned To', align: "left", minWidth: '150px', renderValue: (params, setParams) => params.assigned_to?.first_name },

    { id: 6, fieldName: 'assigned_by_name', label: 'Assigned By', align: "left", minWidth: '150px' },
    { id: 7, fieldName: 'start_date', sort: true, label: 'Assigned Date', align: "left", renderValue: (params, setParams) => moment(params.start_date).format("DD/MM/YYYY") },
    {
      id: 8, fieldName: 'priority', sort: true, label: 'Priority', align: "left",
      renderValue: (params, setParams) => <PriorityButton selectedUser={selectedUser} priority={params.priority} id={params._id} assignedTo={params.assigned_to?._id ? [params.assigned_to?._id] : [params.assigned_to]} status={params.status} />
    },
    {
      id: 3, fieldName: 'action', label: 'Action', align: "left", renderValue: (params, setParams) => <Box sx={{ display: "flex" }}>
        <ViewTaskButton selectedUser={selectedUser} params={params} setParams={setParams} />
        <EditTaskButton params={params} setParams={setParams} />
        <TaskDeleteButtoon params={params} setParams={setParams} />

      </Box>
    },
  ], [dispatch, selectedUser]);



  const fetchList = async (e) => {
    setLoading(true)
    dispatch(
      callApiAction(
        async () => general ? await fetchGeneralTasksApi({ ...filters }) : await fetchTasksApi({ ...filters, userId: selectedUser ? selectedUser._id : null }),
        async (response) => {
          setLoading(false)
          setList(response)
        },
        (err) => {
          setLoading(false)
          setList({})
          dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error))
        },
      ),
    )

  }



  useEffect(() => {
    fetchList()
  }, [filters, selectedUser])


  const onTabChange = (e, tabIndex) => {
    setTab(tabIndex)

    if (tabIndex == 0) {
      setFilters({
        ...filters,
        startDate: null,
        endDate: null,
        status: general ? TASK_STATUS.PENDING : undefined
      })
    }


    if (tabIndex == 1) {
      const dates = getDateFiltersTime('today')
      setFilters({
        ...filters,
        startDate: dates.startDate,
        endDate: dates.endDate,
        status: general ? TASK_STATUS.PENDING : undefined
      })
    }
    if (tabIndex == 2) {
      const dates = getDateFiltersTime('this_week')
      setFilters({
        ...filters,
        startDate: dates.startDate,
        endDate: dates.endDate,
        status: general ? TASK_STATUS.PENDING : undefined
      })
    }
    if (tabIndex == 3) {
      setFilters({
        ...filters,
        startDate: null,
        endDate: null,
        status: TASK_STATUS.MARKED_AS_COMPLETED
      })
    }
    if (tabIndex == 4) {
      setFilters({
        ...filters,
        startDate: null,
        endDate: null,
        status: TASK_STATUS.COMPLETED
      })
    }


  }



  const onStatusChange = (status) => {


    setFilters({
      ...filters,
      status: status
    })

  }

  const onPriorityChange = (priority) => {


    setFilters({
      ...filters,
      priority
    })

  }
  const onAddButtonClick = () => {
    navigate('/tasks/my-tasks/create-task')
  }
  //   if (notMe)
  //  return   <TaskTable

  //       loading={loading}
  //       filters={filters}
  //       list={list}
  //       fetchList={fetchList}

  //       setFilters={setFilters}
  //     />

  return (
    <TaskListUi
      columns={columns}
      general={general}
      onAddButtonClick={onAddButtonClick}
      loading={loading}
      filters={filters}
      list={list}
      fetchList={fetchList}
      onTabChange={onTabChange}
      tab={tab}
      setFilters={setFilters}
      onPriorityChange={onPriorityChange}
      onStatusChange={onStatusChange}
    />
  )
}
export default memo(TaskController)
