import { Visibility } from "@mui/icons-material"
import { Autocomplete, Button, ButtonGroup, Chip, CircularProgress, Grid, IconButton, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Tooltip, Typography } from "@mui/material"
import { Box } from "@mui/system"
import moment from "moment"
import { Fragment, memo, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { center } from "../../../assets/css/theme/common"
import CustomMonthPicker from "../../../components/layouts/common/CustomMonthPicker"
import { closeModal, openModal } from "../../../store/actions/modalAction"
import { LEAVE_RESPONSE, USER_ROLES } from "../../../utils/constants"
import { findObjectKeyByValue, leaveStatusColored } from "../../../utils/helper"
import CoffDetailsDialog from "./CoffDetailsDialog"
import LeaveDetailsDialog from "./LeaveDetailsDialog"
import { LEAVE_NAMES, LEAVE_STATUS } from "../../../utils/leave.constants"
import CoffDetailsController from "./CoffDetailsController"
import LeaveDetailsController from "./LeaveDetailsController"
import DataTable from "../../../components/tables/DataTable"


const LeaveRow = memo(
    ({ id, applicationDate, leaveType, startDate, coffDate, endDate, status, totalLeaves, callBack, isFromCoff, appliedBy, pendingAndGenral }) => {

        const { user } = useSelector(state => state)
        const dispatch = useDispatch()
        const [currentStatus, setCurrentStatus] = useState(status)
        const onOpenViewMore = () => {

            if (isFromCoff) {

                dispatch(
                    openModal(
                        {
                            component: <CoffDetailsController
                                leaveId={id}
                                callBack={(responseStatus) => { setCurrentStatus(responseStatus) }}
                                isFromModal={true}
                            />,
                            title: "Comp Off Application"
                        }
                    ),
                )
            } else {
                dispatch(


                    openModal(
                        {
                            component: <LeaveDetailsController
                                leaveId={id}
                                callBack={(responseStatus) => { setCurrentStatus(responseStatus) }}
                                isFromModal={true}
                            />,
                            title: "Leave Application"
                        }
                    ))
            }
        }
        const handleClose = () => {
            dispatch(closeModal())
        }
        return (
            <>
                <TableRow >
                    <TableCell>{applicationDate}</TableCell>
                    {pendingAndGenral && <TableCell>{appliedBy}</TableCell>}
                    {!isFromCoff && <TableCell >{leaveType}</TableCell>}
                    {!isFromCoff && <TableCell >{startDate == endDate ? startDate : `${startDate} - ${endDate}`}</TableCell>}
                    {isFromCoff && <TableCell>{coffDate}</TableCell>}
                    {!isFromCoff && <TableCell >{totalLeaves}</TableCell>}
                    <TableCell >{<Chip color={leaveStatusColored(currentStatus)} label={findObjectKeyByValue(currentStatus, LEAVE_STATUS)} />}</TableCell>
                    {<TableCell>
                        <Tooltip title={`view  details`}>
                            <IconButton onClick={onOpenViewMore}>
                                <Visibility />
                            </IconButton>
                        </Tooltip>
                    </TableCell>}
                </TableRow>
            </>
        )
    }
)


const LeaveSummaryUi = ({ columns, listLoading, filters, setFilters, list, callBack, isFromCoff, pendingAndGenral }) => {



    return (
        <>


            <Box sx={{ minHeight: "300px" }} mt={3}>
                <DataTable noPagination columns={columns} rows={list} count={list?.length} filters={filters} setFilters={setFilters} loading={listLoading} />
            </Box>



        </>
    )
}
export default LeaveSummaryUi