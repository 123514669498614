import { Box, Chip, CircularProgress, Divider, Grid, Typography, styled } from "@mui/material"
import { CenteredBox } from "../../components/layouts/common/boxes"
import GoogleMapReact from 'google-map-react';
import MapComponent from "../../components/MapComponents";
import moment from "moment";

import ImageComponent from "../../components/ImageComponent";
import { CUSTOMER_STATUS, VISIT_PRODUCTS, VISIT_STATUS, VISIT_SUB_TYPE_NAMES, VISIT_TYPES } from "../../utils/visits.constants";
import { findObjectKeyByValue } from "../../utils/helper";

const InquiryComponentOuter = styled(Box)(({ theme, indexNo }) => ({
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(3),
    marginTop: theme.spacing(4),
    position: "relative",
    border: "1px solid " + theme.palette.grey.main,
    "::after": {
        content: `'${indexNo}'`,
        position: "absolute",
        top: "0px",
        left: "0px",
        height: "20px",
        width: "auto",
        borderRadius: 2,
        padding:2,
        paddingLeft:theme.spacing(4),
        paddingRight:theme.spacing(4),
        align:"center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: theme.palette.light.main,
        transform: "translate(-2%,-60%)",
        background: theme.palette.dark.main,
    }
}))
const InquiryComponent = (props) => {

    const prefix = props.type == VISIT_TYPES.DOCTOR ? "Doctor/Clinic " : (props.type == VISIT_TYPES.STORE ? "Store" : "Customer")
    const contactPersonPrefix = props.type == VISIT_TYPES.CUSTOMER ? "Customer" : "Contact Person"
    return (
        <InquiryComponentOuter indexNo={"visit-"+props.count} >

            <Grid container spacing={4}>
                {props.place_id && props.place_id != "" && <Grid item md={4} xs={6}>
                    <Box>
                        <Typography align="center" lineHeight="100%" fontWeight="bold" variant="body2" mb={2} >
                            {prefix} Name
                        </Typography>
                        <Typography align="center" lineHeight="100%" variant="body2" dangerouslySetInnerHTML={{
                            __html: props.place_id.name,
                        }} >

                        </Typography>
                    </Box>
                </Grid>}
                {props.type && props.type != "" && <Grid item md={4} xs={6}>
                    <Box>
                        <Typography align="center" lineHeight="100%" fontWeight="bold" variant="body2" mb={2} >
                            Visit For
                        </Typography>
                        <Typography align="center" lineHeight="100%" variant="body2" >
                            {VISIT_SUB_TYPE_NAMES[props.sub_type]}
                        </Typography>
                    </Box>
                </Grid>}
                {props.products && props.products?.length > 0 && <Grid item md={4} xs={6}>
                    <Box>
                        <Typography align="center" lineHeight="100%" fontWeight="bold" variant="body2" mb={2} >
                            Products
                        </Typography>
                        <Typography align="center" lineHeight="100%" variant="body2" >
                            {props.products.map((item) => <Chip label={findObjectKeyByValue(item, VISIT_PRODUCTS)} size="small" />)}
                        </Typography>
                    </Box>
                </Grid>}

                {
                    <Grid item md={4} xs={6}>
                        <Box>
                            <Typography align="center" lineHeight="100%" fontWeight="bold" variant="body2" mb={2} >
                                {contactPersonPrefix} Name
                            </Typography>
                            <Typography align="center" lineHeight="100%" variant="body2" dangerouslySetInnerHTML={{ __html: props.name && props.name != '' ? props.name : "NA" }} >
                                { }
                            </Typography>
                        </Box>
                    </Grid>
                }

                {<Grid item md={4} xs={6}>
                    <Box>
                        <Typography align="center" lineHeight="100%" fontWeight="bold" variant="body2" mb={2} >
                            {contactPersonPrefix} Phone
                        </Typography>
                        <Typography align="center" lineHeight="100%" variant="body2" >
                            {props.phone && props.phone != '' ? props.phone : "NA"}
                        </Typography>
                    </Box>
                </Grid>}

                {<Grid item md={4} xs={6}>
                    <Box>
                        <Typography align="center" lineHeight="100%" fontWeight="bold" variant="body2" mb={2} >
                            {contactPersonPrefix} Email
                        </Typography>
                        <Typography align="center" lineHeight="100%" variant="body2" >
                            {props.email && props.email != '' ? props.email : "NA"}
                        </Typography>
                    </Box>
                </Grid>}
                {<Grid item md={4} xs={6}>
                    <Box>
                        <Typography align="center" lineHeight="100%" fontWeight="bold" variant="body2" mb={2} >
                           Address
                        </Typography>
                        <Typography align="center" textTransform="capitalize" lineHeight="100%" variant="body2" >
                         {props.address}, {props.city}
                        </Typography>
                    </Box>
                </Grid>}
                {<Grid item md={4} xs={6}>
                    <Box>
                        <Typography align="center" lineHeight="100%" fontWeight="bold" variant="body2" mb={2} >
                            Visited By
                        </Typography>
                        <Typography align="center" lineHeight="100%" variant="body2" >
                            {props.created_by && props.created_by?.first_name != '' ? (props.created_by?.first_name+" "+props.created_by?.last_name) : "NA"}
                        </Typography>
                    </Box>
                </Grid>}
{/* 
                {<Grid item md={12} xs={12}>
                    <Box>
                        <Typography align="center" lineHeight="100%" fontWeight="bold" variant="body2" mb={2} >
                            Remarks
                        </Typography>
                        <Typography align="center" lineHeight="100%" variant="body2" >
                            {props.remarks && props.remarks != '' ? props.remarks : "NA"}
                        </Typography>
                    </Box>
                </Grid>} */}


                <Grid item xs={12}>
                    <Typography align="center" lineHeight="100%" fontWeight="bold" variant="h5" mb={2} >
                        Images
                    </Typography>
                    <Grid container spacing={2}>
                        {
                            props.images &&props.images?.length>0 ? props.images?.map((image) =>
                                <Grid item xs={4} md={4} key={image}>
                                    <ImageComponent src={image}  />
                                </Grid>

                            ):  <Grid item xs={12 }><CenteredBox>
                                <Typography align="center">No Images</Typography>
                            </CenteredBox>
                            </Grid>
                        }
                        {/* <ImageComponent src={} /> */}

                    </Grid>
                </Grid>
            </Grid>

        </InquiryComponentOuter>
    )
}
const VisitDetailsUi = ({ loading, list }) => {

    return <Box>
        {loading && <CenteredBox mt={4} mb={4}><CircularProgress /></CenteredBox>}
        {
            !loading && list && list['_id'] && <Box>
                <Box mb={4}>

                    <Grid container spacing={3}>
                        <Grid item md={6} sm={12}>

                            <Box sx={{ width: "100%", height: '100%', minHeight: "200px", position: "relative", borderRadius: 1 }}>
                                {list.location?.coordinates?.[0] ? <MapComponent
                                    markers={[
                                        {
                                            lat: list.location.coordinates[1],
                                            lng: list.location.coordinates[0],
                                            address: list.start_time
                                        }
                                    ]}

                                /> : <CenteredBox sx={{ height: "100%", width: "100%", background: "lightgray" }}>No Location</CenteredBox>}
                            </Box>
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }} mb={3} mt={2}>
                                <Typography lineHeight="100%" fontWeight="bold" variant="body1">
                                    Visited By:
                                </Typography>
                                <Typography textTransform="capitalize" lineHeight="100%" variant="body1" sx={{ ml: 2 }}>
                                    {list.created_by?.first_name} {list.created_by?.last_name}
                                </Typography>
                            </Box>

                            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }} mb={3}>
                                <Typography lineHeight="100%" fontWeight="bold" variant="body1">
                                    Status:
                                </Typography>
                                <Typography textTransform="capitalize" lineHeight="100%" variant="body1" sx={{ ml: 2 }}>
                                    <Chip label={findObjectKeyByValue(list.status, VISIT_STATUS)} size="small" color={list.status == VISIT_STATUS.CLOSE ? "success" : "warning"} />
                                </Typography>
                            </Box>

                            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }} mb={3}>
                                <Typography lineHeight="100%" fontWeight="bold" variant="body1">
                                    Type:
                                </Typography>
                                <Typography textTransform="capitalize" lineHeight="100%" variant="body1" sx={{ ml: 2 }}>
                                    {findObjectKeyByValue(list.type, VISIT_TYPES)?.toLowerCase?.()}
                                </Typography>
                            </Box>

                            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }} mb={3}>
                                <Typography lineHeight="100%" fontWeight="bold" variant="body1">
                                    Start Time:
                                </Typography>
                                <Typography textTransform="capitalize" lineHeight="100%" variant="body1" sx={{ ml: 2 }}>
                                    {moment(list.start_time).format("DD/MM/YYYY HH:mm")}
                                </Typography>
                            </Box>

                            {list.end_time &&
                                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }} mb={3}>
                                    <Typography lineHeight="100%" fontWeight="bold" variant="body1">
                                        End Time:
                                    </Typography>
                                    <Typography textTransform="capitalize" lineHeight="100%" variant="body1" sx={{ ml: 2 }}>
                                        {moment(list.end_time).format("DD/MM/YYYY HH:mm")}
                                    </Typography>
                                </Box>}

                            {list.customer_current_status?.toString() && list.customer_current_status != CUSTOMER_STATUS.NONE &&
                                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }} mb={3}>
                                    <Typography lineHeight="100%" fontWeight="bold" variant="body1">
                                        Customer Status:
                                    </Typography>
                                    <Typography textTransform="capitalize" lineHeight="100%" variant="body1" sx={{ ml: 2 }}>
                                        {findObjectKeyByValue(list.customer_current_status, CUSTOMER_STATUS)?.toLowerCase()}
                                    </Typography>
                                </Box>}
                        </Grid>
                    </Grid>


                    <Box>
                        {
                            list.visits && Array.isArray(list.visits) && [list, ...list.visits].map((inquiry, index) => <InquiryComponent count={list.visits+1-index} indexNo={index + 1} key={inquiry._id} {...inquiry} />)
                        }
                    </Box>
                </Box>



            </Box>
        }

        {!loading && (!list || Object.keys(list).length == 0) && <CenteredBox mt={4} mb={4}><Typography variant="h3" >No task Found</Typography></CenteredBox>}
    </Box>
}
export default VisitDetailsUi