import moment from "moment";
import { actions } from "../../utils/constants";


const initialState = {
    user: null,
    date: moment()

}
const selectedEmployeeReducer = (state = initialState, action) => {

    switch (action.type) {
        case actions.SELECT_USER: return { ...state, user: action.user };
        case actions.CHANGE_DATE: return { ...state, date: action.date };
        default: return { ...state }
    }

}
export default selectedEmployeeReducer