import { Avatar, Box, Typography, styled, useMediaQuery } from "@mui/material"
import { Logo } from "../../components/layouts/common/Logo"
import { useTheme } from "@emotion/react"
import { setStringView, toTitleCase, unEscapeStr } from "../../utils/helper"

import BirthdayCelebration from '../../assets/images/BirthdayCelebration.png'
import Celebration from '../../assets/images/celebration.png'
import { memo } from "react"

const BirthdayBoxOuter = styled(Box)(({ theme }) => ({
    borderRadius: theme.shape.borderRadius,

    width: "100%",
    height: "100%",

    top: "0px",
    left: "0px",
    zIndex: 0,
    background: "linear-gradient(292deg, rgba(0,0,0,1) 0%, rgba(89,49,10,1) 45%)",
    overflow: "hidden",
}))
const BirthdayBox = ({ data = {} }) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))
    return <>
        <Box sx={{ position: "relative", width: "100%" }} mt={3}>
            <BirthdayBoxOuter >
                <Box sx={{ display: "flex", flex: 1 ,position:"relative",zIndex:1}} p={3}>

                    {data.profile_url && <Avatar sizes={isMobile ? "small" : "large"} src={unEscapeStr(data.profile_url)} sx={{ width: isMobile ? "35px" : "70px", height: isMobile ? "35px" : "70px", bgcolor: "primary.main" }}>


                    </Avatar>}
                    {!data.profile_url && <Avatar sizes={isMobile ? "small" : "large"} sx={{ width: isMobile ? "35px" : "70px", height: isMobile ? "35px" : "70px", bgcolor: "primary.main" }}>

                        {<Typography variant="h1">
                            {
                                data.name?.[0]?.toUpperCase()
                            }
                        </Typography>}
                    </Avatar>}
                    <Box ml={3} sx={{ display: "flex", flexDirection: "column", flex: 1, justifyContent: "center" }}>
                        <Typography fontWeight="bold" variant="h2" color="light.main">
                            Happy Birthday To
                        </Typography>
                        <Typography variant="h3" color={'light.main'} >
                            
                            {setStringView(toTitleCase(data.first_name+" "+data.last_name), 'end')}
                        </Typography>
                    </Box>
                    <Box sx={{ position: "absolute", right: "0px", bottom: "0px" }}>
                        <img src={BirthdayCelebration} style={{ height: "120px" }} alt="" />

                    </Box>

                </Box>
                <Box sx={{ position: "absolute", right: "0px", bottom: "0px", height: "100%", width: "80%", zIndex: 0 }}>
                    <img src={Celebration} style={{ width: "100%", height: "100%", objectFit: "cover" }} alt="" />

                </Box>
            </BirthdayBoxOuter>


        </Box>
    </>
}
export default memo(BirthdayBox)