import { Box, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import CustomInput from "../../components/inputs/CustomInput";
import SubmitButton from "../../components/button/SubmitButton";
import { useDispatch } from "react-redux";
import { callSnackBar } from "../../store/actions/snackbarAction";
import { SNACK_BAR_VARIETNS } from "../../utils/constants";

function DocumentList({ loading, taskInput, setTaskInput, handleSubmitData,closeDocumentListModal }) {
  const [temp, setTemp] = useState("");
  const dispatch = useDispatch();
  const handleSubmit = () => {
    if (temp === "") {
      dispatch(callSnackBar("Please enter details", SNACK_BAR_VARIETNS.error));
    } else {
      handleSubmitData(temp);
    }
  };

  const handleClose = () =>{
    closeDocumentListModal();
  }
  return (
    <>
      <Grid md={12} item>
        <CustomInput
          disabled={loading}
          value={temp}
          onChange={(e) => setTemp(e.target.value)}
          type="text"
          placeholder="Write Here..."
        />
      </Grid>
      <Box style={{ marginBottom: "20px" }} />
      <Grid md={2} item sx={{ float: "right" }}>
        <SubmitButton
          onClick={handleClose}
          title={"Close"}
          disabled={loading}
          variant="contained"
          sx={{ width: "15%" }}
        >
        </SubmitButton>
        <SubmitButton
          type="submit"
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          title={" Add "}
          disabled={loading}
          sx={{ width: "20%" }}
        ></SubmitButton>
      </Grid>
    </>
  );
}

export default DocumentList;
