import { Add } from "@mui/icons-material"
import {  IconButton, Tooltip } from "@mui/material"

import { REIMBURSEMENT_ADVANCE_PAYMENT_STATUS, USER_ROLES } from "../../utils/constants"
import { useDispatch, useSelector } from "react-redux"
import {openModal } from "../../store/actions/modalAction"

import AddAdvancePaymentController from "./AddAdvancePaymentController"


const AddAmountButton = ({ params, setParams }) => {
    const { user } = useSelector(state => state)
    const dispatch = useDispatch()

    const onClick = () => {
        
        dispatch(openModal({
            title: "Add Amount",
            size:"xs",
            component: <AddAdvancePaymentController id={params._id} callBack={(item) => {
                setParams({ ...params, amount: Number(params.amount)+Number(item.amount) })
            }} />
        }))

    }
    if (params.status==REIMBURSEMENT_ADVANCE_PAYMENT_STATUS.CLOSE||user.data.role == USER_ROLES.employee)
        return <></>


    return <Tooltip title="Add Amount" >
        <IconButton onClick={onClick}>
            <Add />
        </IconButton>
    </Tooltip>
}
export default AddAmountButton