import { Edit } from "@mui/icons-material"
import { IconButton } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { openModal } from "../../../../store/actions/modalAction"
import TaskUpdateController from "../TaskUpdateController"
import { TASK_STATUS } from "../../../../utils/task.constants"

const EditTaskButton = ({ params, setParams }) => {
    const { user } = useSelector(state => state)
    const dispatch = useDispatch()
    const onEdit = () => {
        dispatch(openModal({
            title: "Update Task", component: <TaskUpdateController id={params._id}
                due_date={params.due_date}
                start_date={params.start_date}
                description={params.description}
                callBack={(updatedData) => setParams({ ...params, ...updatedData })}
            />, size: "md"
        }))
    }
    const assignedTo = params.assigned_to?._id ? params.assigned_to?._id : params.assigned_to
    const allowedToUpdateOrDelete = assignedTo != user.data._id && params.status == TASK_STATUS.PENDING

    if (!allowedToUpdateOrDelete)
        return <></>
    return <IconButton onClick={onEdit}>
        <Edit color="info" />
    </IconButton>
}
export default EditTaskButton