import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { callApiAction } from '../../store/actions/commonAction';
import { fetchTeamListApi } from '../../apis/team.api';
import { toTitleCase } from '../../utils/helper';
import TeamDetailsUi from './TeamDetailsUi';
import { Info } from '@mui/icons-material';
import { Box, IconButton, Tooltip } from '@mui/material';
import { openModal } from '../../store/actions/modalAction';
import TeamMemberProfileController from './TeamMemberProfileController';
import { fetchTeamDetailsAction } from '../../store/actions/teamAction';

function TeamDetailsController() {
    const user = useSelector((state) => state.user);
    const [list, setList] = useState({});
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch()
    const {team} = useSelector((state) => state)
    // console.log("team data", team.team_data)
    // console.log("list data", list)
    const openTeamMemberDetailsModal = useCallback((id) => {
        dispatch(openModal({
            title : 'User Details',
            component : <TeamMemberProfileController id={id} />
        }))
    },[])

    const [filters, setFilters] = useState({
        pageNo: 1,
        pageSize: 12,
        search: "",
        role: null,
        sort: 'employee_id',
        sortDirection: 1,
        searchable: ["name", "email"],
    });
    const handleChangePage = (e, newVal) => {

        setFilters({ ...filters, pageNo: newVal + 1 })
    }
    const handleChangeRowsPerPage = (e) => {
        setFilters({ ...filters, pageNo: 1, pageSize: e.target.value })
    }

    const fetchTeamList = () => {
        setLoading(true)
        dispatch(
            callApiAction(
                async () => await fetchTeamListApi({...filters}),
                (response) => {
                    setList(response)
                    setLoading(false)
                },
                (err) => {
                    setLoading(false)
                }
            )
        )
    }
    
    const getTeamDetailsList = () => {
        if (!team.team_data || team.team_data.length === 0 || JSON.stringify(filters)!=JSON.stringify(team.team_filters)) {
            dispatch(fetchTeamDetailsAction(filters));
        }
    }
    useEffect(() => {
        getTeamDetailsList()
        fetchTeamList();
      }, [filters]);
  return (
    <>
    <TeamDetailsUi
        filters={filters}
        setFilters={setFilters}
        // loading={loading}
        loading={team.team_loading}
        // list={list}
        list={team.team_data}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
    />
   </>
  )
}

export default TeamDetailsController
