import { useCallback, useEffect, useMemo, useState } from "react"
import { useDispatch } from 'react-redux'
import { callApiAction } from "../../../store/actions/commonAction"
import { getUserApi, updateUserField } from "../../../apis/user.api"
import { useParams } from "react-router-dom"
import { openModal } from "../../../store/actions/modalAction"
import LeaveSummaryUi from "./LeaveSummaryUi"
import { getLeavesApi } from "../../../apis/leave.api"
import moment from "moment"
import { findObjectKeyByValue } from "../../../utils/helper"
import { LEAVE_STATUS, LEAVE_TYPE } from "../../../utils/leave.constants"
import { Chip, Typography } from "@mui/material"
import LeaveViewButton from "./LeaveViewButton"
const LeaveSummaryController = ({ userId }) => {

    const [list, setList] = useState([])



    const [listLoading, setListLoading] = useState(false)

    const dispatch = useDispatch()
    const params = useParams()


    const columns = useMemo(() => [
        { id: 1, fieldName: 'createdAt', label: 'Application Date', align: "left", sort: true, minWidth: '150px', renderValue: (params) => moment(params.createdAt).format("DD/MM/YYYY") },
        { id: 2, fieldName: 'type', label: 'Leave Type', align: "left", renderValue: (params) => <Typography fontSize="inherit" textTransform="capitalize">{findObjectKeyByValue(params.type, LEAVE_TYPE)}</Typography> },
        {
            id: 3, fieldName: 'from', label: 'Leave Date', align: "left", sort: true, minWidth: '150px', renderValue: (params) => {
                let str = moment(params.from).format("DD/MM/YYYY")
                const to = moment(params.to).format("DD/MM/YYYY")
                if (str != to) {
                    str += (" - " +to)
                }
                return str
            }
        },
        {
            id: 4, fieldName: 'leave_count', label: 'Total Leave', align: "left", minWidth: '150px'
        },
        {
            id: 5, fieldName: 'status', label: 'Status', align: "left", sort: true, minWidth: '50px',renderValue: (params, setParams) =><Chip 
            color={params.status==LEAVE_STATUS.APPROVED?"success":(params.status==LEAVE_STATUS.REJECTED?"error":"warning")}
            label={findObjectKeyByValue(params.status,LEAVE_STATUS)}
            size="small"
            />
        },        
        {
            id: 6, fieldName: 'action', label: 'Action', align: "left", renderValue: (params, setParams) =>   <LeaveViewButton id={params._id} />
        },
    ], [dispatch]);

    const [filters, setFilters] = useState({

        date: moment(),
        userId: userId

    })

    const fetchList = () => {
        setListLoading(true)
        const parmasToPass = { ...filters }
        parmasToPass.date = parmasToPass.date.valueOf()
        dispatch(callApiAction(
            async () => await getLeavesApi({ ...parmasToPass }),
            (response) => {
                setList(response)

                setListLoading(false)
            },
            (err) => {
                setListLoading(false)
            }
        ))
    }



    useEffect(() => {
        fetchList()
    }, [filters])



    return (
        <>
            <LeaveSummaryUi
                columns={columns}
                listLoading={listLoading}
                filters={filters}
                setFilters={setFilters}
                callBack={fetchList}
                list={list}

            />

        </>
    )
}
export default LeaveSummaryController