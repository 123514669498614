import {
  Box,
  Checkbox,
  DialogActions,
  Grid,
  Paper,
  Typography
} from "@mui/material";
import CustomInput from "../../components/inputs/CustomInput";

import SubmitButton from "../../components/button/SubmitButton";
import { CenteredBox } from "../../components/layouts/common/boxes";


const SettleAdvancePaymentsUI = ({
  state,
  fetchLoading,
  loading,
  onSubmit,
  fields,
  setFields,
}) => {
  return (
    <>
      <Box
        onSubmit={onSubmit}
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          overflowY: "auto",
          position: "relative",
        }}
        maxHeight="100%"
      >
        {
          fields.err && <Typography color="error" variant="h6">
            {fields.err}
          </Typography>
        }
        {
          !loading && <>
            <Paper component={Box} p={2} variant="outlined" >
              <Grid container >
                <Grid item xs={12} sm={6} >
                  <Box mb={3}>
                    <Typography variant="h4" >
                      Employee Name:
                    </Typography>
                    <Typography variant="caption" >
                      {state?.user_id?.first_name} {state?.user_id?.last_name}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} >
                  <Box mb={3}>
                    <Typography variant="h4" >
                      Advance Taken For:
                    </Typography>
                    <Typography variant="caption" >
                      {state?.purpose}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>

              <Box sx={{ display: "flex" }}>
                <CenteredBox component={Paper} flex={1} flexDirection="column" variant="outlined" p={2} >
                  <Typography variant="h3" >
                    {state?.amount} Rs.
                  </Typography>
                  <Typography variant="caption" >
                    Total Paid
                  </Typography>
                </CenteredBox>
                <CenteredBox p={2} ml={2} mr={2}>
                  <Typography variant="h3" >
                    -
                  </Typography>
                </CenteredBox>
                <CenteredBox component={Paper} flex={1} flexDirection="column" variant="outlined" p={2} >
                  <Typography variant="h3" >
                    {state?.used_amount} Rs.
                  </Typography>
                  <Typography variant="caption" >
                    Total Used
                  </Typography>
                </CenteredBox>
                <CenteredBox p={2} ml={2} mr={2}>
                  <Typography variant="h3" >
                    =
                  </Typography>
                </CenteredBox>
                <CenteredBox component={Paper} flex={1} ml={2} flexDirection="column" variant="outlined" p={2} >
                  <Typography variant="h3" >
                    {state?.amount - state?.used_amount} Rs.
                  </Typography>
                  <Typography variant="caption" >
                    Remained Amount
                  </Typography>
                </CenteredBox>
              </Box>
            </Paper>
          </>
        }
        {!loading && (
          <>


            <Box sx={{display:"flex",alignItems:"center"}}>
              
            <Checkbox  checked={fields.checked} onChange={(e)=>setFields({...fields,checked:e.target.checked})} />
              <Typography variant="caption" lineHeight="100%" > {state?.user_id?.first_name } {state?.user_id?.last_name } returned {state?.amount-state?.used_amount} Rs.?  </Typography>
              
            </Box>

            <CustomInput
              multiline={true}
              rows={4}
              disabled={loading || fetchLoading}
              value={fields.remarks}
              onChange={(e) => setFields({ ...fields, remarks: e.target.value })}
              type="text"
              label="Remarks*"
            />




          </>
        )}

        <DialogActions>
          <Box sx={{ float: "right", marginTop: "7px" }}>
            <SubmitButton
              loading={loading || fetchLoading}
              disabled={loading || fetchLoading || !fields.checked}
              type="submit"
              variant="contained"
              color="primary"
              title="Settle"
            >

            </SubmitButton>
          </Box>
        </DialogActions>
      </Box >
    </>
  );
};

export default SettleAdvancePaymentsUI;
