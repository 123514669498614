import { FormControlLabel, Radio, RadioGroup, styled } from "@mui/material"


const CustomRadioGroup = styled(RadioGroup)(({ theme }) => ({
    width: "100%",
    border: "1px solid " + theme.palette.text.primary,
    padding: theme.spacing(2),
    flexWrap: "wrap",
    display: "flex !important",
    borderRadius: theme.shape.borderRadius,

    [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1) +" !important",
        paddingLeft:theme.spacing(2)+" !important",
        paddingRight:theme.spacing(2)+" !important",
        height:"unset"
    }
}))

const CustomFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
    display: "flex",
    flex: 1,
    fontSize: "5px !important",
    [theme.breakpoints.down('sm')]: {
        "*": {
            fontSize: theme.typography.subtitle2.fontSize +" !important"
        }
    }
}))

const CustomRadioButtons = ({ options, ...props }) => {
    return <CustomRadioGroup

        row
        mt={2}
        {...props}
    >
        {
            options.map((item) => <CustomFormControlLabel key={item.value} control={<Radio />} {...item} />)
        }

    </CustomRadioGroup>
}
export default CustomRadioButtons