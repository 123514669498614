import { SvgIcon } from "@mui/material"

const CakeIcon = () => {
    return <SvgIcon fill="none"
        viewBox="-20 0 369.507 369.507"
        fontSize="inherit"
    >
        <g xmlns="http://www.w3.org/2000/svg">
            <rect x="20.42" y="236.823" style={{ }} fill="#65513C" width="328.662" height="132.684" />
            <rect x="20.42" y="307.889" style={{ }} fill="#EED898" width="328.662" height="20.82" />
            <path style={{ }} fill="#EE9BAC" d="M348.552,210.068c10.052,0,18.2,8.148,18.2,18.2v21.836c0,20.103-16.297,36.4-36.4,36.4   c-20.103,0-36.4-16.296-36.4-36.4c0,20.103-16.297,36.4-36.399,36.4c-20.103,0-36.4-16.296-36.4-36.4   c0,20.103-16.297,36.4-36.4,36.4s-36.4-16.296-36.4-36.4c0,20.103-16.297,36.4-36.4,36.4s-36.399-16.296-36.399-36.4   c0,20.103-16.297,36.4-36.4,36.4s-36.4-16.296-36.4-36.4v-21.836c0-10.052,8.148-18.2,18.2-18.2L348.552,210.068L348.552,210.068z" />
            <rect x="74.491" y="121.041" style={{ }} fill="#65513C" width="220.521" height="89.026" />
            <rect x="74.491" y="166.842" style={{ }} fill="#EED898" width="220.521" height="13.969" />
            <path style={{ }} fill="#EE9BAC" d="M294.656,101.207c6.744,0,12.211,5.466,12.211,12.211v14.651c0,13.49-10.935,24.424-24.423,24.424   c-13.489,0-24.424-10.934-24.424-24.424c0,13.49-10.934,24.424-24.423,24.424s-24.424-10.934-24.424-24.424   c0,13.49-10.935,24.424-24.423,24.424s-24.423-10.934-24.423-24.424c0,13.49-10.936,24.424-24.424,24.424   s-24.423-10.934-24.423-24.424c0,13.49-10.935,24.424-24.424,24.424c-13.488,0-24.423-10.934-24.423-24.424v-14.651   c0-6.745,5.467-12.211,12.211-12.211H294.656z" />
            <g>
                <rect x="131.913" y="45.886" style={{ }} fill="#EED898" width="18.004" height="55.321" />
                <path style={{ }} fill="#FFB819" d="M154.222,28.366c0-7.35-13.307-28.366-13.307-28.366s-13.307,21.016-13.307,28.366    s5.957,13.308,13.308,13.308C148.265,41.673,154.222,35.715,154.222,28.366z" />
            </g>
            <g>
                <rect x="175.745" y="45.886" style={{ }} fill="#EED898" width="18.004" height="55.321" />
                <path style={{ }} fill="#FFB819" d="M198.055,28.366c0-7.35-13.307-28.366-13.307-28.366S171.44,21.016,171.44,28.366    s5.958,13.308,13.308,13.308C192.098,41.673,198.055,35.715,198.055,28.366z" />
            </g>
            <g>
                <rect x="219.578" y="45.886" style={{ }} fill="#EED898" width="18.004" height="55.321" />
                <path style={{ }} fill="#FFB819" d="M241.888,28.366c0-7.35-13.307-28.366-13.307-28.366s-13.308,21.016-13.308,28.366    s5.958,13.308,13.308,13.308C235.93,41.673,241.888,35.715,241.888,28.366z" />
            </g>
        </g>
    </SvgIcon>
}
export default CakeIcon