import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Grid,
  IconButton,
  Link,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TableBody,
  ButtonBase,
} from "@mui/material";
import { CenteredBox } from "../../components/layouts/common/boxes";
import {
  Cancel,
  CheckBox,
  CheckCircle,
  Visibility,
  Add,
} from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import { center } from "../../assets/css/theme/common";
import { Skeleton } from "@mui/material";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { openModal } from "../../store/actions/modalAction";
import DataTable from "../../components/tables/DataTable";
import AddFeedbackFormController from "./AddFeedbackFormController";

const LeaveTypeUI = ({
  setState,
  callBack,
  loading,
  state,
  columns,
  filters,
  setFilters,
}) => {
  const dispatch = useDispatch();
  // const addFeedback = useCallback(() => {
  //   dispatch(
  //     openModal({
  //       title: "Add Feedback",
  //       component: <AddFeedbackFormController callBack={callBack} />,
  //       size: "sm",
  //     })
  //   );
  // }, []);

  //   const UpdateLeaveType = useCallback(
  //     (
  //       id,
  //       initialname,
  //       initialcolor,
  //       initialsystem_default,
  //       initialshort_name,
  //       initialidentifier,
  //       initialpriority
  //     ) => {
  //       dispatch(
  //         openModal({
  //           title: "Update LeaveType",
  //           component: (
  //             <UpdateLeaveTypeController
  //               callBack={callBack}
  //               id={id}
  //               initialname={initialname}
  //               initialcolor={initialcolor}
  //               initialsystem_default={initialsystem_default}
  //               initialshort_name={initialshort_name}
  //               initialidentifier={initialidentifier}
  //               initialpriority={initialpriority}
  //             />
  //           ),
  //           size: "sm",
  //         })
  //       );
  //     },
  //     []
  //   );

  return (
    <>
      {!state && !loading && (
        <CenteredBox>
          <Typography variant="h3">Loading Data...</Typography>
        </CenteredBox>
      )}
      <Box m={3}>
        <Box>
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: "24px",
              lineHeight: "40px",
              color: "#0D0D0D",
            }}
          >
            Feedback Data
          </Typography>
          {/* <Button
            onClick={addFeedback}
            sx={{
              display: "flex",
              height: "100%",
              zIndex: 0,
              textTransform: "capitalize",
              boxShadow: "none",
              border: "1px solid #393939",
              width: "100%",
              height: "56px",
              ":hover": {
                boxShadow: "none",
              },
            }}
          >
            <Add color="primary" /> &nbsp;Add Feedback
          </Button> */}
        </Box>
        <Box sx={{ minHeight: "300px" }} mt={3}>
          <DataTable
            columns={columns}
            rows={state?.result ?? []}
            count={state?.total ?? 0}
            filters={filters}
            setFilters={setFilters}
            loading={loading}
          />
        </Box>
      </Box>
    </>
  );
};
export default LeaveTypeUI;
