import { Delete } from "@mui/icons-material"
import { CircularProgress, IconButton, Typography } from "@mui/material"
import { useState } from "react"
import { deleteHoliday } from "../../apis/holiday.api"
import { callApiAction } from "../../store/actions/commonAction"
import { callSnackBar } from "../../store/actions/snackbarAction"
import { SNACK_BAR_VARIETNS } from "../../utils/constants"
import { useDispatch, useSelector } from "react-redux"
import { closeModal, openModal } from "../../store/actions/modalAction"
import { deleteDepartment } from "../../apis/department"
import { deleteEmployeeType } from "../../apis/employeeType.api"
import { setEmployeeTypeDataOnDeleteAction } from "../../store/actions/settingsAction"


const DeleteEmployeeTypeButton = ({ params, setParams }) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState()
    const {settings} = useSelector((state) => state)

    const onDelete = () => {
        setLoading(true);
        dispatch(
            callApiAction(
                async () => await deleteEmployeeType({ id: params._id }),
                (response) => {
                    
                    setLoading(false);
                    setParams({})
                    const updatedData = settings.employeeType_data.filter(item => item._id != params._id)
                    dispatch(setEmployeeTypeDataOnDeleteAction(updatedData, settings.employeeType_filters))
                    dispatch(callSnackBar("Employee Type Deleted", SNACK_BAR_VARIETNS.suceess))
                },
                (err) => {
                    setLoading(false);
                    dispatch(callSnackBar("Can not delete Employee Type", SNACK_BAR_VARIETNS.error))
                }
            )
        );
        dispatch(closeModal())
    }
    const onClick = () => {
        dispatch(openModal({
            title:"Alert!",
            component:<Typography>{"Are sure to want to delete "+params.name+" ?"}</Typography>,
            onConfirm:onDelete,
            size:"sm",
            confirmText:"Delete",
            onCancle:()=>dispatch(closeModal())
        }))
    }
    if (loading)
        return <CircularProgress />
    return <IconButton onClick={onClick}>
        <Delete color="error" />
    </IconButton>
}
export default DeleteEmployeeTypeButton