import { actions } from "../../utils/constants";
const initialState = {
    
    data: [

    ],

}
const announcementReducer = (state = initialState, action) => {
    // console.log("states",action);
    switch (action.type) {
        case actions.SET_ANNOUNCEMENT_DATA: return { ...state, data: action.data};
        // case actions.ANNOUNCEMENT_READ: return { ...state, new: 0 };d
        default: return { ...state }
    }

}

export default announcementReducer





