import { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { callApiAction } from "../../../store/actions/commonAction"
import VisitPlacesListUI from "./VisitPlacesListUI"

import { Box, IconButton } from "@mui/material"

import { getPlacesApi } from "../../../apis/visits.api"
import { Info } from "@mui/icons-material"
import { openModal } from "../../../store/actions/modalAction"
import PlaceDetailsController from "../placedetails/PlaceDetailsController"
import moment from "moment"
const ActionButton = ({ params, setParams }) => {
    const dispatch = useDispatch()
    const onViewMoreClick = ()=>{
        dispatch(openModal({
            component:<PlaceDetailsController place_id={params._id} />,
            size:"lg",
        }))
    }
return <IconButton onClick={onViewMoreClick}>
    <Info color="info" />
</IconButton>
}
const VisitPlacesListController = ({ category }) => {
    const { user, selectedEmployee } = useSelector(state => state)
    const dispatch = useDispatch()

    const [state, setState] = useState({})
    const [loading, setLoading] = useState(false)

    const fetchApi = getPlacesApi


    const columns = useMemo(() => [

        { id: 1, fieldName: 'name', label: 'Name', align: "left", sort: true, minWidth: '150px' },
        { id: 2, fieldName: 'updatedAt', label: 'Last Updated on', align: "left", sort: true, minWidth: '150px',renderValue:(params)=>moment(params.updatedAt).format("DD/MM/YYYY") },

        { id: 4, fieldName: 'total_visits', label: 'Total Visits', align: "left" },

        {
            id: 3, fieldName: 'action', label: 'Action', align: "left", renderValue: (params, setParams) => <Box sx={{ display: "flex" }}>

<ActionButton params={params} setParams={setParams} />

            </Box>
        },
    ], [dispatch]);
    const [filters, setFilters] = useState({
        pageSize: 10,
        pageNo: 1,
        sort: "updatedAt",
        category,
        only_me: true,
        sortDirection: -1
    })

    const fetchList = () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await fetchApi({ ...filters, category, userId: selectedEmployee.user?._id }),
            (response) => {
                setState(response)

                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
    }


    useEffect(() => {
        fetchList()
    }, [filters, category, selectedEmployee.user?._id])

    return <VisitPlacesListUI columns={columns} filters={filters} setFilters={setFilters} setState={setState} callBack={fetchList} loading={loading} state={state} />
}
export default VisitPlacesListController