import { memo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { callApiAction } from '../../store/actions/commonAction'
import { getCoffApi, getLeavesApi, getLogsApi, getMonthlyLeavesApi } from '../../apis/leave.api'
import moment from 'moment'
import Calendar from '../../components/layouts/Calendar'
import CalendarBox from './VisitCalendarInnerBox'

import {

  CALENDAR_ITEM_TYPES,
  LEAVE_COLORS,
  SNACK_BAR_VARIETNS,

} from '../../utils/constants'

import { LEAVE_NAMES, LEAVE_STATUS, LEAVE_TYPE } from '../../utils/leave.constants'
import { callSnackBar } from '../../store/actions/snackbarAction'
import { getMonthlyVisits } from '../../apis/visits.api'
import CustomMonthPicker from '../../components/layouts/common/CustomMonthPicker'
import { changeDate } from '../../store/actions/selectedEmployeeAction'
import { Box, Paper, Typography } from '@mui/material'
import { VISIT_COLORS, VISIT_TYPE_NAMES } from '../../utils/visits.constants'
import { CenteredBox } from '../../components/layouts/common/boxes'

const VisitCalendarController = ({ forEmployee }) => {

  const { holiday, selectedEmployee, user } = useSelector((state) => state)


  const activeUser = forEmployee ? selectedEmployee.user : user.data

  const userId = activeUser?._id
  const calendarTimeObj = new Date(selectedEmployee.date?.toISOString())
  const weeklyOf = activeUser?.weekly_of


  const [list, setList] = useState([])
  const [listLoading, setListLoading] = useState(false)

  const holidayList = holiday.data

  const [visitlist, setVisitList] = useState(null)

  const dispatch = useDispatch()

  const [filters, setFilters] = useState({
    userId: userId,
  })

  const fetchList = () => {
    setListLoading(true)
    setVisitList(null)

    const parmasToPass = { ...filters }


    dispatch(
      callApiAction(
        async () =>
          await getMonthlyVisits({
            ...parmasToPass,
            userId,
            date: calendarTimeObj.toISOString(),
          }),
        (response) => {
          setVisitList(response)
        },
        (err) => {
          dispatch(callSnackBar("Something went wrong!", SNACK_BAR_VARIETNS.error))
        },
      ),
    )
  }

  useEffect(() => {
    fetchList()
  }, [filters, userId, calendarTimeObj.valueOf()])

  useEffect(() => {
    if (visitlist) {
      let temp = []
      if (visitlist && Array.isArray(visitlist) && visitlist.length > 0) {
        visitlist.forEach((visit) => {
          if (
            visit.data &&
            Array.isArray(visit.data) &&
            visit.data.length > 0
          ) {
            visit.data.forEach((log, index) => {
              temp.push({
                title: log.name ?? "Visit-" + (index + 1),
                start: new Date(log.start_time),
                end: log.end_time ? new Date(log.end_time) : new Date(log.start_time),
                ...log
              })
            })
          }
        })
      }
      if (holidayList && Array.isArray(holidayList)) {
        holidayList.forEach((leave) => {
          temp.push({
            title: leave.name,
            start: new Date(leave.date),
            end: new Date(leave.date),
            relatedId: leave._id,
            type: CALENDAR_ITEM_TYPES.holiday,
            color: LEAVE_COLORS.HOLIDAY,
            onClick: () => { },
          })
        })
      }
      setList(temp)
      setListLoading(false)
    }

  }, [visitlist, holidayList])


  const setDate = (momentObj) => {
    dispatch(changeDate(momentObj))
  }
  return (
    <>
      <CustomMonthPicker date={selectedEmployee.date} setDate={setDate} />
     
      <Box mb={4} />
      <Calendar

        loading={listLoading}
        data={list}
        dayComponent={(props) => <CalendarBox forEmployee={forEmployee} weeklyOf={weeklyOf ?? [0]}  {...props} />}
        calendarTimeObj={calendarTimeObj}
      />
       <CenteredBox component={Paper} mt={2} p={3}>
        {Object.keys(VISIT_COLORS).map((visit_type) => <CenteredBox sx={{ display: "flex" }} ml={1} mr={1} >
          <Box sx={{ height: "20px", width: "20px", background: VISIT_COLORS[visit_type], opacity: 0.3 }} >

          </Box>
          <Typography ml={2} textTransform="capitalize">
            {(VISIT_TYPE_NAMES[visit_type]?.toLowerCase?.())} Visit
          </Typography>
        </CenteredBox>)}
      </CenteredBox>

    </>
  )
}
export default memo(VisitCalendarController)
