import {
  Box,
  Button,
  Checkbox,
  List,
  Collapse,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Slide,
  FormControl,
  Typography,
  TextField,
  CircularProgress,
  Autocomplete,
  ListItem,
  MenuItem,
} from "@mui/material";
import CustomRadioButtons from "../../components/layouts/common/CustomRadioButtons";
import CustomInput from "../../components/inputs/CustomInput";
import { CenteredBox } from "../../components/layouts/common/boxes";
import SubmitButton from "../../components/button/SubmitButton";
import { fetchFeedback } from "../../apis/feedbackForm.api";
import { fetchFeedbackType } from "../../apis/feedbackType.api";
import AsyncDropDown from "../../components/inputs/AsyncDropDown";

const AddFeedbackFormUI = ({
  loading,
  addFeedback,
  err,
  title,
  setTitle,
  description,
  setDescription,
  feedbacktype,
  feedback,
  setFeedbackType,
  priority,
  setPriority,
  setFeedbacktype,
  
}) => {
  return (
    <>
      <Box
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          overflowY: "auto",
          position: "relative",
        }}
        maxHeight="100%"
      >
        {loading && (
          <CenteredBox>
            <CircularProgress />
          </CenteredBox>
        )}
        {!loading && (
          <>
            <Box sx={{ marginTop: "10px", width: "100%" }}>
              <Box>
                <Typography fontWeight={500} variant="h4">
                  Title

                </Typography>
                <Typography fontWeight={500} variant="body1" color="error">
                  {err}
                </Typography>
              </Box>
              <Box sx={{ marginTop: "7px" }}>
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "column",
                  }}
                >
                  <Box sx={{ display: "flex", width: "100%" }}>
                    <CustomInput
                      disabled={loading}
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      type="text"
                      placeholder="Write title..."
                    />
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box sx={{ marginTop: "10px", width: "100%" }}>
              <Box>
                <Typography fontWeight={500} variant="h4">
                  Description
                </Typography>
              </Box>
              <Box sx={{ marginTop: "7px" }}>
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "column",
                  }}
                >
                  <Box sx={{ display: "flex", width: "100%" }}>
                    <CustomInput
                      disabled={loading}
                      value={description}
                      multiline
                      rows={4}
                      onChange={(e) => setDescription(e.target.value)}
                      type="text"
                      placeholder="Write Description..."
                    />
                  </Box>
                </Box>
              </Box>
            </Box>

            <Grid>
              <Box sx={{ marginTop: "10px", width: "100%" }}>
                <Box>
                  <Typography fontWeight={500} variant="h4">
                    Feedback Type
                  </Typography>
                </Box>
                <Box sx={{ marginTop: "7px" }}>
                  <AsyncDropDown
                    // label="Select FeedbackType*"
                    lazyFun={async (params) => {
                      return await fetchFeedbackType({ ...params });
                    }}
                    value={feedbacktype}
                    OptionComponent={({ option, ...rest }) => {
                      return (
                        <>
                          <MenuItem {...rest}>{option.name}</MenuItem>
                        </>
                      );
                    }}
                    onChange={async (changedVal) => {
                      setFeedbacktype(
                       changedVal?._id,
                      );
                    }}
                    titleKey={"name"}
                    valueKey={"_id"}
                  />
                </Box>
              </Box>
            </Grid>


          </>
        )}

        <DialogActions>
          <Box sx={{ float: "right", marginTop: "7px" }}>
            <SubmitButton
              loading={loading}
              type="submit"
              variant="contained"
              color="primary"
              onClick={addFeedback}
              title="Add Feedback"
            ></SubmitButton>
          </Box>
        </DialogActions>
      </Box>
    </>
  );
};

export default AddFeedbackFormUI;
