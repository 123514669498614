import { memo, useContext, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { callApiAction } from '../../../store/actions/commonAction'
import { SNACK_BAR_VARIETNS } from '../../../utils/constants'
import { getAllDailyUpdates } from '../../../apis/dailyupdate.api'
import { EmployeeTaskUserContext } from '../EmployeeMain'
import { callSnackBar } from '../../../store/actions/snackbarAction'
import DailyUpdateReportUi from './DailyUpdateReportUi'

const DailyUpdateReportController = ({ }) => {

    
    const dispatch = useDispatch()

    const params = useParams()

    const [loading, setLoading] = useState(false)
    const selectedUser = useContext(EmployeeTaskUserContext)
    const [filters, setFilters] = useState({
        err: '',
        pageNo: 1,
        pageSize: 25,
        date: params.time ? moment(parseInt(params.time)) : moment(),
        data: true
    })
    const [list, setList] = useState([])



    const fetchList = async (e) => {
        setLoading(true)
        dispatch(
            callApiAction(
                async () => await getAllDailyUpdates({ ...filters, date: filters.date.toISOString(), userId: selectedUser ? selectedUser._id : null }),
                async (response) => {
                    setLoading(false)

                    const totalDaysInMonth = filters.date.daysInMonth()
                    let finalList = []

                    if (response && Array.isArray(response)) {
                        for (let i = 0; i < totalDaysInMonth; i++) {
                            finalList.push([])
                        }
                        for (let item of response) {
                            const itemDate = moment(item.date).get("date")
                            const arr = finalList[itemDate - 1].concat(item.items)
                            finalList[itemDate - 1] = arr
                        }
                    }

                    setList(finalList)
                },
                (err) => {
                    setLoading(false)
                    setList({})
                    dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error))
                },
            ),
        )

    }


    useEffect(() => {
        fetchList()
    }, [filters, selectedUser])

    return (
        <DailyUpdateReportUi
            list={list}

            loading={loading}
            filters={filters}
            setFilters={setFilters}
        />
    )
}
export default memo(DailyUpdateReportController)
