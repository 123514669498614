import { mode } from "../apis/endpoints";

export const COMPANY_NAME = 'WEHEAR Innovation Pvt. Ltd.'

export const companyDetails = {
  NAME: 'Wehear',
  EMAIL: 'info@wehear.in',
  ADDRESS: '805 , Supath ,Vijay Cross Roads , Navrangpura , Ahmedabad',
  GST: '24AACCW8915D1ZE',
  bankName: 'Kotak -9090960606',
  bankIFSC: 'KKBK0002588',
  bankNo: '9090960606',
  companyName: COMPANY_NAME,
}
export const USER_ROLES = {
  admin: 0,
  hr: 1,
  destributor:3,
  employee: 4
};
export const USER_ROLES_NUMBER = {
  0: "admin",
  1: "hr",
  3:"Destributor",
  4: "employee",

};
export const USER_ROLES_NAME = {
  admin: 'Admin',
  destributor:"Destributor",
  hr: 'Hr',
  employee: 'Employee',
}
export const REIMBURSEMENT_STATUS = {
  pending: 'pending',
  rejected: 'rejected',
  approved: 'approved',
  paid: 'paid'
}
export const REIMBURSEMENT_ADVANCE_PAYMENT_STATUS = {
  OPEN: 1,
  CLOSE: 2
};
export const REIMBURSEMENT_TYPE = {
  fuel: 'fuel',
  food: 'food',
  other: 'other',
  travel: 'travel',
}

export const FOOD_TYPES = {
  SNACKS: 'SNACKS',
  LUNCH: 'LUNCH',
  DINNER: 'DINNER',
}
export const REIMBURSEMENT_TRAVEL_TYPE = {
  OLA_UBER: 'Ola-Uber',
  BUS: 'bus',
  TRAIN: 'train',
  AUTO: 'auto',
  AIR: 'air',
}
export const REIMBURSEMENT_FUEL_TYPE = [
  {
    label: '2 wheeler',
    value: 'two_wheel_amount_per_km',
  },
  {
    label: '4 wheeler',
    value:'four_wheel_amount_per_km',
  },
]
export const REIMBURSEMENT_FUEL_TYPE_AMOUNT = {
  TWO_WHEELER: 4,
  FOUR_WHEELER: 8
}
export const FUEL_PRICE_PER_KM = 4

// export const LEAVE_TYPES = {
//   CL: 'Casual Leave',
//   SL: 'Sick Leave',
//   PL: 'Paid Leave',
//   COFF: 'Compensatory  Leave',
// }

const LEAVE_STATUS = {
  PENDING: 0,
  APPROVED: 1,
  REJECTED: -1
}
export const HALF_TYPE_OBJ = {
  FIRST_HALF: 1,
  SECOND_HALF: 2,
  FULL_DAY: 3,

}
export const HALF_TYPE = [
  {
    value: 3,
    label: 'Full day',
    deduct: 0,
    add: 1,
  },
  {
    value: 1,
    label: '1st Half',
    deduct: 0.5,
    add: 0.5,
  },
  {
    value: 2,
    label: '2nd Half',
    deduct: 0.5,
    add: 0.5,
  },
]
export const LEAVE_RESPONSE = {
  accepted: 'Accepted',
  rejected: 'Rejected',
  pending: 'Under-Review',
}
export const CALENDAR_ITEM_TYPES = {
  coff: 'COFF',
  leave: 'LEAVE',
  wfh: 'WFH',
  task: 'TASK',
  daily_updates: 'DAILY_UPDATES',
  holiday: 'HOLIDAY',
  checkInLog: 'checkInLog',
  checkOUTLog: 'checkOutLog',
  breakInLog: 'breakInLog',
  breakOUTLog: 'breakOutLog',
}
export const LOGS_NAMES = {
  'checkInLog': 'check In',
  'checkOutLog': 'check Out',
  'breakInLog': 'break In',
  'breakOutLog': 'break Out',
  'periodicLog': "Periodic Log"
}
export const APP_TYPES = {
  WEB: 1,
  APP: 2
}
export const LEAVE_COLORS = {
  COFF_RAISE_APPROVED: 'green',
  HOLIDAY: '#D58C37',
  AUTO_APPROVED: 'rgba(94, 130, 51, 1)',
  COFF_RAISE_REJECTED: 'red',


  'COFF_RAISE_PENDING': 'gray',
}

export const WEEKLY_OFF = [{ label: "Sun", value: 0 }, { label: "Mon", value: 1 }, { label: "Tue", value: 2 }, { label: "Wed", value: 3 }, { label: "Thu", value: 4 }, { label: "Fri", value: 5 }, { label: "Sat", value: 6 }]
export const NOTIFICATION_TYPE = {
  general: 'general',
  leave: 'leave',
  reimbursement: 'reimbursement',
  coff: 'coff',
  task: 'task'
}

export const actions = {
  SIGN_IN: 'SIGN_IN',
  SET_USER: 'SET_USER',
  UPDATE_USER_DATA: 'UPDATE_USER_DATA',
  SET_USER_LAST_READ_NOTIFICATION: 'SET_USER_LAST_READ_NOTIFICATION',
  SIGN_OUT: 'SIGN_OUT',
  OPEN_MODAL: 'OPEN_MODAL',
  CLOSE_MODAL: 'CLOSE_MODAL',

  SET_LIFE_STOCK_DATA: 'SET_LIFE_STOCK_DATA',
  START_LIFE_STOCK_LOADING: 'START_LIFE_STOCK_LOADING',

  SET_LEAVE_BALANCE_COUNT_DATA: 'SET_LEAVE_BALANCE_COUNT_DATA',
  START_LEAVE_BALANCE_COUNT_LOADING: 'START_LEAVE_BALANCE_COUNT_LOADING',

  SET_CALENDAR_DATA : 'SET_CALENDAR_DATA',
  SET_LEAVE_LIST_DATA : 'SET_LEAVE_LIST_DATA',
  SET_LOGS_LIST_DATA : 'SET_LOGS_LIST_DATA',
  SET_COFF_LIST_DATA : 'SET_COFF_LIST_DATA',
  SET_WFH_LIST_DATA : 'SET_WFH_LIST_DATA',
  START_CALENDAR_LOADING : 'START_CALENDAR_LOADING',
  STOP_CALENDAR_LOADING : 'STOP_CALENDAR_LOADING',

  SET_PENDING_LEAVES_DATA : 'SET_PENDING_LEAVES_DATA',
  START_PENDING_LEAVES_LOADING : 'START_PENDING_LEAVES_LOADING',

  SET_COMPOFF_DATA : 'SET_COMPOFF_DATA',
  START_COMPOFF_LOADING : 'START_COMPOFF_LOADING',

  SET_WFH_DATA : 'SET_WFH_DATA',
  START_WFH_LOADING : 'START_WFH_LOADING',

  SET_REIMBURSEMENT_ADVANCE_PAYMENT_DATA: 'SET_REIMBURSEMENT_ADVANCE_PAYMENT_DATA',
  START_REIMBURSEMENT_ADVANCE_PAYMENT_LOADING: 'START_REIMBURSEMENT_ADVANCE_PAYMENT_LOADING',

  SET_REIMBURSEMENT_PENDING_DATA : 'SET_REIMBURSEMENT_PENDING_DATA',
  START_REIMBURSEMENT_PENDING_LOADING :'START_REIMBURSEMENT_PENDING_LOADING',

  SET_REIMBURSEMENT_COUNT_DATA : 'SET_REIMBURSEMENT_COUNT_DATA',
  START_REIMBURSEMENT_COUNT_LOADING : 'START_REIMBURSEMENT_COUNT_LOADING',

  SET_REIMBURSEMENT_PERSONAL_DATA : 'SET_REIMBURSEMENT_PERSONAL_DATA',
  START_REIMBURSEMENT_PERSONAL_LOADING : 'START_REIMBURSEMENT_PERSONAL_LOADING',

  SET_TEAM_DATA : 'SET_TEAM_DATA',
  START_TEAM_LOADING : 'START_TEAM_LOADING',

  SET_HOLIDAY_DATA : 'SET_HOLIDAY_DATA',
  SET_HOLIDAY_DATA_ONDELETE : 'SET_HOLIDAY_DATA_ONDELETE',
  START_HOLIDAY_LOADING  : 'START_HOLIDAY_LOADING',

  SET_DOCUMENT_DATA : 'SET_DOCUMENT_DATA',
  SET_DOCUMENT_DATA_ONDELETE : 'SET_DOCUMENT_DATA_ONDELETE',
  START_DOCUMENT_LOADING : 'START_DOCUMENT_LOADING',

  SET_BRANCH_DATA : 'SET_BRANCH_DATA',
  SET_BRANCH_DATA_ONDELETE : 'SET_BRANCH_DATA_ONDELETE',
  START_BRANCH_LOADING : 'START_BRANCH_LOADING',

  SET_DEPARTMENT_DATA : 'SET_DEPARTMENT_DATA',
  SET_DEPARTMENT_DATA_ONDELETE : 'SET_DEPARTMENT_DATA_ONDELETE',
  START_DEPARTMENT_LOADING : 'START_DEPARTMENT_LOADING',

  SET_EMPLOYEE_TYPE_DATA : 'SET_EMPLOYEE_TYPE_DATA',
  SET_EMPLOYEE_TYPE_DATA_ONDELETE : 'SET_EMPLOYEE_TYPE_DATA_ONDELETE',
  START_EMPLOYEE_TYPE_LOADING : 'START_EMPLOYEE_TYPE_LOADING',

  SET_VERSION_DATA : 'SET_VERSION_DATA',
  SET_VERSION_DATA_ONDELETE : 'SET_VERSION_DATA_ONDELETE',
  START_VERSION_LOADING : 'START_VERSION_LOADING',

  SET_USERS_DATA : 'SET_USERS_DATA',
  START_USERS_LOADING : 'START_USERS_LOADING',

  SET_INTERVIEW_DATA : 'SET_INTERVIEW_DATA',
  SET_INTERVIEW_DATA_ONUPDATE : 'SET_INTERVIEW_DATA_ONUPDATE',
  START_INTERVIEW_LOADING : 'START_INTERVIEW_LOADING',

  SET_RESIGNATION_DATA : 'SET_RESIGNATION_DATA',
  SET_RESIGNATION_DATA_ONUPDATE : 'SET_RESIGNATION_DATA_ONUPDATE',
  START_RESIGNATION_LOADING : 'START_RESIGNATION_LOADING',

  SET_HOLIDAY_COUNT_DATA: 'SET_HOLIDAY_COUNT_DATA',
  START_HOLIDAY_COUNT_LOADING: 'START_HOLIDAY_COUNT_LOADING',

  SET_LEAVE_TYPE_COUNT_DATA: 'SET_LEAVE_TYPE_COUNT_DATA',
  START_LEAVE_TYPE_COUNT_LOADING: 'START_LEAVE_TYPE_COUNT_LOADING',

  SET_NOTIFICATION_DATA: 'SET_NOTIFICATION_DATA',
  NOTIFICATION_READ: 'NOTIFICATION_READ',

  SET_DEVICES_LIST: 'SET_DEVICES_LIST',
  UPDATE_DEVICES_LIST: 'UPDATE_DEVICES_LIST',
  CALL_SNACKBAR_DATA: "CALL_SNACKBAR_DATA",
  SELECT_USER:"SELECT_USER",
  CHANGE_DATE:"CHANGE_DATE",


  SET_USER_LAST_READ_ANNOUNCEMENT: 'SET_USER_LAST_READ_ANNOUNCEMENT',
  SET_ANNOUNCEMENT_DATA: 'SET_ANNOUNCEMENT_DATA',
  ANNOUNCEMENT_READ: 'ANNOUNCEMENT_READ',

  SET_BIRTHDAY_DATA: 'SET_BIRTHDAY_DATA',

  
  SET_LEAD_TYPE_DATA : 'SET_LEAD_TYPE_DATA',
  SET_LEAD_TYPE_DATA_ONDELETE : 'SET_LEAD_TYPE_DATA_ONDELETE',
  START_LEAD_TYPE_LOADING : 'START_LEAD_TYPE_LOADING',

}
export const USER_UPDATE_TYPE = {
  personalDetails: 'presonalDetails',
  companyDetails: 'companyDetails',
  bankDetails: 'bankDetails',
  salaryDetails: 'salaryDetails',
  taxesDetails: 'taxesDetails',
  documents: 'documents',
}

export const SNACK_BAR_VARIETNS = {
  error: "error",
  suceess: "success",
  warning: "warning"
}
export const REQUESTS_STATUS = {
  PENDING: 1,
  APPROVED: 2,
  REJECTED: 3
}
export const REQUESTS_TYPE = {
  WFH: 1,
}

export const CHECK_IN = {
  LATE_CHECKIN : 1,
  LEAVE_DEDUCT : 2,
  LATE_CHECKIN_IGNORED : 3,
}
