import {
  Box,
  DialogActions,
  Typography
} from "@mui/material";
import CustomInput from "../../components/inputs/CustomInput";

import SubmitButton from "../../components/button/SubmitButton";


const AddAdvancePaymentsUI = ({
  loading,
  onSubmit,
  fields,
  setFields,
}) => {
  return (
    <>
      <Box
        onSubmit={onSubmit}
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          overflowY: "auto",
          position: "relative",
        }}
        maxHeight="100%"
      >
        {
          fields.err && <Typography color="error" variant="h6">
            {fields.err}
          </Typography>
        }
        {!loading && (
          <>

            <CustomInput

              disabled={loading}
              value={fields.amount}
              onChange={(e) => setFields({ ...fields, amount: e.target.value })}
              type="number"
              label="Amount*"
            />

            <CustomInput
              multiline={true}
              rows={4}
              disabled={loading}
              value={fields.remarks}
              onChange={(e) => setFields({ ...fields, remarks: e.target.value })}
              type="text"
              label="Remarks*"
            />




          </>
        )}

        <DialogActions>
          <Box sx={{ float: "right", marginTop: "7px" }}>
            <SubmitButton
              loading={loading}
              type="submit"
              variant="contained"
              color="primary"
              title="Add"
            >

            </SubmitButton>
          </Box>
        </DialogActions>
      </Box >
    </>
  );
};

export default AddAdvancePaymentsUI;
