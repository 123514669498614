import { useCallback, useEffect, useMemo, useState } from "react";
import UserPageUi from "./UserPageUI";
import { useDispatch } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";
import { getUserApi, updateUserField } from "../../apis/user.api";
import { useNavigate, useParams } from "react-router-dom";
import { openModal } from "../../store/actions/modalAction";
import UserCreateController from "./UserCreateController";
import { useSelector } from "react-redux";
import { getUserByIdApi } from "../../apis/user.api";
import { updateUserState } from "../../apis/user.api";
import fileDownload from "js-file-download";
import UserViewMoreButton from "./components/UserViewMoreButton";
import { Box } from "@mui/material";
import UserUpdateButton from "./components/UserUpdateButton";
import { USER_ROLES } from "../../utils/constants";
import UpdateActiveUserButton from "./components/UpdateActiveUserButton";
import UpdateInOfficeButton from "./components/UpdateInOfficeButton";
import { toTitleCase } from "../../utils/helper";
import DownloadUserProfilePdfButton from "./components/DownloadUserProfilePdfButton";
import { fetchUsersDataAction } from "../../store/actions/usersAction";
import UpdateHiddenUserButton from "./components/UpdateHiddenUserButton";

const UserController = () => {
  const user = useSelector((state) => state.user);
  const [list, setList] = useState({});
  const [loading, setLoading] = useState(false);
  const [exportLoading, setexportLoading] = useState(false);

  const [listLoading, setListLoading] = useState(false);
  const { users } = useSelector((state) => state);

  const dispatch = useDispatch();
  const params = useParams();

  const columns = useMemo(
    () => [
      {
        id: 1,
        fieldName: "employee_id",
        label: "Employee Id",
        align: "left",
        sort: true,
        minWidth: "150px",
      },
      {
        id: 2,
        fieldName: "first_name",
        label: "Name",
        align: "left",
        sort: true,
        renderValue: (params, setParams) => params.name,
      },
      {
        id: 3,
        fieldName: "designation",
        label: "Designation",
        align: "left",
        sort: true,
        renderValue: (params, setParams) => toTitleCase(params.designation),
      },
      { id: 4, fieldName: "email", label: "Email", align: "left" },
      { id: 5, fieldName: "phone", label: "Phone", align: "left" },
      {
        id: 6,
        fieldName: "parent_id",
        label: "Reporting Person",
        align: "left",
        minWidth: "150px",
        renderValue: (params, setParams) => params.first_parent?.name ?? "NA",
      },
      {
        id: 8,
        fieldName: "",
        label: "In Office",
        align: "right",
        minWidth: "100px",
        hide: user.data.role == USER_ROLES.employee,
        renderValue: (params, setParams) => (
          <UpdateInOfficeButton params={params} />
        ),
      },
      {
        id: 8,
        fieldName: "",
        label: "Active User",
        align: "right",
        minWidth: "120px",
        hide: user.data.role == USER_ROLES.employee,
        renderValue: (params, setParams) => (
          <UpdateActiveUserButton params={params} />
        ),
      },
      {
        id: 9,
        fieldName: "",
        label: "Hidden User",
        align: "right",
        minWidth: "120px",
        hide: user.data.role == USER_ROLES.employee,
        renderValue: (params, setParams) => (
          <UpdateHiddenUserButton params={params} />
        ),
      },
      {
        id: 10,
        fieldName: "",
        label: "Action",
        align: "right",
        minWidth: "150px",
        //hide: user.data.role == USER_ROLES.employee,
        renderValue: (params, setParams) => (
          <>
            <Box sx={{ width: "100%", display: "flex" }}>
              <UserViewMoreButton id={params._id} />
              {user.data.role != USER_ROLES.employee && (
                <UserUpdateButton params={params} setParams={setParams} />
              )}
              <DownloadUserProfilePdfButton params={params} />
            </Box>
          </>
        ),
      },
    ],
    [dispatch]
  );
  const [filters, setFilters] = useState({
    pageNo: 1,
    pageSize: 10,
    search: "",
    role: null,
    sort: "first_name",
    sortDirection: 1,
    searchable: ["name", "email"],
    enabled:null,
  });

  const fetchList = (isExport) => {
    if (isExport) {
      setexportLoading(true);
      dispatch(
        callApiAction(
          async () => await getUserApi({ ...filters, csv: true }),
          (response) => {
            fileDownload(response, "users.csv");
            setexportLoading(false);
          },
          (err) => {
            console.log(err);
            setexportLoading(false);
          },
          true
        )
      );
    }
  };

  const getUsersList = () => {
    if (
      !users.users_data ||
      users.users_data.length === 0 ||
      JSON.stringify(filters) != JSON.stringify(users.users_filters)
    ) {
      dispatch(fetchUsersDataAction(filters));
    }
  };

  const navigate = useNavigate();
  const onCreateBtnClick = () => {
    navigate("create");
  };
  useEffect(() => {
    getUsersList();
    // fetchList()
  }, [filters]);

  return (
    <>
      <UserPageUi
        columns={columns}
        exportLoading={exportLoading}
        onCreateBtnClick={onCreateBtnClick}
        listLoading={users.users_loading}
        filters={filters}
        setFilters={setFilters}
        loading={loading}
        list={users.users_data}
        fetchList={fetchList}
      />
    </>
  );
};
export default UserController;
