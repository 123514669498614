import React, { useEffect } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Box,
  FormHelperText,
  Chip,
  Stack,
  TextField,
  Autocomplete,
  Grid,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import SubmitButton from "../../components/button/SubmitButton";
import { StyledSearchBar } from "../../components/inputs/SearchBar";
import CustomInput from "../../components/inputs/CustomInput";
import { UserSearchBarNormal } from "../../components/inputs/SearchBar";
import { closeModal } from "../../store/actions/modalAction";
import { SNACK_BAR_VARIETNS, USER_ROLES, USER_ROLES_NAME, USER_UPDATE_TYPE, WEEKLY_OFF } from "../../utils/constants";
import moment, { weekdays } from "moment";
import { current } from "@reduxjs/toolkit";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { DesktopDatePicker, MobileDatePicker, TimePicker } from "@mui/x-date-pickers";
import FileInput from "../../components/inputs/FileInput";
import AsyncDropDown from "../../components/inputs/AsyncDropDown";
import { fetchBranches } from "../../apis/branch.api";
import { fetchEmployeeTypes } from "../../apis/employeeType.api";
import { fetchDepartments } from "../../apis/department";
import { toTitleCase, validateEmail, validatePAN, validatePassword } from "../../utils/helper";
import COUNTRY_CODES from "../../utils/CountryCode";
import CountryStateCityDropDown from "./CountryStateCity";
import SalaryDetails from "./SalaryDetails";
import { callSnackBar } from "../../store/actions/snackbarAction";

export const PersonalDetailsSection = ({
  loading,
  onSubmit,
  isUpdate,
  errors,
  formValues,
  setFormValues,
  getErrorMessage,
  onPanChange,
  onAadharChange,
  onProfilePhototChange,
  defaultFormValues
}) => {

  return <Stack spacing={2} sx={{ mb: 2 }}>
    <Grid container rowGap={3} columnSpacing={3}>
      <Grid xs={12} md={4} item>
        <Box>
          <CustomInput
            disabled={loading}
            type="text"
            validate={() => {
              return (formValues.first_name && formValues.first_name != "") ? true : "First name is required."
            }}
            label="First Name*"
            value={formValues.first_name}
            onChange={(e) =>
              setFormValues({
                ...formValues,
                err: "",
                first_name: e.target.value,
              })
            }

            sx={{ backgroundColor: "background.paper" }}
          />
        </Box>
      </Grid>
      <Grid xs={12} md={4} item>
        <Box>
          <CustomInput
            disabled={loading}
            type="text"
            label="Middle Name"
            value={formValues.middle_name}
            onChange={(e) =>
              setFormValues({
                ...formValues,
                err: "",
                middle_name: e.target.value,
              })
            }

            sx={{ backgroundColor: "background.paper" }}
          />
        </Box>
      </Grid>
      <Grid xs={12} md={4} item>
        <Box>
          <CustomInput
            disabled={loading}
            type="text"
            validate={() => {
              return (formValues.last_name && formValues.last_name != "") ? true : "Last name is required."
            }}
            label="Last Name*"
            value={formValues.last_name}
            onChange={(e) =>
              setFormValues({
                ...formValues,
                err: "",
                last_name: e.target.value,
              })
            }

            sx={{ backgroundColor: "background.paper" }}
          />
        </Box>
      </Grid>
      <Grid xs={12} md={6} item>
        <Box>
          {!isUpdate && <CustomInput
            disabled={loading}
            label="Email*"
            type="email"
            name="email"
            validate={() => {
              if (!formValues.email || formValues.email == "") {
                return "Email is required."
              } else if (!validateEmail(formValues.email)) {
                return "Invalid Email."
              }
              return true
            }}
            value={formValues.email}
            onChange={(e) =>
              setFormValues({
                ...formValues,
                err: "",
                email: e.target.value,
              })
            }
            sx={{ backgroundColor: "background.paper" }}
          />}
        </Box>
      </Grid>
      <Grid xs={12} md={6} item>
        {!isUpdate && <Box>
          <CustomInput
            disabled={loading}
            label="Password*"
            type="password"
            validate={() => {
              if (!formValues.password || formValues.password == "") {
                return "Password is required."
              } else if (!validatePassword(formValues.password)) {
                return "Password must be atlist 8 character long and must contain atlist one special character,one digit & One capital letter."
              }
              return true
            }}
            value={formValues.password}
            onChange={(e) =>
              setFormValues({
                ...formValues,
                err: "",
                password: e.target.value,
              })
            }
            sx={{ backgroundColor: "background.paper" }}
          />
        </Box>}
      </Grid>
      <Grid xs={12} md={6} item>
        <Box>
          <CustomInput
            InputProps={{
              startAdornment:
                <InputAdornment position="start">
                  <Select
                    disableUnderline
                    value={formValues.phone_country_code}
                    onChange={(e) =>
                      setFormValues({
                        ...formValues,
                        err: "",
                        phone_country_code: e.target.value,
                      })
                    }

                    variant="standard"
                    size="small"
                  >
                    {
                      COUNTRY_CODES.map((item) => <MenuItem key={item.code} value={item.dial_code} >{item.code}({item.dial_code})</MenuItem>)
                    }
                  </Select>
                </InputAdornment>
            }
            }
            disabled={loading}
            label="Phone*"
            type="number"
            name="phone"
            validate={() => {
              if (!formValues.phone || formValues.phone == "") {
                return "Phone is required."
              }
              return true
            }}
            value={formValues.phone}
            onChange={(e) =>
              setFormValues({
                ...formValues,
                err: "",
                phone: e.target.value,
              })
            }
          />
        </Box>
      </Grid>
      <Grid xs={12} md={6} item>
        <Box>
          <CustomInput
            InputProps={{
              startAdornment:
                <InputAdornment position="start">
                  <Select
                    disableUnderline
                    value={formValues.phone_emergency_country_code}
                    onChange={(e) =>
                      setFormValues({
                        ...formValues,
                        err: "",
                        phone_emergency_country_code: e.target.value,
                      })
                    }
                    variant="standard"
                    size="small"
                  >
                    {
                      COUNTRY_CODES.map((item) => <MenuItem key={item.code} value={item.dial_code} >{item.code}({item.dial_code})</MenuItem>)
                    }
                  </Select>
                </InputAdornment>
            }
            }
            disabled={loading}
            label="Emergency Phone*"
            type="number"
            name="phone_emergency"
            value={formValues.phone_emergency}
            validate={() => {
              if (!formValues.phone_emergency || formValues.phone_emergency == "") {
                return "Emergency Phone is required."
              }
              return true
            }}
            onChange={(e) =>
              setFormValues({
                ...formValues,
                err: "",
                phone_emergency: e.target.value,
              })
            }
            sx={{ backgroundColor: "background.paper" }}
          />
        </Box>
      </Grid>

      <Grid xs={12} md={6} item>
        <Box>

          <MobileDatePicker
            disabled={loading}
            id="dob"
            name="dob"
            inputFormat="DD MMM,YYYY"
            value={moment(formValues.dob)}
            onChange={(e) =>
              setFormValues({
                ...formValues,
                err: "",
                dob: e.toISOString(),
              })
            }
            type="date"
            label={"Date Of Birth*"}
            renderInput={(params) => (
              <CustomInput
                {...params}
                sx={{ width: '100%' }}
              />
            )}
          />
        </Box>
      </Grid>
      <Grid xs={12} md={6} item>
        <Box>
          <FormControl fullWidth margin="dense">
            <InputLabel>Blood Group*</InputLabel>
            <Select
              label={"Blood Group*"}
              name="blood_group"
              value={formValues.blood_group || ""}
              onChange={(e) =>
                setFormValues({
                  ...formValues,
                  err: "",
                  blood_group: e.target.value,
                })
              }

              sx={{ backgroundColor: "background.paper" }}
            >
              <MenuItem value="A+">A+</MenuItem>
              <MenuItem value="A-">A-</MenuItem>
              <MenuItem value="B+">B+</MenuItem>
              <MenuItem value="B-">B-</MenuItem>
              <MenuItem value="O+">O+</MenuItem>
              <MenuItem value="O-">O-</MenuItem>
              <MenuItem value="AB+">AB+</MenuItem>
              <MenuItem value="AB-">AB-</MenuItem>
              <MenuItem value="other">Other</MenuItem>
            </Select>
            <FormHelperText>

            </FormHelperText>
          </FormControl>
        </Box>
      </Grid>


      <Grid xs={12} item>
        <Box>
          <CustomInput
            rows={2}
            multiline
            disabled={loading}
            name="medical_remarks"
            validate={() => {
              if (!formValues.medical_remarks || formValues.medical_remarks == "") {
                return "Medical remarks is required."
              }
              return true
            }}
            value={formValues.medical_remarks}
            onChange={(e) =>
              setFormValues({
                ...formValues,
                err: "",
                medical_remarks: e.target.value,
              })
            }
            type="text"
            label={"Medical Remarks*"}
          />
        </Box>
      </Grid>
      <Grid xs={12} item>
        <Box>
          <CustomInput
            rows={2}
            multiline
            disabled={loading}
            name="address_line1"
            value={formValues.address_line1}
            onChange={(e) =>
              setFormValues({
                ...formValues,
                err: "",
                address_line1: e.target.value,
              })
            }
            validate={() => {
              if (!formValues.address_line1 || formValues.address_line1 == "") {
                return "Address Line 1 is required."
              }
              return true
            }}
            type="text"
            label={"Address Line 1*"}
          />
        </Box>
      </Grid>
      <Grid xs={12} item>
        <Box>
          <CustomInput
            rows={2}
            multiline
            disabled={loading}
            name="address_line2"
            value={formValues.address_line2}
            onChange={(e) =>
              setFormValues({
                ...formValues,
                err: "",
                address_line2: e.target.value,
              })
            }
            type="text"
            label={"Address Line 2"}
          />
        </Box>
      </Grid>

      <CountryStateCityDropDown
        country={formValues.country}
        state={formValues.state}
        city={formValues.city}
        onChange={(country, state, city) => {
          setFormValues({ ...formValues, country, state, city })
        }}
      />
      

      <Grid xs={12} item>
        <Box>
          <CustomInput
            validate={() => {
              if (!formValues.zip_code || formValues.zip_code == "") {
                return "ZIP Code is required."
              }
              return true
            }}
            disabled={loading}
            name="zip_code"
            value={formValues.zip_code}
            onChange={(e) =>
              setFormValues({
                ...formValues,
                err: "",
                zip_code: e.target.value,
              })
            }
            type="number"
            label={"ZIP Code*"}
          />
        </Box>
      </Grid>

      <Grid xs={12} md={6} item>
        <Box>
          <CustomInput
            fullWidth
            disabled={loading}
            name="pan_no"
            value={formValues.pan_no}
            onChange={(e) =>
              setFormValues({
                ...formValues,
                err: "",
                pan_no: e.target.value,
              })
            }
            validate={() => {
              if (!formValues.pan_no || formValues.pan_no == "") {
                return "PAN number is required."
              }
              if (!validatePAN(formValues.pan_no)) {
                return "Invalid PAN number."
              }
              return true
            }}
            type="text"
            label={"PAN No.*"}

          />
        </Box>
      </Grid>
      <Grid xs={12} md={6} item>
        <Box>
          <FileInput
            onChange={(files) => {
              onPanChange(files)
            }}
            defaults={formValues.pan_url ? [formValues.pan_url] : []}
            accept="image/*,.pdf"
            title="Upload PAN Card*"
            subTitle="Only png,jpeg,jpg,pdf files are allowed! less than 1 MB"
          />
        </Box>
      </Grid>
      <Grid xs={12} md={6} item>
        <Box>
          <CustomInput
            disabled={loading}
            name="aadhar_no"
            value={formValues.aadhar_no}
            onChange={(e) =>
              setFormValues({
                ...formValues,
                err: "",
                aadhar_no: e.target.value,
              })
            }
            validate={() => {
              if (!formValues.aadhar_no || formValues.aadhar_no == "") {
                return "Aadhar number is required."
              } else if (formValues.aadhar_no.length != 12) {
                return "Aadhar no is invalid."
              }
              return true
            }}
            type="number"
            label={"Aadhar No.*"}

          />
        </Box>
      </Grid>
      <Grid xs={12} md={6} item>
        <Box>
          <FileInput
            onChange={(files) => {
              onAadharChange(files)
            }}
            defaults={formValues.addhar_url ? [formValues.addhar_url] : []}
            accept="image/*,.pdf"
            title="Upload Aadhar*"
            subTitle="Only png,jpeg,jpg,pdf files are allowed! less than 1 MB"
          />
        </Box>
      </Grid>

      <Grid xs={12} md={12} item>
        <Box>
          <FileInput
            onChange={(files) => {
              onProfilePhototChange(files)
            }}
            defaults={formValues.profile_url ? [formValues.profile_url] : []}
            accept="image/*"
            inputProps={{ accept: "image/*" }}
            title="Upload Profile Pic*"
            onlyImage
            subTitle="Only png,jpeg,jpg files are allowed! less than 1 MB"
          />
        </Box>
      </Grid>
    </Grid>
  </Stack>
}

export const CompanyDetailsSection = ({
  loading,
  onSubmit,
  errors,
  formValues,
  setFormValues,
  getErrorMessage,

}) => {
  return <Stack spacing={2} sx={{ mb: 2 }}>
    <Grid container rowGap={3} columnSpacing={3}>
      <Grid xs={12} md={6} item>
        <Box>
          <AsyncDropDown
            defaultVal={formValues.branch?{_id: formValues.branch,name: formValues.branch_name}:null}
            InputComponent={(props) => <CustomInput label="Branch*" placeholder="Select Branch"  {...props} />}
            lazyFun={async (props) => {
              return await fetchBranches({ ...props })
            }}
            label="Branch*"
            onChange={async (changedVal) => {
              setFormValues({
                ...formValues,
                err: "",
                branch: changedVal._id,
              })
            }}
            titleKey={'name'}
            valueKey={"_id"}
            OptionComponent={({ option, ...rest }) => {
              return <Box sx={{ width: "100%", display: "flex", alignItems: "center" }} {...rest}>
                <Typography ml={3} variant="h5">{option.name}</Typography>
              </Box>
            }}

          />
        </Box>
      </Grid>


      <Grid xs={12} md={6} item>
        <Box>
          <AsyncDropDown
          defaultVal={formValues.employee_type?{_id: formValues.employee_type,name: formValues.employee_type_name}:null}
            InputComponent={(props) => <CustomInput label="Employee Type*" placeholder="Select Employee Type"  {...props} />}
            lazyFun={async (props) => {
              return await fetchEmployeeTypes({ ...props })
            }}
            label="Employee Type*"
            onChange={async (changedVal) => {
              setFormValues({
                ...formValues,
                err: "",
                employee_type: changedVal._id,
              })
            }}
            titleKey={'name'}
            valueKey={"_id"}
            OptionComponent={({ option, ...rest }) => {
              return <Box sx={{ width: "100%", display: "flex", alignItems: "center" }} {...rest}>
                <Typography ml={3} variant="h5">{option.name}</Typography>
              </Box>
            }}

          />
        </Box>
      </Grid>

      <Grid xs={12} md={6} item>
        <Box>
          <FormControl margin="dense" fullWidth >
            <InputLabel>Role*</InputLabel>
            <Select
              label="Role*"
              name="role"
              value={formValues.role || ""}
              onChange={(e) =>
                setFormValues({
                  ...formValues,
                  err: "",
                  role: e.target.value,
                })
              }

            >
              {Object.keys(USER_ROLES_NAME).map((key) => {
                return <MenuItem value={USER_ROLES[key]}>{USER_ROLES_NAME[key]}</MenuItem>;
              })}
            </Select>
            <FormHelperText>{getErrorMessage("role")}</FormHelperText>
          </FormControl>
        </Box>
      </Grid>


      <Grid xs={12} md={6} item>
        <Box>
          
          <AsyncDropDown
          defaultVal={formValues.department?{_id: formValues.department,name: formValues.department_name}:null}
            InputComponent={(props) => <CustomInput label="Department" placeholder="Select Department"  {...props} />}
            label="Department*"
            lazyFun={async (props) => {
              return await fetchDepartments({ ...props })
            }}

            OptionComponent={({ option, ...rest }) => {
              return <Box sx={{ width: "100%", display: "flex", alignItems: "center" }} {...rest}>
                <Typography ml={3} variant="h5">{option.name}</Typography>
              </Box>
            }}

            onChange={async (changedVal) => {
              setFormValues({
                ...formValues,
                err: "",
                department: changedVal._id,
              })
            }}
            titleKey={'name'}
            valueKey={"_id"}


          />
        </Box>
      </Grid>

      <Grid xs={12} md={6} item>
        <Box>
          <CustomInput
          
            disabled={loading}
            type="text"
            label="Designation*"
            value={formValues.designation}
            onChange={(e) =>
              setFormValues({
                ...formValues,
                err: "",
                designation: e.target.value,
              })
            }
            sx={{ backgroundColor: "background.paper" }}
          />
        </Box>
      </Grid>


      <Grid xs={12} md={6} item>
        <Box>
          <MobileDatePicker
            disabled={loading}
            name="join_date"
            value={moment(formValues.join_date)}
            onChange={(e) =>
              setFormValues({
                ...formValues,
                err: "",
                join_date: e.toISOString(),
              })
            }
            inputFormat="DD MMM,YYYY"
            type="date"
            label="Joining Date*"


            renderInput={(params) => (
              <CustomInput
                {...params}
                sx={{ width: '100%' }}

              />
            )}
          />
        </Box>
      </Grid>
      
      <Grid xs={12} md={6} item>
        <Box>
          <UserSearchBarNormal
          defaultVal={formValues.parent_id?{_id: formValues.parent_id,name: formValues.parent_id_name}:null}
            inputProps={{
              label: "Reporting Person",
            }}

            onUserChange={(newVal) => {
              setFormValues({
                ...formValues,
                err: "",
                parent_id: newVal._id,
              })

            }
            }
          />
          <FormHelperText>
            {/* {getErrorMessage("Reporing Person")} */}
          </FormHelperText>
        </Box>
      </Grid>

      <Grid xs={12} md={6} item>
        <Box>
        <TimePicker
            disabled={loading}
            name="checked_in_time"
            value={moment(formValues.checked_in_time)}
            onChange={(e) =>
              setFormValues({
                ...formValues,
                err: "",
                checked_in_time: e.toISOString(),
              })
            }
            inputFormat="hh:mm a"
            label="Check In Time*"


            renderInput={(params) => (
              <CustomInput
                {...params}
                sx={{ width: '100%' }}

              />
            )}
          />
        </Box>
      </Grid>

      <Grid xs={12} md={6} item>
        <Box>
          <Autocomplete
defaultValue={formValues.weekly_of?formValues.weekly_of.map((item)=>WEEKLY_OFF.find(weeklyof=>item==weeklyof.value)):[]}
            sx={{ width: "100%", color: "primary.main" }}
            multiple
            onChange={(event, newValue) => {
              setFormValues({
                ...formValues,
                err: "",
                weekly_of: newValue.map((val) => val.value),
              });
            }}
            options={WEEKLY_OFF}
            getOptionLabel={(option) => option.label}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => (
                <Chip
                  label={option.label}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                margin="dense"
                {...params}
                label="Weekly Off*"
                variant="outlined"
                placeholder="Selected days"
              />
            )}
          />
        </Box>
      </Grid>


      <Grid xs={12} md={6} item>
        <Box>
          <CustomInput
          
            disabled={loading}
            type="number"
            label="4 Wheeler Petrol Price*"
            value={formValues.four_wheel_amount_per_km}
            onChange={(e) =>
              setFormValues({
                ...formValues,
                err: "",
                four_wheel_amount_per_km: e.target.value,
              })
            }
            sx={{ backgroundColor: "background.paper" }}
          />
        </Box>
      </Grid>
      <Grid xs={12} md={6} item>
        <Box>
          <CustomInput
          
            disabled={loading}
            type="number"
            label="2 Wheeler Petrol Price*"
            value={formValues.two_wheel_amount_per_km}
            onChange={(e) =>
              setFormValues({
                ...formValues,
                err: "",
                two_wheel_amount_per_km: e.target.value,
              })
            }
            sx={{ backgroundColor: "background.paper" }}
          />
        </Box>
      </Grid>

    </Grid>
  </Stack>
}

export const BankDetailsSection = ({
  loading,
  onSubmit,
  errors,
  formValues,
  setFormValues,
  onCheckChange,
  getErrorMessage,

}) => {
  return <Stack spacing={2} sx={{ mb: 2 }}>
    <Grid container rowGap={3} columnSpacing={3}>
      <Grid xs={12} md={6} item>
        <Box>
          <CustomInput
            disabled={loading}
            label="Bank Name*"
            type="text"
            name="bank_name"
            value={formValues.bank_name}
            onChange={(e) =>
              setFormValues({
                ...formValues,
                err: "",
                bank_name: e.target.value,
              })
            }
            sx={{ backgroundColor: "background.paper" }}
          />
        </Box>
      </Grid>
      <Grid xs={12} md={6} item>
        <Box>
          <CustomInput
            disabled={loading}
            name="ifsc_code"
            value={formValues.ifsc_code}
            onChange={(e) =>
              setFormValues({
                ...formValues,
                err: "",
                ifsc_code: e.target.value,
              })
            }
            type="text"
            label={"IFSC Code No.*"}

          />
        </Box>
      </Grid>
      <Grid xs={12} md={6} item>
        <Box>
          <CustomInput
            disabled={loading}
            name="account_no"
            value={formValues.account_no}
            onChange={(e) =>
              setFormValues({
                ...formValues,
                err: "",
                account_no: e.target.value,
              })
            }
            type="number"
            label={"Acccount No.*"}

          />
        </Box>
      </Grid>

      <Grid xs={12} md={6} item>
        <Box>
          <FileInput
            onChange={(files) => {
              onCheckChange(files)
            }}
            defaults={formValues.cancel_check ? [formValues.cancel_check] : []}
            accept="image/*,.pdf"
            title="Upload cancel-check/pass-book/bank-statement"
            subTitle="Only png,jpeg,jpg,pdf files are allowed! less than 1 MB"
          />
        </Box>
      </Grid>
    </Grid>
  </Stack>
}
export const DocumentsSection = ({
  loading,
  onSubmit,
  errors,
  formValues,
  setFormValues,
  getErrorMessage,
  onAggrimentChange,
  onNdaChange
}) => {
  return <Stack spacing={2} sx={{ mb: 2 }}>
    <Grid container rowGap={3} columnSpacing={3}>
      <Grid xs={12} md={12} item>
        <Box>
          <FileInput
            onChange={(files) => {
              onNdaChange(files)
            }}
            inputProps={{ accept: ".pdf" }}
            title="Upload NDA"

            subTitle="Only PDF files are allowed! less than 1 MB"

          />
        </Box>
      </Grid>
      <Grid xs={12} md={12} item>
        <Box>
          <FileInput
            onChange={(files) => {
              onAggrimentChange(files)
            }}
            inputProps={{ accept: ".pdf" }}
            title="Upload agreement"
            subTitle="Only PDF files are allowed! less than 1 MB"

          />
        </Box>
      </Grid>


    </Grid>
  </Stack>
}

const UserCreateUI = ({
  updateType,
  userId,
  loading,
  onSubmit,
  formValues,
  setFormValues,
  defaultFormValues

}) => {
  const { user } = useSelector((state) => state);
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(closeModal());
  };
  const sectionSelection = {
    [USER_UPDATE_TYPE.personalDetails]: 1,
    [USER_UPDATE_TYPE.companyDetails]: 2,
    [USER_UPDATE_TYPE.bankDetails]: 3,
    [USER_UPDATE_TYPE.salaryDetails]: 4,
    [USER_UPDATE_TYPE.documents]: 5,
  }
  const [section, setSection] = useState(updateType ? sectionSelection[updateType] : 1);

  const [errors, setErrors] = useState({});
  const steps = ['Personal Details', 'Company Details', 'Bank Details', ' Salary Details', 'Documents'];
  const [activeStep, setActiveStep] = React.useState(0);
  const handleNext = () => {
    
    const requiredFields = getValidationSchema(section);

    let hasError = false
    for (const fieldObj of requiredFields) {
      if (fieldObj.required && !formValues[fieldObj.field]) {
        hasError = true
        dispatch(callSnackBar(toTitleCase(fieldObj.name ? fieldObj.name : fieldObj.field) + "  is required", SNACK_BAR_VARIETNS.error))
        break;
      }
      if (fieldObj.validate && fieldObj.validate() !== true) {
        hasError = true
        dispatch(callSnackBar(fieldObj.validate(), SNACK_BAR_VARIETNS.error))
        break;
      }

    }
    if (!hasError && !userId && section<steps.length) {
      setSection(section + 1)
      setActiveStep(activeStep + 1)
    }
    return hasError
  };
const handleReset = () =>{
  setFormValues(defaultFormValues);
}

  const handleBack = () => {
    setSection((prev) => prev - 1);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const getValidationSchema = (section) => {
    switch (section) {
      case 1:
        return [
          {
            field: 'first_name',
            required: true,
          },
          {
            field: 'last_name',
            required: true,
          },
          {
            field: 'email',
            
            validate: () => {
              if(!userId){
              if (!formValues.email || formValues.email == "") {
                return "Email is required."
              } else if (!validateEmail(formValues.email)) {
                return "Invalid Email."
              }}
              return true
            }
          },
          {
            field: 'password',
            
            validate: () => {
              if (!userId) {


                if (!formValues.password || formValues.password == "") {
                  return "Password is required."
                } else if (!validatePassword(formValues.password)) {
                  return "Password must be atlist 8 character long and must contain atlist one special character,one digit & One capital letter."
                }
              }
              return true
            }
          },
          {
            field: 'phone',
            required: true
          },
          {
            field: 'phone_country_code',
            required: true
          },
          {
            field: 'phone_emergency_country_code',
            required: true
          },
          {
            field: 'phone_emergency',
            required: true
          },

          {
            field: 'blood_group',
            required: true,
          },
          {
            field: 'medical_remarks',
            required: true,
          },
          {
            field: 'address_line1',
            required: true,
          },
          {
            field: 'country',
            required: true,
          },

          {
            field: 'state',
            required: true,
          },
          {
            field: 'city',
            required: true,
          },
          

          {
            field: 'zip_code',
            required: true,
          },
          {
            field: 'pan_url',
            name: "PAN Image",
            required: true,
          },
          {
            field: 'addhar_url',
            name: "Aadhar Image",
            required: true,
          },

          {
            field: 'profile_url',
            name: "Profile Picture",
            required: true,
          },

          {
            field: 'pan_no',
            required: true,
            validate: () => {
              if (!formValues.pan_no || formValues.pan_no == "") {
                return "PAN number is required."
              }
              if (!validatePAN(formValues.pan_no)) {
                return "Invalid PAN number."
              }
              return true
            }
          },

          {
            field: 'aadhar_no',
            required: true,
            validate: () => {
              
              if (!formValues.aadhar_no || formValues.aadhar_no == "") {
                return "Addhar number is required."
              }
              else if (formValues.aadhar_no.toString().length != 12) {
                return "Aadhar no is invalid."
              }
              return true
            }
          },

        ];
        return []
      case 2:
        return [
          {
            field: 'employee_type',
            required: true,
          },
          {
            field: 'department',
            required: true,
          },
          {
            field: 'designation',
            required: true,
          },
          {
            field: 'join_date',
            required: true,
          },
          {
            field : 'checked_in_time',
            required : true,
          },
        ]
      case 3:
        return [
          {
            field: 'bank_name',
            required: true,
          },
          {
            field: 'account_no',
            required: true,
          },
          {
            field: 'ifsc_code',
            required: true,
          },
          
        ]

      case 4:
        return [];
      case 5:
        return [];
      default:
        return [];
    }
  };

  const onPanChange = (files) => {
    setFormValues({ ...formValues, pan_url: files });
  };
  const onAadharChange = (files) => {
    setFormValues({ ...formValues, addhar_url: files });
  };
  const onProfilePhototChange = (files) => {
    setFormValues({ ...formValues, profile_url: files });
  };
  const onNdaChange = (files) => {
    setFormValues({ ...formValues, nda_url: files });
  };
  const onAggrimentChange = (files) => {
    setFormValues({ ...formValues, aggreement_url: files });
  };

  const onCheckChange = (files) => {
    setFormValues({ ...formValues, cancel_check: files });
  };

  const submit = () => {

  };
  useEffect(() => {
    if (section > 1)
      localStorage.setItem('user_form_value', JSON.stringify(formValues))
  }, [section])
  const getErrorMessage = (fieldName) => {

    return errors[fieldName] ? errors[fieldName] : "";
  };
  return (

    <>
      <Box m={3}>

        <Box
          component="form"
          sx={{ display: "flex", flexDirection: "column", overflowY: "auto" }}
          maxHeight="100%"
          onSubmit={(e) => {e.preventDefault(); if (!handleNext()) { onSubmit(e) } }}
        >

          <Typography variant="h3">
            {!(updateType && userId) && "Create User"}
            <Box component={"div"}>
              <Typography variant="h4" color={"red"}>
                {formValues.err}{" "}
              </Typography>
            </Box>
          </Typography>

          <Box

          >
            {!(updateType && userId) && <Stepper alternativeLabel component={Box} mt={3}
              mb={3} activeStep={activeStep}>
              {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel  {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>}
            {section === 1 && (
              <PersonalDetailsSection
                isUpdate={userId && updateType}
                errors={errors}
                getErrorMessage={getErrorMessage}
                loading={loading}
                onSubmit={onSubmit}
                formValues={formValues}
                setFormValues={setFormValues}
                onPanChange={onPanChange}
                onAadharChange={onAadharChange}
                onProfilePhototChange={onProfilePhototChange}
              />
            )}


            {section === 2 && (
              <CompanyDetailsSection
                errors={errors}
                getErrorMessage={getErrorMessage}
                loading={loading}
                onSubmit={onSubmit}
                formValues={formValues}
                setFormValues={setFormValues}

              />
            )}
            {section === 3 && (
              <BankDetailsSection
                onCheckChange={onCheckChange}
                errors={errors}
                getErrorMessage={getErrorMessage}
                loading={loading}
                onSubmit={onSubmit}
                formValues={formValues}
                setFormValues={setFormValues}

              />
            )}
            {section === 4 && (

              <SalaryDetails

                errors={errors}
                getErrorMessage={getErrorMessage}
                loading={loading}
                onSubmit={onSubmit}
                formValues={formValues}
                setFormValues={setFormValues}

              />
            )}

            {section === 5 && (
              <Stack spacing={2} sx={{ mb: 2 }}>

                <DocumentsSection

                  errors={errors}
                  getErrorMessage={getErrorMessage}
                  loading={loading}
                  onSubmit={onSubmit}
                  formValues={formValues}
                  setFormValues={setFormValues}
                  onAggrimentChange={onAggrimentChange}
                  onNdaChange={onNdaChange}
                />
              </Stack>
            )}
          </Box>

          <Box mt={4} sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            {section == 1 && (
              <Box>

              </Box>
            )}
            {section > 1 && !(updateType && userId) && (
              <Box>
                <SubmitButton
                  variant="contained"
                  onClick={handleBack}
                  title={"Back"}
                ></SubmitButton>
              </Box>
            )}
            {section < 5 && !(updateType && userId) && (
              <Box sx={{display:"flex"}}>
              <Box sx={{padding:"0px 4px 0px"}}>
              <SubmitButton
                variant="contained"
                onClick={handleReset}
                title={"Reset"}
              ></SubmitButton>
            </Box>

              <Box  sx={{padding:"0px 4px 0px"}}>
                <SubmitButton
                  variant="contained"
                  onClick={handleNext}
                  title={"Next"}
                ></SubmitButton>
              </Box>
              </Box>
            )}
            {(section === 5 || (updateType && userId)) && (
              <>
                <Box sx={{ width: userId ? "100%" : "unset" }}>
                  <SubmitButton
                    loading={loading}
                    type=""
                    title={userId ? "Update" : "Create"}
                    onClick={submit}
                  ></SubmitButton>
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default UserCreateUI;
