import axios from 'axios'
import { getHeaders } from '../utils/helper'
import endpoints from './endpoints'

export const getMonthlyVisits = async (params) => {
  const callResponse = await axios({
    url: endpoints.monthlyVisit,
    method: 'get',
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => ({status:0,response:err.response,code:err.response.status}))

  return callResponse
}
export const getVisitByIdApi = async (params) => {
    const callResponse = await axios({
      url: endpoints.visitById,
      method: 'get',
      headers: getHeaders(),
      params,
    })
      .then((response) => response.data)
      .catch((err) => ({status:0,response:err.response,code:err.response.status}))
  
    return callResponse
  }
  export const getVisitedPlacesApi = async (params) => {
    const callResponse = await axios({
      url: endpoints.visitedPlaces,
      method: 'get',
      headers: getHeaders(),
      params,
    })
      .then((response) => response.data)
      .catch((err) => ({status:0,response:err.response,code:err.response.status}))
  
    return callResponse
  }
  export const getPlacesApi = async (params) => {
    const callResponse = await axios({
      url: endpoints.visitPlaces,
      method: 'get',
      headers: getHeaders(),
      params,
    })
      .then((response) => response.data)
      .catch((err) => ({status:0,response:err.response,code:err.response.status}))
  
    return callResponse
  }
  export const getPlaceByIdApi = async (params) => {
    const callResponse = await axios({
      url: endpoints.placeById,
      method: 'get',
      headers: getHeaders(),
      params,
    })
      .then((response) => response.data)
      .catch((err) => ({status:0,response:err.response,code:err.response.status}))
  
    return callResponse
  }