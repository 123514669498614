import { Info } from "@mui/icons-material"
import { IconButton, Tooltip } from "@mui/material"
import { useDispatch } from "react-redux";
import { openModal } from "../../../store/actions/modalAction";
import ProfileDialog from "../../profile/ProfileDialog";
import ReimberesementDetailsController from "../ReimberesementDetailsController";

const ReimburesmentViewMoreButton = ({ id }) => {
    const dispatch = useDispatch()
    const onClick = () => {
        dispatch(openModal({
            title: "Reimburesement Details",
            component: <ReimberesementDetailsController reimbursementId={id} />,
            size: "md"
        }));
    };
    return <Tooltip title="View More" >
        <IconButton onClick={onClick} >
            <Info color="info" />
        </IconButton>
    </Tooltip>
}
export default ReimburesmentViewMoreButton