import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserByIdApi, updateUserField } from "../../apis/user.api";
import { callApiAction } from "../../store/actions/commonAction";
import { loggedInUser } from "../../utils/helper";
import { fetchDepartments, deleteDepartment } from "../../apis/department";
import DepartmentUI from "./DepartmentUi";
import UpdateDepartmentButton from "./UpdateDepartmentButton";
import DeleteDepartmentButton from "./DeleteDepartmentButton";
import { Box } from "@mui/material";
import { fetchDepartmentDataAction } from "../../store/actions/settingsAction";

const DepartmentController = ({ userId }) => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [state, setState] = useState([]);
  const [loading, setLoading] = useState(false);
  const onUpdateValue = () => { };
  const {settings} = useSelector((state) => state)
  const fetchDepartmentsApi = fetchDepartments;


  const columns = useMemo(() => [
    { id: 1, fieldName: 'name', label: 'Name', align: "left", sort: true, minWidth: '150px' },
    {
      id: 2, fieldName: 'parents', label: 'Parent Department', align: "left", renderValue: (params, setParams) => (params.parents?.[0]?.name ?? "NA")
    },
    {
      id: 3, fieldName: 'action', label: 'Action', align: "left", renderValue: (params, setParams) => <Box sx={{ display: "flex" }}>
        <UpdateDepartmentButton params={params} setParams={setParams} />

        <DeleteDepartmentButton params={params} setParams={setParams} />

      </Box>
    },
  ], [dispatch]);
  const [filters, setFilters] = useState({
    pageSize: 10,
    pageNo: 1
  })

  const fetchList = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () =>
          await fetchDepartmentsApi(filters),
        (response) => {
          setState(response.result);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };


  const getDepartmentList = () => {
    if (!settings.department_data || settings.department_data.length === 0 || JSON.stringify(filters)!=JSON.stringify(settings.department_filters)) {
        dispatch(fetchDepartmentDataAction(filters));
    }
}
  useEffect(() => {
    getDepartmentList()
    fetchList();
  }, [filters]);

  return (
    <DepartmentUI
      columns={columns}
      setState={setState}
      loading={settings.department_loading}
      state={settings.department_data}
      callBack={getDepartmentList}
      filters={filters}
      setFilters={setFilters}

    />
  );
};
export default DepartmentController;
