import {
    Box,

    Checkbox,
    Chip,
    Collapse,
    Grid,
    Paper,
    Skeleton,

    Typography,
} from '@mui/material'
import moment from 'moment'

import { LEAVE_NAMES, LEAVE_TYPE } from '../../../utils/leave.constants'
import { CenteredBox } from '../../../components/layouts/common/boxes'
import SubmitButton, { ResetButton } from '../../../components/button/SubmitButton'

export const LeavePreviewBox = ({ count, type, date }) => {

    return <Grid item xs={12} sm={6} >
        <Paper component={Box} elevation={0} p={3} sx={{ display: "flex", background: "#f2f2f2", border: 1 }}>
            <CenteredBox sx={{ minWidth: "25%", borderRight: 1 }} pr={2}>
                <Paper component={CenteredBox} elevation={0} sx={{
                    width: "100%",
                    maxWidth: "60px",
                    background: LEAVE_NAMES[Number(type) - 1]?.background,
                    color: LEAVE_NAMES[Number(type) - 1]?.color,
                    border: "1px solid " + LEAVE_NAMES[Number(type) - 1]?.color

                }} p={1} >
                    <Typography variant='caption' fontWeight="bold" >    {LEAVE_NAMES[Number(type) - 1]?.label}</Typography>
                </Paper>
            </CenteredBox>
            <CenteredBox sx={{ display: "flex", flexDirection: "column", borderRight: 1, flex: 1 }}>
                <Typography variant='subtitle1' >{moment(date).format('DD MMM YYYY')}</Typography>

            </CenteredBox>
            <CenteredBox sx={{ minWidth: "25%" }}>
                <Typography variant='h3' >
                    {count}
                </Typography>
            </CenteredBox>

        </Paper>
    </Grid>
}

const LeavePreview = ({

    onSubmit,
    data,
    loading,
    onClose


}) => {








    return (
        <Box mt={1} >
            <Box component="form" onSubmit={onSubmit} sx={{ display: "flex", minHeight: "300px", width: "100%", flexDirection: "column", justifyContent: "space-between", flex: 1 }} mt={4}>


                <Grid container spacing={2}>
                    {
                        data?.leaves?.map((item) => <LeavePreviewBox key={item.from} count={item.leave_count} type={item.type} date={item.from} />)
                    }
                </Grid>



                 <Box mt={3} sx={{ display: "flex", justifyContent: "flex-end" }}>

                    <Box sx={{ display: "flex" }} mr={3}>
                        <ResetButton
                            loading={loading}
                            type="reset"
                            variant="text"
                            onClick={onClose}

                            title="Back"
                        >

                        </ResetButton>
                    </Box>
                    <Box sx={{ display: "flex" }}>
                        <SubmitButton
                            disabled={loading}
                            loading={loading}
                            type="submit"
                            variant="contained"
                            color="primary"
                            title=" Submit Leave"
                        >

                        </SubmitButton>
                    </Box>
                </Box> 
            </Box>



        </Box >
    )
}
export default LeavePreview
