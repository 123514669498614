import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { callApiAction } from '../../store/actions/commonAction'
import { useParams } from 'react-router-dom'
import { openModal } from '../../store/actions/modalAction'
import { getCoffApi, getLeavesApi, getLogsApi, getMonthlyLeavesApi } from '../../apis/leave.api'
import moment from 'moment'
import Calendar from '../../components/layouts/Calendar'
import CalendarBox from './CalendarInnerBox'

import {
  CALENDAR_ITEM_TYPES,
  HALF_TYPE_OBJ,
  LEAVE_COLORS,
  LEAVE_RESPONSE,
  SNACK_BAR_VARIETNS,

} from '../../utils/constants'
import LeaveDetailsController from '../leaves/leaveSummary/LeaveDetailsController'
import CoffDetailsController from '../leaves/leaveSummary/CoffDetailsController'
import { Box, Typography } from '@mui/material'
import { Circle } from '@mui/icons-material'
import { findObjectKeyByValue } from '../../utils/helper'
import { LEAVE_NAMES, LEAVE_STATUS, LEAVE_TYPE } from '../../utils/leave.constants'
import { callSnackBar } from '../../store/actions/snackbarAction'
import { fetchOneMonthWorkFromHomeDataApi } from '../../apis/wfh.api'

const CalendarController = ({ userId, weeklyOf, calendarTimeObj, forEmployee }) => {
  const { holiday } = useSelector((state) => state)

  const [list, setList] = useState([])
  const [listLoading, setListLoading] = useState(false)

  const holidayList = holiday.data
  const [leaveList, setLeaveList] = useState(null)
  const [wfhList, setWFHList] = useState(null)
  const [cOffList, setCOFFList] = useState(null)
  const [logsList, setLogsList] = useState(null)

  const [leavesCount, setLeavesCount] = useState({})
  const dispatch = useDispatch()

  const [filters, setFilters] = useState({
    userId: userId,
  })

  const fetchList = () => {
    setListLoading(true)
    setCOFFList(null)
    setWFHList(null)
    setLeaveList(null)
    setLogsList(null)

    const parmasToPass = { ...filters }

    if (forEmployee) {
      parmasToPass['not_status'] = LEAVE_STATUS.REJECTED
      parmasToPass['forEmployee'] = true
    }
    dispatch(
      callApiAction(
        async () =>
          await getMonthlyLeavesApi({
            ...parmasToPass,
            userId,
            date: calendarTimeObj.valueOf(),
          }),
        (response) => {
          setLeaveList(response)
        },
        (err) => {
          dispatch(callSnackBar("Something went wrong!", SNACK_BAR_VARIETNS.error))
        },
      ),
    )

    if (!forEmployee) {
      dispatch(
        callApiAction(
          async () =>
            await getLogsApi({
              ...parmasToPass,
              userId,
              date: calendarTimeObj.toISOString(),
            }),
          (response) => {
            setLogsList(response)
          },
          (err) => {
            dispatch(callSnackBar("Something went wrong!", SNACK_BAR_VARIETNS.error))
          },
        ),
      )
      
      dispatch(
        callApiAction(
          async () =>
            await getCoffApi({
              ...parmasToPass,
              userId,
              date: calendarTimeObj.valueOf(),
            }),
          (response) => {
            setCOFFList(response)
          },
          (err) => {
            dispatch(callSnackBar("Something went wrong!", SNACK_BAR_VARIETNS.error))
          },
        ),
      )

      dispatch(
        callApiAction(
          async () =>
            await fetchOneMonthWorkFromHomeDataApi({
              ...parmasToPass,
              userId,
              date: calendarTimeObj.toISOString(),
            }),
          (response) => {
            setWFHList(response)
          },
          (err) => {
            dispatch(callSnackBar("Something went wrong!", SNACK_BAR_VARIETNS.error))
          },
        ),
      )
    }
  }

  useEffect(() => {
    fetchList()
  }, [filters, userId, calendarTimeObj.valueOf()])

  useEffect(() => {
    if ((forEmployee || (!forEmployee && cOffList && logsList && wfhList )) && holidayList && leaveList) {
      let temp = []
      if (logsList && Array.isArray(logsList) && logsList.length > 0) {
        logsList.forEach((dayLog) => {
          if (
            dayLog.data &&
            Array.isArray(dayLog.data) &&
            dayLog.data.length > 0
          ) {
            dayLog.data.forEach((log) => {
              temp.push({
                title: moment(log.logTime).format('HH:mm'),
                start: new Date(log.logTime),
                end: new Date(log.logTime),
                logTime: log.logTime,
                relatedId: log._id,
                location_id: log?.location_id,
                type: log.logType,
                checkIn : log.checked_in_status,
                userId : log.userId,
                logId : log._id,
              })
            })
          }
        })
      }

      if (cOffList && Array.isArray(cOffList)) {
        cOffList.forEach((coff) => {
          temp.push({
            title: 'Comp-OFF',
            start: new Date(coff.date),
            end: new Date(coff.date),
            relatedId: coff._id,
            fromDuration: coff.time_duration,
            toTimeDuration: coff.time_duration,
            type: CALENDAR_ITEM_TYPES.coff,
            // color: LEAVE_COLORS['COFF_RAISE' + '_' + findObjectKeyByValue(coff.status, LEAVE_STATUS)],
            color: coff.status == LEAVE_STATUS.APPROVED ? "white" : coff.status == LEAVE_STATUS.REJECTED ? "white" : "white",
            background: coff.status == LEAVE_STATUS.APPROVED ? "green" : coff.status == LEAVE_STATUS.REJECTED ? "red" : "gray",
            onClick: () => {

              dispatch(
                openModal(
                  {
                    component: <CoffDetailsController
                      leaveId={coff._id}
                      callBack={() => { }}
                      isFromModal={true}
                    />,
                    title: "Comp Off Application"
                  }
                ),
              )
            },
          })
        })
      }
      if (leaveList && Array.isArray(leaveList)) {
        leaveList.forEach((leave) => {

          const obj = {
            title: LEAVE_NAMES[leave.type - 1].label,

            relatedId: leave._id,

            type: CALENDAR_ITEM_TYPES.leave,
            status: leave.status,
            color: leave.status == LEAVE_STATUS.APPROVED ? LEAVE_NAMES[leave.type - 1]?.color : leave.status == LEAVE_STATUS.REJECTED ? "white" : "white",
            background: leave.status == LEAVE_STATUS.APPROVED ? LEAVE_NAMES[leave.type - 1]?.background : leave.status == LEAVE_STATUS.REJECTED ? "red" : "gray",
            applied_by: leave?.applied_by,
            onClick: () => {
              dispatch(


                openModal(
                  {
                    component: <LeaveDetailsController
                      leaveId={leave._id}
                      callBack={() => { }}
                      isFromModal={true}
                    />,
                    title: "Leave Application"
                  }
                ),
              )
            },
          }
          if (leave?.deducted_leave?.length > 0) {
            
            leave?.deducted_leave.forEach((subLeave)=>{
              const timeDuration = {
                start: new Date(subLeave.from),
                end: new Date(subLeave.from),
                fromDuration: subLeave.from_time_duration,
                toTimeDuration: subLeave.from_time_duration,
  
              }
              temp.push({...obj,...timeDuration})
            })


          } else {

            const timeDuration = {
              start: new Date(leave.from),
              end: new Date(leave.to),
              fromDuration: leave.from_time_duration,
              toTimeDuration: leave.to_time_duration,

            }
            temp.push({...obj,...timeDuration})
          }

          
        })
      }

      if (wfhList && Array.isArray(wfhList)) {
        wfhList.forEach((wfh) => {

          temp.push({
            title: 'WFH',
            start: new Date(wfh.date),
            end: new Date(wfh.date),
            relatedId: wfh._id,
            duration: wfh.duration??HALF_TYPE_OBJ.FULL_DAY,
            
            type: CALENDAR_ITEM_TYPES.wfh,
            status: wfh.status,
            _id: wfh._id
          })
        })
      }

      if (holidayList && Array.isArray(holidayList)) {
        holidayList.forEach((leave) => {
          temp.push({
            title: leave.name,
            start: new Date(leave.date),
            end: new Date(leave.date),
            relatedId: leave._id,
            type: CALENDAR_ITEM_TYPES.holiday,
            color: LEAVE_COLORS.HOLIDAY,
            onClick: () => { },
          })
        })
      }

      setList(temp)
      setListLoading(false)
    }
  }, [cOffList, logsList, holidayList, leaveList])

  useEffect(() => {
    if (leaveList && Array.isArray(leaveList) && leaveList.length > 0) {
      const temp = {}
      leaveList.forEach((leave) => {
        if (leave.status == LEAVE_STATUS.APPROVED) {

          if (leave.type && leave.type != LEAVE_TYPE.MULTI_LEAVES) {
            temp[leave.type] = leave.leave_count + (temp[leave.type] ?? 0)
          }
          if (leave.type && leave.type == LEAVE_TYPE.MULTI_LEAVES) {

            leave.deducted_leave?.forEach((item) => {
              temp[item.type] = item.leave_count + (temp[item.type] ?? 0)
            })
          }
        }


      })

      setLeavesCount(temp)
    } else {
      setLeavesCount({})
    }
  }, [leaveList])

  return (
    <>
      <Calendar

        loading={listLoading}
        data={list}
        dayComponent={(props) => <CalendarBox forEmployee={forEmployee} weeklyOf={weeklyOf ?? [0]} userId={userId}  {...props} />}
        calendarTimeObj={calendarTimeObj}
      />
      {leavesCount && !forEmployee &&
        Object.keys(leavesCount) &&
        Object.keys(leavesCount).length > 0 && (
          <Box mt={3} p={2} sx={{ display: 'flex' }}>
            <Typography variant="h4" mr={3}>
              Current month leaves{' '}
            </Typography>

            {Object.keys(leavesCount).map((leave) => {
              return (
                <Box sx={{ display: 'flex', alignItems: 'center' }} mr={3}>
                  <Circle sx={{ color: LEAVE_NAMES[leave - 1]?.color, opacity: 0.5 }} />
                  <Typography variant="h5" color="grey.main" mr={2} ml={2}>
                    {LEAVE_NAMES[leave - 1]?.label}
                  </Typography>
                  <Typography variant="h5">
                    {
                      leavesCount[leave]
                    }
                  </Typography>
                </Box>
              )
            })}


          </Box>
        )}
    </>
  )
}
export default CalendarController
