import { Delete, Edit } from "@mui/icons-material"
import { CircularProgress, IconButton, Typography } from "@mui/material"
import { useState } from "react"

import { useDispatch } from "react-redux"
import { closeModal, openModal } from "../../store/actions/modalAction"
import UpdateBranchController from "./UpdateBranchController"


const UpdateBranchButton = ({ params, setParams }) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState()


    const onClick = () => {
        dispatch(openModal({
            title: "Update Branch",
           component:<UpdateBranchController callBack={(res)=>{setParams({...params,...res})}} id={params._id} initialAddress={params.address} intialName={params.name} />
        }))
    }
    if (loading)
        return <CircularProgress />
    return <IconButton onClick={onClick}>
        <Edit color="info" />
    </IconButton>
}
export default UpdateBranchButton