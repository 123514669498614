import { Info } from "@mui/icons-material"
import { IconButton, Tooltip } from "@mui/material"
import { useDispatch } from "react-redux";
import { openModal } from "../../../store/actions/modalAction";

import LeaveDetailsController from "./LeaveDetailsController";
import CoffDetailsController from "./CoffDetailsController";

const CoffViewButton = ({ id }) => {
    const dispatch = useDispatch()
    const onClick = () => {
        dispatch(openModal({
            title: "Comp-off Details",
            component: <CoffDetailsController leaveId={id} isFromModal />,
            size: "md"
        }));
    };
    return <Tooltip title="View More" >
        <IconButton onClick={onClick} >
            <Info color="info" />
        </IconButton>
    </Tooltip>
}
export default CoffViewButton