import { CakeOutlined, CallOutlined, MailOutline, WifiCalling3Outlined } from "@mui/icons-material"
import { Avatar, Box, Grid, Link, Paper, Typography, useMediaQuery, useTheme } from "@mui/material"
import moment from "moment"
import { setStringView, toTitleCase, unEscapeStr } from "../../utils/helper"
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';

export const BasicProfileDetailsComponent = ({ data }) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))
    return (
        <>
        <Box mt={3} mb={3}>
        <Grid container columnSpacing={3} rowSpacing={3}>
          <Grid item xs={12}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
              }}
              mr={2}
            >
              <Box
                sx={{
                  height: '100%',
                  border: '1px solid #BFBFBF',
                  boxShadow:
                    '0px 2px 9px rgba(50, 71, 92, 0.06), 0px 4px 9px 1px rgba(50, 71, 92, 0.04), 0px 2px 6px 4px rgba(50, 71, 92, 0.02)',
                  borderRadius: '4px',
                }}
              >
                <Paper sx={{ width: "100%", display: "flex", padding: isMobile ? 2 : 3, boxShadow: "none" }}>
                <Box sx={{ display: "flex", flex: 1 }}>

                    {data.profile_url && <Avatar sizes={isMobile ? "small" : "large"} src={unEscapeStr(data.profile_url)} sx={{ width: isMobile ? "35px" : "70px", height: isMobile ? "35px" : "70px", bgcolor: "primary.main" }}>


                    </Avatar>}
                    {!data.profile_url && <Avatar sizes={isMobile ? "small" : "large"} sx={{ width: isMobile ? "35px" : "70px", height: isMobile ? "35px" : "70px", bgcolor: "primary.main" }}>

                        {<Typography variant="h1">
                            {
                                data.name[0].toUpperCase()
                            }
                        </Typography>}
                    </Avatar>}
                    <Box ml={3} sx={{ display: "flex", flexDirection: "column", flex: 1, justifyContent: "center" }}>
                        <Typography variant="h2">
                            {setStringView(toTitleCase(data.name),'end')}
                        </Typography>
                        <Typography variant="h6" color={'grey.main'} >
                            {data.designation}
                        </Typography>
                    </Box>
                </Box>
                {!isMobile && <Box sx={{ display: "flex", flex: 2 }} m={1}>
                    <Grid container spacing={2} rowSpacing={3}>
                        <Grid item xs={4} >
                            <Typography sx={{ alignItems: "center", display: "flex", lineHeight: "100%", overflow: "hidden" }} variant="h6" >
                                <MailOutline />&nbsp;&nbsp;<Link href={`mailto:${data.email}`}>{setStringView(data.email)}</Link>
                            </Typography>
                        </Grid>
                        <Grid item xs={4} >
                            <Typography sx={{ alignItems: "center", display: "flex", lineHeight: "100%" }} variant="h6" >
                                <CallOutlined />&nbsp;&nbsp;{data.phone}
                            </Typography>
                        </Grid>

                        <Grid item xs={4} >
                            <Typography sx={{ alignItems: "center", display: "flex", lineHeight: "100%" }} variant="h6" >
                                <EventAvailableIcon />&nbsp;&nbsp;{data.join_date ? moment(data.join_date).format("DD-MM-YYYY") : "-"}
                            </Typography>
                        </Grid>
                        <Grid item xs={4} >
                            <Typography sx={{ alignItems: "center", display: "flex", lineHeight: "100%" }} variant="h6" >
                                <PeopleAltOutlinedIcon />&nbsp;&nbsp;{toTitleCase(data.first_parent && data.first_parent.name)}
                            </Typography>
                        </Grid>
                        <Grid item xs={4} >
                            <Typography sx={{ alignItems: "center", display: "flex", lineHeight: "100%" }} variant="h6" >
                                <WifiCalling3Outlined />&nbsp;&nbsp;{data.phone_emergency}
                            </Typography>
                        </Grid>
                        <Grid item xs={4} >
                            <Typography sx={{ alignItems: "center", display: "flex", lineHeight: "100%" }} variant="h6" >
                                <CakeOutlined />&nbsp;&nbsp;{data.dob ? moment(data.dob).format("DD-MM-YYYY") : "-"}
                            </Typography>
                        </Grid>

                    </Grid>
                </Box>}
                </Paper>
            </Box>
        </Box>
    </Grid>
    </Grid>
    </Box>
        </>
    )
}