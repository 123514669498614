import { Box, Button, Grid, Paper, Typography } from "@mui/material"
import { Fragment, useCallback, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { openModal } from "../../store/actions/modalAction";


import { CenteredBox } from "../../components/layouts/common/boxes";
import moment from "moment";

const SalaryStructureRow = ({ ...item }) => {

    const salaryArr = useMemo(() => [

        {
            title: "Basic Salary",
            value: item.basic_salary,

        },
        {
            title: "House Rent Allowance",
            value: item.house_rent_allowance,

        },
        {
            title: "Conveyence Allowance",
            value: item.conveyence_allowance,

        },
        {
            title: "Food Allowance",
            value: item.food_allowance,

        },
        {
            title: "Other Allowance",
            value: item.other_allowance,

        },
        {
            title: "Incentive",
            value: item.incencitive,

        },
        {
            title: "Medical Allowance",
            value: item.medical_allowance,

        }

    ], [item])
    const deductionArr = useMemo(() => [
        {
            title: "Proffesional Tax",
            value: item.proffesional_tax,

        },
        {
            title: "PF",
            value: item.pf,

        },
        {
            title: "ESIC",
            value: item.esic,

        }
    ], [])
    return <>
        <Box component={Paper} elevation={0} sx={{ border: "1px solid gray", background: "lightgray" }} p={3} mb={2}>

            <Grid container >
                <Grid item xs={6} >
                </Grid>
                <Grid item xs={6} >
                    <Typography align="right" >Updated At: {moment(item.updatedAt).format("DD/MM/YYYY")}</Typography>
                </Grid>
            </Grid>
            <Box sx={{ width: "100%", background: "#fff" }}>
                <Box sx={{ border: 1 }} p={2}>
                    <Grid container columnSpacing={3} >
                        <Grid item xs={6} sx={{ borderRight: 1 }} >
                            <Typography mb={2} fontWeight="bold">
                                Salary Components
                            </Typography>
                            <Grid container sx={{ width: "100%" }} >
                                {
                                    salaryArr.map((item) => <Fragment key={item.title} >
                                        <Grid item xs={6} >
                                            <Box sx={{}} >
                                                <Typography variant="body1" >
                                                    {item.title}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6} >
                                            <Box sx={{}} >
                                                <Typography variant="body1" >
                                                    {item.value}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Fragment>)
                                }
                            </Grid>
                        </Grid>

                        <Grid item xs={6} >
                            <Typography mb={2} fontWeight="bold">
                                Deduction Components
                            </Typography>
                            <Grid container >

                                {
                                    deductionArr.map((item) => <Fragment key={item.title} >
                                        <Grid item xs={6} >
                                            <Box sx={{}} >
                                                <Typography variant="body1" >
                                                    {item.title}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6} >
                                            <Box sx={{}} >
                                                <Typography variant="body1" >
                                                    {item.value}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Fragment>)
                                }

                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ border: 1 }} p={2}>
                    <Grid container columnSpacing={3} >
                        <Grid item sx={{ borderRight: 1 }} xs={6} >
                            <Grid container >
                                <Grid item xs={6} >
                                    <Typography pr={3} align="right" fontWeight="bold">
                                        Total Salary:
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} >
                                    <Typography fontWeight="bold">
                                        {
                                            salaryArr.reduce((currVal, item) => currVal + Number(item.value), 0)
                                        }
                                    </Typography>
                                </Grid>

                            </Grid>



                        </Grid>
                        <Grid item xs={6} >
                            <Grid container >
                                <Grid item xs={6} >
                                    <Typography pr={3} align="right" fontWeight="bold">
                                        Total Deduction:
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} >
                                    <Typography fontWeight="bold">
                                        {
                                            deductionArr.reduce((currVal, item) => currVal + Number(item.value), 0)
                                        }
                                    </Typography>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <CenteredBox mt={4} sx={{ flexDirection: "column" }}>
                <Typography variant="h3" >Total Monthly Salary </Typography>
                <Typography variant="h4" >{
                    salaryArr.reduce((currVal, item) => currVal + Number(item.value), 0) - deductionArr.reduce((currVal, item) => currVal + Number(item.value), 0)
                } </Typography>

            </CenteredBox>
        </Box>
    </>
}
const SalaryStructureUI = ({ setState, filters, setFilters, callBack, loading, state, columns }) => {
    const dispatch = useDispatch();

    return <>
        <Box
            mt={2}
            sx={{

                border: '1px solid #BFBFBF',
                boxShadow:
                    '0px 2px 9px rgba(50, 71, 92, 0.06), 0px 4px 9px 1px rgba(50, 71, 92, 0.04), 0px 2px 6px 4px rgba(50, 71, 92, 0.02)',
                borderRadius: '4px',
            }}
        >
            <Box m={3} >
                <Box>
                    <Typography sx={{
                        fontWeight: 700,
                        fontSize: "24px",
                        lineHeight: "40px",
                        color: "#0D0D0D"
                    }}>Salary Structure</Typography>

                </Box>
                <Box sx={{ minHeight: "300px",display:"flex" ,flexDirection:"column"}} mt={3}>
                    {
                        (!(state?.length) || state.length == 0) && <CenteredBox sx={{minHeight:"100%",flex:1}}>
                            <Typography>
                                No History Available
                            </Typography>
                        </CenteredBox>
                    }
                    {
                        state.length > 0 && state.map((item) => <SalaryStructureRow key={item._id} {...item} />)
                    }
                </Box>
            </Box>
        </Box>
    </>
}
export default SalaryStructureUI