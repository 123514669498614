import { Box } from "@mui/material"

import DataTable from "../../../components/tables/DataTable"
import CustomInput from "../../../components/inputs/CustomInput"



const TaskTable = ({
    loading,
    filters,
    list,
    columns,
    setFilters,
}) => {
    return <Box sx={{ minHeight: "300px" }} >
        <Box mt={2} mb={2} sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Box>
                <CustomInput value={filters.search} onChange={(e) => { setFilters({ ...filters, search: e.target.value }) }} size="small" label="search" ></CustomInput>
            </Box>

        </Box>
        <DataTable columns={columns} rows={list?.result ?? []} count={list?.total} filters={filters} setFilters={setFilters} loading={loading} />
    </Box>

}
export default TaskTable