import { Chip, capitalize } from '@mui/material'
import { CALENDAR_ITEM_TYPES, LEAVE_RESPONSE, REIMBURSEMENT_STATUS } from './constants'
import { LEAVE_STATUS } from './leave.constants'
import moment from 'moment'
import { INTERVIEW_STATUS } from './interview.constants'

export const accessTokenLastUpdatedAt = {
  set: (val) => {
    localStorage.setItem('wehear_hrms_access_token_last_update', val)
  },
  get: () => localStorage.getItem('wehear_hrms_access_token_last_update'),
  remove: () => {
    localStorage.removeItem('wehear_hrms_access_token_last_update')
  },
}
export const accessToken = {
  set: (val) => {
    localStorage.setItem('wehear_hrms_access_token', val)
    accessTokenLastUpdatedAt.set(moment().valueOf())
  },
  get: () => localStorage.getItem('wehear_hrms_access_token'),
  remove: () => {
    localStorage.removeItem('wehear_hrms_access_token')
    accessTokenLastUpdatedAt.remove()
  },
}

export const loggedInUser = {
  set: (val) => {
    localStorage.setItem('wehear_hrms_user', JSON.stringify(val))
  },
  get: () =>
    localStorage.getItem('wehear_hrms_user')
      ? JSON.parse(localStorage.getItem('wehear_hrms_user'))
      : null,
  remove: () => {
    localStorage.removeItem('wehear_hrms_user')
  },
}

export const refreshToken = {
  set: (val) => {
    localStorage.setItem('wehear_hrms_refresh_token', val)
  },
  get: () => localStorage.getItem('wehear_hrms_refresh_token'),
  remove: () => {
    localStorage.removeItem('wehear_hrms_refresh_token')
  },
}

export const lastReadNotification = {
  set: (val) => {
    localStorage.setItem('last_notification', val)
  },
  get: () =>
    localStorage.getItem('last_notification')
      ? localStorage.getItem('last_notification')
      : null,
  remove: () => {
    localStorage.removeItem('last_notification')
  },
}

export const alreadyLoggedIn = {
  set: (val) => {
    localStorage.setItem('once_logged_in', val)
  },
  get: () => localStorage.getItem('once_logged_in'),
  remove: () => {
    localStorage.removeItem('once_logged_in')
  },
}
export const lastReadVersion = {
  set: (val) => {
    localStorage.setItem('last_version', val)
  },
  get: () => localStorage.getItem('last_version'),
  remove: () => {
    localStorage.removeItem('last_version')
  },
}
export const lastBirthdayWishYear = {
  set: (val) => {
    localStorage.setItem('birthday_wish_year', val)
  },
  get: () => localStorage.getItem('birthday_wish_year'),
  remove: () => {
    localStorage.removeItem('birthday_wish_year')
  },
}

export const birthdayFetchDate = {
  set: (val) => {
    localStorage.setItem('birthday_fetch_date', val)
  },
  get: () => localStorage.getItem('birthday_fetch_date'),
  remove: () => {
    localStorage.removeItem('birthday_fetch_date')
  },
}
export const toTitleCase = (str) => {
  if (str)
    return str.replace(/_/g, " ").replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    })
  return str
}

export const logOut = () => {
  refreshToken.remove()
  accessToken.remove()
  loggedInUser.remove()
  lastReadNotification.remove()
}

export const getHeaders = () => {
  const token = `Bearer ${accessToken.get()}`
  return {
    Authorization: token,
  };
};
export const getFileHeaders = () => {
  const token = `Bearer ${accessToken.get()}`;
  return {
    Authorization: token,
    "Content-Type": "multipart/form-data"
  };
};
export function setZeroPrefix(val) {
  if (parseInt(val, 10) < 10) {
    return `0${val}`
  }
  return val
}

export const dateConverter = ({ type = 'DD_MM_YYYY', value }) => {
  if (type === 'DD_MM_YYYY') {
    const dateObj = new Date(value)
    return `${setZeroPrefix(dateObj.getDate())}/${setZeroPrefix(
      dateObj.getMonth() + 1,
    )}/${dateObj.getFullYear()}`
  }
  return value
}
export const validatePassword = (val) => {
  const re = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/i
  return re.test(val)
}
export const validateEmail = (email) => {
  var re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i
  return re.test(email)
}

export const validatePAN = (pan) => {
  var re = /[A-Z]{5}[0-9]{4}[A-Z]{1}/i
  return re.test(pan)
}
export const validatePhone = (phone) => {
  var re = /^\d{10}$/
  return phone.match(re)
}

export const getDateFiltersTime = (value) => {
  let date = new Date()
  let startDate = date.getTime()
  let endDate = date.getTime()

  switch (value) {
    case 'this_week':
      {
        const currentDay = date.getDay()
        startDate = startDate - currentDay * 3600 * 1000 * 24
        const temp = new Date(startDate)
        startDate = new Date(
          temp.getFullYear(),
          temp.getMonth(),
          temp.getDate(),
        ).getTime()
        endDate = new Date(
          temp.getFullYear(),
          temp.getMonth(),
          temp.getDate() + 7,
        ).getTime()
      }
      break
    case 'this_month':
      {
        const temp = new Date(startDate)
        startDate = new Date(temp.getFullYear(), temp.getMonth()).getTime()
      }
      break
    case 'this_year':
      {
        const temp = new Date(startDate)
        startDate = new Date(temp.getFullYear()).getTime()
      }
      break

    case 'last_month':
      {
        const temp = new Date(startDate)
        startDate = new Date(temp.getFullYear(), temp.getMonth() - 1).getTime()
        endDate = new Date(temp.getFullYear(), temp.getMonth()).getTime()
      }
      break
    case 'last_year':
      {
        const temp = new Date(startDate)
        startDate = new Date(temp.getFullYear() - 1).getTime()
        endDate = new Date(temp.getFullYear()).getTime()
      }
      break
    case 'last_week':
      {
        const currentDay = date.getDay()
        endDate = endDate - currentDay * 3600 * 1000 * 24
        startDate = endDate - 7 * 3600 * 1000 * 24
        const temp = new Date(endDate)
        const tempStart = new Date(startDate)
        endDate = new Date(
          temp.getFullYear(),
          temp.getMonth(),
          temp.getDate(),
        ).getTime()
        startDate = new Date(
          tempStart.getFullYear(),
          tempStart.getMonth(),
          tempStart.getDate(),
        ).getTime()
      }
      break
    case 'today':
      {
        startDate = new Date(startDate)

        startDate = new Date(
          startDate.getFullYear(),
          startDate.getMonth(),
          startDate.getDate(),
        ).getTime()

        endDate = startDate + 1000 * 60 * 60 * 24
      }
      break
    case 'yesterday':
      {
        startDate = startDate - 1000 * 60 * 60 * 24
        startDate = new Date(startDate)
        startDate = new Date(
          startDate.getFullYear(),
          startDate.getMonth(),
          startDate.getDate(),
        ).getTime()
        endDate = new Date(endDate)
        endDate = new Date(
          endDate.getFullYear(),
          endDate.getMonth(),
          endDate.getDate(),
        ).getTime()
      }
      break
    default: {
      const noOfDays = parseInt(value)

      // startDate = startDate - noOfDays * 3600 * 1000 * 24
      // const temp = new Date(startDate)
      // startDate = new Date(
      //   temp.getFullYear(),
      //   temp.getMonth(),
      //   temp.getDate()
      // ).getTime()
      startDate = ''
      endDate = ''
    }
  }

  return {
    startDate,
    endDate,
  }
}

export const createBadge = (badgeStatus) => {
  let color = 'default'
  if (badgeStatus == REIMBURSEMENT_STATUS.approved) {
    color = 'success'
  } else if (badgeStatus == REIMBURSEMENT_STATUS.rejected) {
    color = 'error'
  }
  return <Chip size='small' label={(badgeStatus.toUpperCase())} color={color}></Chip>
}

export const leaveStatusColored = (status) => {
  let color = 'default'
  switch (status) {
    case LEAVE_STATUS.APPROVED:
      color = 'success'
      break
    case LEAVE_STATUS.REJECTED:
      color = 'error'
      break
    default:
      color = 'default'
      break
  }
  return color
}

export const numberToWords = (num) => {
  const ones = ['Zero', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine', 'Ten',
    'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'];
  const tens = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];
  const suffixes = ['', 'Thousand', 'Million', 'Crore'];

  function convertToWords(n) {
    if (n < 20) {
      return ones[n];
    } else if (n < 100) {
      return tens[Math.floor(n / 10)] + (n % 10 !== 0 ? '-' + ones[n % 10] : '');
    } else {
      return ones[Math.floor(n / 100)] + ' Hundred' + (n % 100 !== 0 ? ' and ' + convertToWords(n % 100) : '');
    }
  }

  if (num === 0) {
    return ones[0];
  }

  const parts = [];
  let i = 0;

  while (num > 0) {
    const chunk = num % 1000;
    if (chunk !== 0) {
      parts.unshift(convertToWords(chunk) + ' ' + suffixes[i]);
    }
    num = Math.floor(num / 1000);
    i++;
  }

  return parts.join(', ');
}


export const TaskStatus = (status) => {
  let color = 'default'
  switch (status) {
    case 'Pending':
      color = 'error'
      break
    case 'Approved':
      color = 'success'
      break
    default:
      color = 'default'
      break
  }
  return color
}
export const Priority = (status) => {
  let color = 'default'
  switch (status) {
    case 'High':
      color = 'error'
      break
    case 'Low':
      color = 'success'
      break
    default:
      color = 'default'
      break
  }
  return color
}
export const titleCase = (s) => {
  if (s)
    return s.replace(/^[-_]*(.)/, (_, c) => c.toUpperCase())       // Initial char (after -/_)
      .replace(/[-_]+(.)/g, (_, c) => ' ' + c.toUpperCase())

  return null
}
export const findObjectKeyByValue = (value, object) => {



  for (let val in object) {
    if (object[val] == value) {

      return titleCase(val)
      break
    }

  }
  return undefined
}
export const unEscapeStr = (htmlStr) => {
  if (htmlStr)
    return htmlStr
      .replace(/&amp;/g, "&")
      .replace(/%2f/g, "/")
      .replace(/&lt;/g, "<")
      .replace(/&gt;/g, ">")
      .replace(/&quot;/g, '"')
      .replace(/&#039;/g, "'")
      .replace(/&#x2F;/g, "/");
  else return htmlStr

}
export const setStringView = (str, dotAt = "mid") => {
  if (str && str != '') {
    const len = str.length
    if (len > 20) {
      if (dotAt == 'mid')
        return str.slice(0, 15) + "..." + str.slice(-3)
      else return str.slice(0, 15) + "..."
    }
    return str
  }
  return str
}
export const makeAmountString = (amount) => {
  if (amount >= 1000 && amount < 100000) {
    return (amount / 1000).toFixed(1) + "K"
  }

  if (amount >= 100000) {
    return (amount / 1000).toFixed(1) + "L"
  }
  return amount
}

export const calculateWorkingHours = (data) => {

  const DEFAULT_LOGOUT_TIME = 19

  let firstCheckIn = null

  let checkInLocation = null
  let checkoutLocation = null
  let lastCheckout = null


  let lastLog = null
  let lastLogTime = 0
  let milliSeconds = 0
  let breakMilliSeconds = 0
  data && Array.isArray(data) && data.sort((a, b) => moment(a.logTime).valueOf() - moment(b.logTime).valueOf()).forEach(
    (item) => {

      if ([CALENDAR_ITEM_TYPES.checkInLog, CALENDAR_ITEM_TYPES.checkOUTLog, CALENDAR_ITEM_TYPES.breakInLog, CALENDAR_ITEM_TYPES.breakOUTLog].includes(item.type)) {

        if (!firstCheckIn && item.type == CALENDAR_ITEM_TYPES.checkInLog) {
          firstCheckIn = item.logTime
          checkInLocation = item.location_id
        }

        if (item.type == CALENDAR_ITEM_TYPES.checkOUTLog) {
          lastCheckout = item.logTime
          checkoutLocation = item.location_id
        }
        if ((item.type == CALENDAR_ITEM_TYPES.breakInLog && lastLog == CALENDAR_ITEM_TYPES.checkInLog) || (item.type == CALENDAR_ITEM_TYPES.breakInLog && lastLog == CALENDAR_ITEM_TYPES.breakOUTLog)) {
          milliSeconds = milliSeconds + (moment(item.logTime).valueOf() - lastLogTime)
        }

        if (item.type == CALENDAR_ITEM_TYPES.checkOUTLog) {
          milliSeconds = milliSeconds + (moment(item.logTime).valueOf() - lastLogTime)
        }

        if (item.type == CALENDAR_ITEM_TYPES.breakOUTLog) {
          breakMilliSeconds = breakMilliSeconds + (moment(item.logTime).valueOf() - lastLogTime)
        }

        if(item.type== CALENDAR_ITEM_TYPES.checkInLog && lastLog==CALENDAR_ITEM_TYPES.checkInLog){
          milliSeconds = milliSeconds + (moment(item.logTime).valueOf() - lastLogTime)
        }
        lastLog = item.type
        lastLogTime = moment(item.logTime).valueOf()
      }
    }

  )

  if (lastLog == CALENDAR_ITEM_TYPES.breakInLog) {
    lastCheckout = moment(lastLogTime).toISOString()
    
    
    if (moment(lastLogTime).get('hours') < DEFAULT_LOGOUT_TIME)
      breakMilliSeconds = breakMilliSeconds + (moment().valueOf() - lastLogTime)
    else
      breakMilliSeconds = breakMilliSeconds + (moment(lastLogTime).set("hour", DEFAULT_LOGOUT_TIME).set("minute", 0).valueOf() - lastLogTime)


    checkoutLocation = null
  }
  let checkedOut = true
  if (lastLog == CALENDAR_ITEM_TYPES.breakOUTLog || lastLog == CALENDAR_ITEM_TYPES.checkInLog) {
    const date = moment(lastLogTime)
    const todaysDate = moment()

    checkedOut = false

    if (date.get("date") == todaysDate.get('date') && date.get("month") == todaysDate.get("month") && date.get("year") == todaysDate.get("year")) {
      milliSeconds = milliSeconds + (moment().valueOf() - lastLogTime)

      lastCheckout = null  //moment().toISOString()
      checkoutLocation = null

    } else {
      if (moment(lastLogTime).get('hours') < DEFAULT_LOGOUT_TIME) {
        milliSeconds = milliSeconds + (moment(lastLogTime).set("hour", DEFAULT_LOGOUT_TIME).set("minute", 0).valueOf() - lastLogTime)
        lastCheckout = moment(lastLogTime).set("hour", DEFAULT_LOGOUT_TIME).set("minute", 0).toISOString()
        checkoutLocation = null
      } else {

        // milliSeconds = milliSeconds + (moment(lastLogTime).valueOf() - lastLogTime)
        lastCheckout = moment(lastLogTime).toISOString()
        checkoutLocation = null
      }
    }

  }

  const hours = Math.floor((milliSeconds / (1000 * 60 * 60)))
  const minutes = Math.floor((milliSeconds - (hours * 60 * 60 * 1000)) / (1000 * 60))


  const breakHours = Math.floor((breakMilliSeconds / (1000 * 60 * 60)))
  const breakMinutes = Math.floor((breakMilliSeconds - (breakHours * 60 * 60 * 1000)) / (1000 * 60))

  return {
    breakHours,
    breakMinutes,
    hours,
    minutes,
    lastCheckout,
    firstCheckIn,
    checkInLocation,
    checkoutLocation,
    checkedOut
  }

}

export const interviewStatus = (status) => {
  let candidateStatus = ''
  switch(status) {
      case INTERVIEW_STATUS.SHORTLISTED :
          candidateStatus = "Shortlisted"
          break
      case INTERVIEW_STATUS.HOLD :
          candidateStatus = "Hold"
          break
      case INTERVIEW_STATUS.REJECTED :
          candidateStatus = "Rejected"
          break
      case INTERVIEW_STATUS.SELECTED :
          candidateStatus = "Selected"
          break
      case INTERVIEW_STATUS.CLOSED :
          candidateStatus = "Closed"
          break
      case INTERVIEW_STATUS.REFUSED_TO_ACCEPT :
          candidateStatus = "Refused To Accept"
          break
      case INTERVIEW_STATUS.NO_RESPONSE :
          candidateStatus = "No Response"
          break         
      default :
          candidateStatus=  "No Status"
          break
  }
  return candidateStatus
}