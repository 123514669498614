import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import AnimatedFireCrackers from './AnimatedFireCrackers';



export default function WishModal({ title = "Happy Birthday", subTitle = "Soham" }) {


    const [open, setOpen] = React.useState(true);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [textVisible, setTextVisible] = React.useState(false)
    React.useEffect(() => {
        setTimeout(() => {
            setTextVisible(true)
        }, 2500)
    }, [])



    return (

        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
       
        >
            <Box>
                <Box>
                    <AnimatedFireCrackers />
                </Box>
                <Box
                    sx={{ position: "fixed", top: "0px", zIndex: 111111, left: "0px", height: "100%", width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}
                >
                    {textVisible && <Box className="birthday-text" >
                        <Typography align='center' fontWeight="bold" color="light.main" variant='h1'>
                            {title}
                        </Typography>
                        <Typography mt={2} align='center' fontWeight="bold" color="light.main" variant='h1'>
                            {subTitle}
                        </Typography>
                    </Box>}
                </Box>
                <Button onClick={handleClose} variant='contained' color='error'
                    sx={{ position: "fixed", bottom: "10px", zIndex: 111111, left: "50%", transform: "translateX(-50%)" }}
                >
                    Close
                </Button>
            </Box>

        </Modal>

    );
}