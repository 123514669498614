import { actions } from "../../utils/constants"

const initialState = {
    users_data : {},
    users_loading : false,
    users_filters: {},

    interview_data : {},
    interview_loading : false,
    interview_filters: {},

    resignation_data : {},
    resignation_loading : false,
    resignation_filters: {},
}

const usersReducer = (state = initialState, action) => {
    switch(action.type) {

        case actions.START_USERS_LOADING : return {...state, users_loading : true, users_data : []};
        case actions.SET_USERS_DATA : return {...state, users_loading: false, users_data: action.value.data, users_filters : action.value.filters};

        case actions.START_INTERVIEW_LOADING : return {...state, interview_loading : true, interview_data : []};
        case actions.SET_INTERVIEW_DATA : return {...state, interview_loading: false, interview_data: action.value.data, interview_filters : action.value.filters};
        case actions.SET_INTERVIEW_DATA_ONUPDATE : return {...state, interview_data : action.value.data, interview_filters : action.value.filters}

        case actions.START_RESIGNATION_LOADING : return {...state, resignation_loading : true, resignation_data : []};
        case actions.SET_RESIGNATION_DATA : return {...state, resignation_loading: false, resignation_data: action.value.data, resignation_filters : action.value.filters};
        case actions.SET_RESIGNATION_DATA_ONUPDATE : return {...state, resignation_data : action.value.data, resignation_filters : action.value.filters}

        default : return {...state};
    }
}
 
export default usersReducer