import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux"
import { callApiAction } from "../../store/actions/commonAction";
import SettleAdvancePaymentsUI from "./SettleAdvancePaymentsUI"
import { closeModal } from "../../store/actions/modalAction";
import useValidate from "../../store/hooks/useValidator";
import { AddAmountAdvancePaymentApi, createAdvancePaymentApi, getAdvancePaymentsByIdApi, settleAdvancePaymentApi } from "../../apis/reimbersement.api";

const SettleAdvancePaymentController = ({ callBack, id }) => {

    const validate = useValidate()
    const dispatch = useDispatch()

    const [loading, setLoading] = useState(false)
    const [fetchLoading, setFetchLoading] = useState(false)
    const [state, setState] = useState(false)
    const [fields, setFields] = useState({
        id,
        remarks: ''
    })

    const validationSchema = useMemo(() => ([
        {
            required: true,
            value: fields.checked,
            field: ' Check Condition Box '
        },
        {
            required: true,
            value: fields.remarks,
            field: ' Remarks '
        }
    ]), [fields])

    const submitData = (e) => {
        e.preventDefault()
        const validationResponse = validate(validationSchema)

        if (validationResponse === true) {
            setLoading(true)
            dispatch(callApiAction(
                async () => await settleAdvancePaymentApi({ ...fields }),
                (response) => {
                    callBack(fields)
                    setLoading(false)
                    dispatch(closeModal())
                },
                (err) => {
                    setLoading(false)
                    setFields({ ...fields, err })
                }
            ))
        } else {
            setFields({ ...fields, 'err': validationResponse })
        }
    }

    const fetchById = () => {
        setFetchLoading(true)
        dispatch(callApiAction(
            async () => await getAdvancePaymentsByIdApi({ id }),
            (response) => {
                setState(response)

                setFetchLoading(false)
            },
            (err) => {
                setFetchLoading(false)
            }
        ))
    }

    useEffect(() => {
        fetchById()
    }, [id])
    return <SettleAdvancePaymentsUI
        state={state}
        fetchLoading={fetchLoading}
        loading={loading}
        onSubmit={submitData}
        fields={fields}
        setFields={setFields}
    />
}

export default SettleAdvancePaymentController;