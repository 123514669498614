import { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { callApiAction } from "../../../store/actions/commonAction"
import { createEmployeeType,deleteEmployeeType ,fetchEmployeeTypes} from "../../../apis/employeeType.api"
import EmployeeTypeUI from "./EmployeeTypeUI"
import UpdateEmployeeTypeButton from "../UpdateEmployeeTypeButton"
import DeleteEmployeeTypeButton from "../DeleteEmployeeTypeButton"
import { Box } from "@mui/material"
import { fetchEmployeeTypeDataAction } from "../../../store/actions/settingsAction"

const EmployeeTypeController = ({ userId }) => {
    const user = useSelector(state => state.user)
    const dispatch = useDispatch()

    const [state, setState] = useState([])
    const [loading, setLoading] = useState(false)
    const {settings} = useSelector((state) => state)
    
    const fetchEmployeeTypesApi = fetchEmployeeTypes
    

    const columns = useMemo(() => [
        { id: 1, fieldName: 'name', label: 'Name', align: "left", sort: true, minWidth: '150px' },
        // { id: 2, fieldName: 'address', label: 'Address', align: "left" },
        {
            id: 3, fieldName: 'action', label: 'Action', align: "left", renderValue: (params, setParams) => <Box sx={{ display: "flex" }}>
                <UpdateEmployeeTypeButton params={params} setParams={setParams} />

                <DeleteEmployeeTypeButton params={params} setParams={setParams} />

            </Box>
        },
    ], [dispatch]);
    const [filters, setFilters] = useState({
        pageSize: 10,
        pageNo: 1
    })


    const fetchList = () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await fetchEmployeeTypesApi(filters),
            (response) => {
                setState(response.result)
                // console.log(state)
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
            ))
    }

   
    const getEmployeeTypeList = () => {
        if (!settings.employeeType_data || settings.employeeType_data.length === 0 || JSON.stringify(filters)!=JSON.stringify(settings.employeeType_filters)) {
            dispatch(fetchEmployeeTypeDataAction(filters));
        }
    }
    useEffect(() => {
        getEmployeeTypeList()
        // fetchList()
    }, [filters])

    return <EmployeeTypeUI columns={columns} filters={filters} setFilters={setFilters} setState={setState} callBack={getEmployeeTypeList} loading={settings.employeeType_loading} state={settings.employeeType_data} />
}
export default EmployeeTypeController