import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";
import FeedbackUI from "./FeedbackFormUI";
import { Box } from "@mui/material";
import { fetchFeedback } from "../../apis/feedbackForm.api";
import UpdateFeedbackButton from "./UpdateFeedbackButton";

const FeedbackFormController = ({ userId }) => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [state, setState] = useState({});
  const [loading, setLoading] = useState(false);
  // const {settings} = useSelector((state) => state)

  const fetchFeedbackFormApi = fetchFeedback;

  const columns = useMemo(
    () => [
      {
        id: 1,
        fieldName: "name",
        label: "Name",
        align: "left",
        sort: true,
        minWidth: "150px",
      },
      {
        id: 2,
        fieldName: "title",
        label: "Title",
        align: "left",
        minWidth: "150px",
      },
      {
        id: 3,
        fieldName: "description",
        label: "Description",
        align: "left",
        minWidth: "150px",
      },
      {
        id: 4,
        fieldName: "feedbacktype",
        label: "FeedbackType",
        align: "left",
        renderValue: (params) => params.feedbacktype.map((item) => item.name),
      },
      // {
      //   id: 5,
      //   fieldName: "priority",
      //   label: "Priority",
      //   align: "left",
      //   sort: true,
      // },
      // {
      //   id: 6,
      //   fieldName: "action",
      //   label: "Action",
      //   align: "left",
      //   renderValue: (params, setParams) => (
      //     <Box sx={{ display: "flex" }}>
      //       <UpdateFeedbackButton params={params} setParams={setParams} />

      //       {/* <DeleteEmployeeTypeButton params={params} setParams={setParams} /> */}
      //     </Box>
      //   ),
      // },
    ],
    [dispatch]
  );
  const [filters, setFilters] = useState({
    pageSize: 10,
    pageNo: 1,
  });

  const fetchList = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await fetchFeedbackFormApi(filters),
        (response) => {
          setState(response);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    fetchList();
  }, [filters]);

  return (
    <FeedbackUI
      columns={columns}
      state={state}
      filters={filters}
      setFilters={setFilters}
      setState={setState}
      callBack={fetchList}
      loading={loading}
    />
  );
};
export default FeedbackFormController;
