export const mode = "development"; //process.env.REACT_APP_APP_MODE;
let domain = "";

switch (mode) {
  case "ip":
    domain = "http://10.10.81.11:8000/";
    break;
  case "local":
    domain = "http://localhost:8001/";
    break;
  case "development":
    domain = "https://payrollv2-development.up.railway.app/";
    break;
  case "production":
    domain = "https://payrollv2-production-6bc2.up.railway.app/";
    break;
  case "cosmo":
    domain = "https://payrollv2-production-fda2.up.railway.app/";
    break;
  case "localDevelopment":
    domain = "https://payrollv2-local-development.up.railway.app/";
    break;
  default:
    domain = "http://localhost:8000/";
}

export default {
  root: domain,
  getUserById: `${domain}api/task/getUserById`,
  signIn: `${domain}api/auth/login`,
  resetToken: `${domain}api/auth/reset-token`,
  resetPass: `${domain}api/auth/reset-pass`,
  documentBase: `${domain}api/document/`,

  advancePaymentBase: `${domain}api/advance-payment`,
  advancePaymentById: `${domain}api/advance-payment/id`,
  advancePaymentDownloadReport: `${domain}api/advance-payment/download-pdf`,
  settleAdvancePaymentBase: `${domain}api/advance-payment/settle`,
  userAndSystemFetchByToken: `${domain}api/user/fetch-by-token`,
  userBase: `${domain}api/user/`,
  userProfilePicture: `${domain}api/user/update-profile`,
  userSalaryStructure: `${domain}api/user/salary-structure`,
  userBirthdayBase: `${domain}api/user/birthday`,
  userPassword: `${domain}api/user/password`,
  userById: `${domain}api/user/fetch-by-id`,
  updateUserState: `${domain}api/user/enable-or-disable`,
  inOfficePresence: `${domain}api/user/inoffice`,
  leaveById: `${domain}api/leave/fetch-by-id/`,
  leaveBase: `${domain}api/leave/`,
  userProfileReport: `${domain}api/user/pdf`,
  salarySlipPdf: `${domain}api/salary/pdf`,

  wfhById: `${domain}api/wfh/by-id/`,
  wfhBase: `${domain}api/wfh/`,
  wfhYearly: `${domain}api/wfh/year`,
  wfhPending: `${domain}api/wfh/pending`,
  wfhMonthly: `${domain}api/wfh/month`,

  manualLeave: `${domain}api/leave/manual`,
  forwardLeaveBalance: `${domain}api/leave/forward-leave`,
  leaveCalendar: `${domain}api/leave/calendar`,
  pendingLeaveBase: `${domain}api/leave/pending`,
  coffById: `${domain}api/leave/coff/fetch-by-id/`,
  leaveCount: `${domain}api/leave/balance`,
  updateCoffStatus: `${domain}api/leave/send/coffstatus`,
  raiseLeaves: `${domain}api/leave/create`,
  coffBase: `${domain}api/leave/coff`,
  pendingCOff: `${domain}api/leave/pending/coff`,
  monthlyLeaves: `${domain}api/leave/fetch/monthly`,

  fetchOneMonthLogs: `${domain}api/log/fetch-one-month`,
  fetchDailyLogs: `${domain}api/log/fetch`,
  ignoreLateCheckin: `${domain}api/log/update-logs-status`,

  downloadLogs: `${domain}api/log/download`,

  coffs: `${domain}api/leave/coff`,
  reimberesementBase: `${domain}api/reimbersement/`,
  reimburesementPdf: `${domain}api/reimbersement/pdf`,
  reimberesementByEmployee: `${domain}api/reimbersement/employee`,
  reimberesementGeneral: `${domain}api/reimbersement/general`,
  pendingReimberesementBase: `${domain}api/reimbersement/pending`,
  reimberesementCount: `${domain}api/reimbersement/counts/`,
  reimberesementById: `${domain}api/reimbersement/id/`,
  reimburesementCSV: `${domain}api/downloadCsv`,

  notificationBase: `${domain}api/notification/`,
  notificationFetch: `${domain}api/notification/fetch`,

  holiday: `${domain}api/holiday`,

  salaryUpload: `${domain}api/salary/upload`,
  fetchSalary: `${domain}api/salary`,
  fetchAllUserSalary: `${domain}api/salary/all`,
  downloadSalaryCsv: `${domain}api/salary/calculate`,

  branch: `${domain}api/branch`,

  department: `${domain}api/department`,

  employeeType: `${domain}api/employee-type`,
  fileOrImage: `${domain}api/files`,
  image: `${domain}api/files/image`,
  file: `${domain}api/files/file`,

  createDailyUpdate: `${domain}api/dailyupdate/createDailyUpdate`,
  getAllDailyUpdates: `${domain}api/dailyupdate/getAllDailyUpdates`,
  getAllDailyUpdateOfMonth: `${domain}api/dailyupdate/month`,
  dailyUpdateBase: `${domain}api/dailyupdate/`,
  taskBase: `${domain}api/task/`,
  taskGeneral: `${domain}api/task/general`,
  taskCalendar: `${domain}api/task/calendar`,
  taskById: `${domain}api/task/id/`,
  taskPriority: `${domain}api/task/priority/`,
  taskSttaus: `${domain}api/task/status/`,
  taskPerformance: `${domain}api/task/performance/`,

  performenceEvaluation: `${domain}api/task/performenceEvaluation/`, //:id,
  performenceEvaluationWithId: `${domain}api/task/performenceEvaluationWithId/`, //:id,
  getReopenReport: `${domain}api/task/getReopenReport/`, //id
  getReopenReporttWithUserId: `${domain}api/task/getReopenReporttWithUserId/`, //id
  getReopenReoprtWithTaskId: `${domain}api/task/getReopenReoprtWithTaskId/`, //id
  GetAllReopenTasks: `${domain}api/task/GetAllReopenTasks`,
  getTaskCalender: `${domain}api/task/getTaskCalender`,
  getPerformenceCalender: `${domain}api/task/getPerformenceCalender`,
  getPerformenceCalenderWithId: `${domain}api/task/getPerformenceCalenderWithId/`,
  GetApprovedTaskOtherRoles: `${domain}api/task/GetApprovedTaskOtherRoles`,
  Mark_As_Completed_TasksOtherRoles: `${domain}api/task/Mark_As_Completed_TasksOtherRoles`,
  GetTodayDueTaskOtherRoles: `${domain}api/task/GetTodayDueTaskOtherRoles`,
  CurrentWeekTaskOtherRoles: `${domain}api/task/CurrentWeekTaskOtherRoles`,
  getAllMyTasksOtherRoles: `${domain}api/task/getAllMyTasksOtherRoles`,
  versions: `${domain}api/version`,
  latestVersions: `${domain}api/version/latest`,
  monthlyVisit: `${domain}api/v2/visit/monthly`,
  visitById: `${domain}api/v2/visit/by-id`,
  placeById: `${domain}api/v2/visit/places/by-id`,
  visitPlaces: `${domain}api/v2/visit/places`,
  visitedPlaces: `${domain}api/v2/visit/visited-place`,

  getInterviewCandidate: `${domain}api/interview-summary-sheet/hrdashboard`,
  getCandidateLink: `${domain}api/interview-summary-sheet/share`,
  assignInterviewDate: `${domain}api/interview-summary-sheet/assign-interview-date`,
  assignInterviewPanel: `${domain}api/interview-summary-sheet/assign-interviewrs`,
  updateInterviewStatus: `${domain}api/interview-summary-sheet/interview-logs`,
  fetchInterviewersName: `${domain}api/interview-summary-sheet/fetch-interviewers`,
  addCriteria: `${domain}api/interview-summary-sheet/candidate-status`,
  interviewReport: `${domain}api/interview-summary-sheet/pdf`,

  getExitUser: `${domain}api/resigned/fetch`,
  announcementFetch: `${domain}api/announcement/fetch-latest`,
  createAnnouncement: `${domain}api/announcement`,
  announcementBase: `${domain}api/announcement`,
  firstannouncementFetch: `${domain}api/read-late`,

  fetchTeamList: `${domain}api/user/fetch-user-public-details/`,
  fetchTeamMemberById: `${domain}api/user/fetch-user-public-by-id`,

  getDocumentList: `${domain}api/resigned/fetch`,
  updateResignationStatus: `${domain}api/resigned/update`,
  resignationPdf: `${domain}api/resigned/pdf`,
  closeResignedStatus: `${domain}api/resigned/close-resignation`,
  addEmpResignation: `${domain}api/resigned/create`,

  birthdayFetch: `${domain}api/user/latest-birthday`,

  addLeaveType: `${domain}api/leave-type/`,
  fetchLeaveType: `${domain}api/leave-type/fetch`,
  fetchByIdLeaveType: `${domain}api/leave-type/by-id`,
  updateLeaveType: `${domain}api/leave-type/update`,
  deleteLeaveType: `${domain}api/leave-type/delete`,

  feedbacktypeBase:`${domain}api/feedback-type`,
  feedbackBase: `${domain}api/feedback/`,

  HiddenBase:`${domain}api/user/hidden-or-not`
};
