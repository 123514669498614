const TASK_PRIORITY = {
    HIGH: 1,
    MEDIUM: 2,
    LOW: 3
}
const TASK_TYPES = {
    ONCE: "ONCE",
    DAILY: "DAILY",
    WEEKLY: "WEEKLY",
    MONTHLY: "MONTHLY"
}
const TASK_STATUS = {
    PENDING: 0,
    MARKED_AS_COMPLETED: 1,
    COMPLETED: 2,
    REJECTED: 3,

}

export { TASK_PRIORITY, TASK_TYPES, TASK_STATUS }