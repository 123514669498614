import { Outlet } from "react-router"
import EmployeeTaskButtons from "./EmployeeTaskButtons"
import { Box, Paper } from "@mui/material"
import BirthdayContainer from "../birthday/BirthdayContainer"

const TaskMain = () => {
    return <>
       
        <EmployeeTaskButtons />
        <Paper component={Box} mb={4} p={3} sx={(theme) => ({ border: "1px solid " + theme.palette.grey[500] })}>
            <Outlet />
        </Paper>
    </>
}
export default TaskMain