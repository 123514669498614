import { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { callApiAction } from "../../store/actions/commonAction"
import { createFeedbackType,fetchFeedbackType,updateFeedbackType } from "../../apis/feedbackType.api";

import FeedbackTypeUI from "./FeedbackTypeUI";
// import UpdateEmployeeTypeButton from "../UpdateEmployeeTypeButton"
// import DeleteEmployeeTypeButton from "../DeleteEmployeeTypeButton"
import { Box } from "@mui/material"
// import { fetchEmployeeTypeDataAction } from "../../../store/actions/settingsAction"

const FeedbackTypeController = ({ userId }) => {
    const user = useSelector(state => state.user)
    const dispatch = useDispatch()

    const [state, setState] = useState({})
    const [loading, setLoading] = useState(false)
    
    const fetchFeedbackTypesApi = fetchFeedbackType
    

    const columns = useMemo(() => [
        { id: 1, fieldName: 'name', label: 'Name', align: "left", sort: true, minWidth: '150px' },
        { id: 2, fieldName: 'priority', label: 'Priority', align: "left",sort:true },
        {
            id: 3, fieldName: 'action', label: 'Action', align: "left", renderValue: (params, setParams) => <Box sx={{ display: "flex" }}>
                {/* <UpdateEmployeeTypeButton params={params} setParams={setParams} />

                <DeleteEmployeeTypeButton params={params} setParams={setParams} /> */}

            </Box>
        },
    ], [dispatch]);
    const [filters, setFilters] = useState({
        pageSize: 10,
        pageNo: 1
    })


    const fetchList = () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await fetchFeedbackTypesApi(filters),
            (response) => {
                setState(response)
                console.log("opop",response)
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
            ))
    }

    useEffect(() => {
        fetchList()
    }, [filters])

    return <FeedbackTypeUI columns={columns} filters={filters} state={state} setFilters={setFilters} setState={setState} callBack={fetchList} loading={loading}  />
}
export default FeedbackTypeController