
import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Paper,
} from "@mui/material";
import {  memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {  useLocation} from "react-router-dom";
import defineRoutes from "../../../routes";
import { useNavigate } from "react-router-dom";


const MobileNav = () => {
  const { user } = useSelector((state) => state);
  const routes = defineRoutes(user);
  const location = useLocation();

  
  const navigate = useNavigate()

  const [val, setVal] = useState(0)


  useEffect(() => {
    if (location.pathname.search("/dashboard") != -1) {
      setVal(0)
    }
    else if (location.pathname.search("/calendar") != -1) {
      setVal(2)
    }
    else if (location.pathname.search("/tasks") != -1) {
      setVal(1)
    }
    else if (location.pathname.search("/reimburesement") != -1) {
      setVal(3)
    } else {
      setVal(-1)
    }
  }, [location.pathname])
  return (

    <Box sx={{ width: "100%", background: "white", position: "relative" }} component={Paper} elevation={4} >
      <BottomNavigation
        showLabels
        value={val}
        sx={{ width: '100%' }}
        textColor="primary.main"

        onChange={(event, newValue) => {
          setVal(newValue)
          
          navigate("/"+event.currentTarget.getAttribute('route'))
        }}
      >
        {routes &&
          Array.isArray(routes) &&
          routes.filter(item => !(item.hideInPannel || item.hideInMobile)).map((route, index) => {
            return (
              <BottomNavigationAction
                value={index}
                route={ route.path}
                // LinkComponent={Link}
                // to={"/" + route.path}
                key={route.path} label={route.mobileTitle ?? route.title} icon={route.icon} />
            );
          })}

      </BottomNavigation>
    </Box>
  );
};
export default memo(MobileNav);
