import { actions } from "../../utils/constants";


const initialState = {
    loading: false,
    data: {},
    date : null,
    userId : '',
   

}
const leaveBalanceReducer = (state = initialState, action) => {
    
    switch (action.type) {        
        case actions.START_LEAVE_BALANCE_COUNT_LOADING: return { ...state, loading: true, data: [] };
        case actions.SET_LEAVE_BALANCE_COUNT_DATA: return { ...state, loading: false, data: action.value.data, date : action.value.date, userId : action.value.userId };
        default: return { ...state }
    }

}
export default leaveBalanceReducer