import {
  Box,
  Button,
  Typography,
} from '@mui/material'

import {
  Add,
} from '@mui/icons-material'
import AddHolidayDataController from './AddHolidayDataController'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { openModal } from '../../store/actions/modalAction'
import { DatePicker } from '@mui/x-date-pickers'
import CustomInput from '../../components/inputs/CustomInput'
import DataTable from '../../components/tables/DataTable'

const HolidayUI = ({ setState, callBack, fields, setFields, loading, state, columns }) => {
  const dispatch = useDispatch()
  const addholiday = useCallback(() => {
    dispatch(openModal({
      title: "Add Holiday",
      component: <AddHolidayDataController callBack={callBack} />, size: 'sm'
    }))
  }, [])
  return (
    <>
    
      <Box m={3}>
        <Box>
          <Typography
            mb={2}
            sx={{
              fontWeight: 700,
              fontSize: '24px',
              lineHeight: '40px',
              color: '#0D0D0D',
            }}
          >
            Company Holiday
          </Typography>
          <Button
            onClick={addholiday}
            sx={{
              display: 'flex',
              height: '100%',
              zIndex: 0,
              textTransform: 'capitalize',
              boxShadow: 'none',
              border: '1px solid #393939',
              width: '100%',
              height: '56px',
              ':hover': {
                boxShadow: 'none',
              },
            }}
          >
            <Add color="primary" /> &nbsp;Add Holiday
          </Button>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Box>
            <DatePicker margin="dense" label="Year" renderInput={(props) => <CustomInput {...props} />} views={['year']} value={fields.year} onChange={(e) => setFields({ ...fields, year: e })} ></DatePicker>
          </Box>
        </Box>
        <Box sx={{ minHeight: "300px" }}>
          <DataTable columns={columns} rows={state} count={state?.length} filters={fields} setFilters={setFields} loading={loading} />
        </Box>

      </Box>
    </>
  )
}
export default HolidayUI
