import moment from "moment"
import { memo, useCallback, useEffect, useState } from "react"
import MyLeaveUi from "./MyLeaveUi"

const MyLeaveController = () => {
    const [date, setDate] = useState(moment())
    // const data = [
    //     {
    //         title: "Today",
    //         start: new Date(),
    //         end: new Date()
    //     },
    //     {
    //         title: "Tommorrw",
    //         start: new Date(2023, 1, 28),
    //         end: new Date(2023, 1, 28)
    //     },

    //     {
    //         title: "Tommorrw2",
    //         start: new Date(2023, 1, 28),
    //         end: new Date(2023, 1, 28)
    //     }
    // ]
 
    return (
        <>
            <MyLeaveUi date={date} setDate={setDate}  />
        </>
    )
}
export default memo(MyLeaveController)