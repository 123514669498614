import { memo, useEffect, useMemo, useState } from "react"
import useValidate from "../../../store/hooks/useValidator"

import { useDispatch, useSelector } from 'react-redux'
import { callApiAction } from "../../../store/actions/commonAction"

import { closeModal } from "../../../store/actions/modalAction"
import { raiseCoffApi, raiseLeaveApi } from "../../../apis/leave.api"
import moment from "moment"
import { HALF_TYPE, HALF_TYPE_OBJ } from "../../../utils/constants"
import CoffRaiseUi from "./CoffRaiseUi"
import { useNavigate } from "react-router-dom"

const CoffRaiseController = ({ userId, weeklyOf, callBack = () => { } }) => {

    const validate = useValidate()
    const dispatch = useDispatch()
    const { leaveBalance, holiday, user } = useSelector(state => state)

    const disableDates = (date) => {
        let isHoliday = false
        if (holiday.data && Array.isArray(holiday.data)) {
            for (let i = 0; i < holiday.data.length; i++) {
                const item = holiday.data[i]
                const incomingDate = moment(item.date)
                isHoliday =
                    incomingDate.get('dates') == date.get('dates') &&
                    incomingDate.get('months') == date.get('months') &&
                    incomingDate.get('years') == date.get('years')
                if (isHoliday) break
            }
        }
        let isWeeklyOf = false
        if (!isHoliday) {

            if (weeklyOf && Array.isArray(weeklyOf) && weeklyOf.includes(date.day())) {
                isWeeklyOf = true
            } else if (user.data && user.data.weekly_of && Array.isArray(user.data.weekly_of) && user.data.weekly_of.includes(date.day())) {
                isWeeklyOf = true
            }
        }
        return !(isWeeklyOf || isHoliday)
    }


    const [loading, setLoading] = useState(false)

    const [fields, setFields] = useState({
        err: '',
        applicationDate: moment(),
        userId: userId,
        remarks: "",
        date: moment(),
        
        time_duration: HALF_TYPE_OBJ.FULL_DAY
    })


    const validationSchema = useMemo(() => ([


        {
            required: true,
            value: fields.date,
            field: 'Comp-Off  Date ',
            custom: () => {
                return !disableDates(fields.date)
            }
        },

        {
            required: true,
            value: fields.remarks,
            field: 'Remarks',
        }
    ]), [fields])

    const navigate = useNavigate()

    const onSubmit = async (e) => {
        e.preventDefault()

        const validationResponse = validate(validationSchema)

        if (validationResponse === true) {

            const leaveData = { ...fields }


            leaveData.date = fields.date.format("YYYY-MM-DD")

            setLoading(true)
            dispatch(
                callApiAction(
                    async () => await raiseCoffApi(leaveData),
                    async (response) => {

                        await callBack()
                        setLoading(false)
                        navigate("/calendar/")
                    },
                    (err) => {
                        setLoading(false)
                        setFields({ ...fields, err })
                    }
                )
            )
        } else {
            setFields({ ...fields, 'err': validationResponse })
        }
    }
    
    return <CoffRaiseUi loading={loading} fields={fields} onSubmit={onSubmit} disableDates={disableDates} setFields={setFields} />
}
export default memo(CoffRaiseController)