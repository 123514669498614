import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { callSnackBar } from "../../store/actions/snackbarAction"
import { callApiAction } from "../../store/actions/commonAction"
import WFHDetailsUi from "./WFHDetailsUi"
import { SNACK_BAR_VARIETNS } from "../../utils/constants"

import { fetchWFHByIdDataApi } from "../../apis/wfh.api"

const WFHDetailsController = ({ id, callBack = () => { } }) => {
    const dispatch = useDispatch()

    const [list, setList] = useState({})
    const [loading, setLoading] = useState(false)
    const [filters, setFilters] = useState({})
    const fetchList = async () => {
        setLoading(true)
        dispatch(
            callApiAction(
                async () => await fetchWFHByIdDataApi({ id }),
                async (response) => {
                    setLoading(false)
                    setList(response)

                },
                (err) => {
                    setLoading(false)


                    setList({})
                    dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error))
                },
            ),
        )

    }
    useEffect(() => {
        fetchList()
    }, [])
    return <>

        <WFHDetailsUi
            filters={filters}
            setFilters={setFilters}
            setList={setList}
            callBack={callBack}
            list={list}
            loading={loading}
        />

    </>
}
export default WFHDetailsController