import {
  Autocomplete,
  Paper,
  Typography,
  Button,
  ButtonGroup,
} from "@mui/material";
import { Box } from "@mui/system";
import { useSelector } from "react-redux";
import SubmitButton from "../../components/button/SubmitButton";
import { StyledSearchBar } from "../../components/inputs/SearchBar";

import { USER_ROLES } from "../../utils/constants";
import DataTable from "../../components/tables/DataTable";

const UserPageUi = ({
  listLoading,
  filters,
  setFilters,
  list,
  columns,
  onCreateBtnClick,
  fetchList,
  exportLoading,
}) => {
  const user = useSelector((state) => state.user);

  return (
    <>
      <Box mt={3} mb={3}>
        <Paper elevation={2} sx={{ width: "100%", padding: 4 }}>
          <Box
            mb={4}
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h3">Users</Typography>
            <Box
              pl={2}
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <StyledSearchBar
                placeholder={"Search Name | Email | Usercode"}
                value={filters.search}
                onChange={(e) => {
                  setFilters({ ...filters, search: e.target.value });
                }}
              />

              {user.data &&
                (user.data.role === USER_ROLES.admin ||
                  user.data.role === USER_ROLES.hr) && (
                  <>
                    <Box pl={2} sx={{ display: "flex" }}>
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        onChange={(e, newVal) => {
                          setFilters({
                            ...filters,
                            role: parseInt(newVal._id),
                          });
                        }}
                        options={[
                          { label: "All", _id: null },
                          ...Object.keys(USER_ROLES).map((key) => ({
                            label: key,
                            _id: USER_ROLES[key],
                          })),
                        ]}
                        sx={{
                          width: 300,
                          display: "flex",
                          "*": { display: "flex", justifyContent: "center" },
                        }}
                        renderInput={(params) => (
                          <StyledSearchBar
                            placeholder="Select Role"
                            {...params}
                          />
                        )}
                      />
                    </Box>

                    <Box pl={2} sx={{ display: "flex" }}>
                      <SubmitButton
                        variant="contained"
                        onClick={onCreateBtnClick}
                        title={"Create User"}
                      />
                    </Box>
                  </>
                )}
            </Box>
          </Box>
          <Box sx={{ display: "flex" }}>
            <Box sx={{ display: "flex" }} mt={2} mb={4}>
              {user.data.role != USER_ROLES.employee && (
                <Box>
                  {" "}
                  <SubmitButton
                    loading={exportLoading}
                    variant="contained"
                    onClick={() => fetchList(true)}
                    title={"Export"}
                  />
                </Box>
              )}
            </Box>

            <Box
            mt={2}
              mb={4}
              sx={{
                display: "flex",
                flex: 1,
                justifyContent: "flex-end",
              }}
              // pl={3}
            >
              <ButtonGroup disableElevation variant="outlined">
                <Button
                  onClick={() => setFilters({ ...filters, enabled: null })}
                  variant={filters.enabled == null ? "contained" : "outlined"}
                >
                  Active User
                </Button>
                <Button
                  onClick={() => setFilters({ ...filters, enabled: false })}
                  variant={filters.enabled == false ? "contained" : "outlined"}
                >
                  Inactive User
                </Button>
              </ButtonGroup>
            </Box>
          </Box>
          <Box sx={{ minHeight: "300px" }}>
            <DataTable
              columns={columns}
              rows={list.result ? list.result : []}
              count={list.total ?? 0}
              filters={filters}
              setFilters={setFilters}
              loading={listLoading}
            />
          </Box>
        </Paper>
      </Box>
    </>
  );
};
export default UserPageUi;
