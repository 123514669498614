import { useDispatch, useSelector } from "react-redux"
import SubmitButton from "../../../components/button/SubmitButton"
import { SNACK_BAR_VARIETNS, USER_ROLES } from "../../../utils/constants"
import { useState } from "react"
import fileDownload from "js-file-download"
import moment from "moment"
import { callApiAction } from "../../../store/actions/commonAction"
import { downloadLogSheetApi } from "../../../apis/leave.api"
import { callSnackBar } from "../../../store/actions/snackbarAction"

const DownloadLogsCsvButton = ({date}) => {
    const dispatch = useDispatch()
    const { user } = useSelector(state => state)
    const [loading, setLoading] = useState()
    const fetchList = () => {
        
            setLoading(true)
            dispatch(callApiAction(
                async () => await downloadLogSheetApi({ date:date.toISOString() }),
                (response) => {
                    fileDownload(response, moment(date).format("MMMM")+"-logs.csv")
                    setLoading(false)
                },
                (err) => {
                    dispatch(callSnackBar(err,SNACK_BAR_VARIETNS.error))
                    setLoading(false)
                },
                true
            ))
        
    };
    if (user.data.role == USER_ROLES.hr || user.data.role == USER_ROLES.admin)
        return <>
            <SubmitButton
            onClick={fetchList}
            loading={loading}
                title={"Download Report"}
            />
        </>
}
export default DownloadLogsCsvButton