import { act } from "react-dom/test-utils";
import { actions } from "../../utils/constants"

const initialState = {
    holiday_data : {},
    holiday_loading : false,
    holiday_filters : {},

    document_data : {},
    document_loading : false,
    document_filters : {},

    branch_data : {},
    branch_loading : false,
    branch_filters : {},

    department_data : {},
    department_loading : false,
    department_filters : {},

    employeeType_data : {},
    employeeType_loading : false,
    employeeType_filters : {},

    version_data : {},
    version_loading : false,
    version_filters : {},
}

const settingsReducer = (state = initialState, action) => {
  switch(action.type) {

    case actions.START_HOLIDAY_LOADING : return {...state, holiday_loading : true, holiday_data: []};
    case actions.SET_HOLIDAY_DATA : return {...state, holiday_loading : false, holiday_data: action.value.data, holiday_filters : action.value.filters};
    case actions.SET_HOLIDAY_DATA_ONDELETE : return {...state, holiday_data : action.value.data, holiday_filters : action.value.filters};

    case actions.START_DOCUMENT_LOADING : return {...state, document_loading : true, document_data: []};
    case actions.SET_DOCUMENT_DATA : return {...state, document_loading : false, document_data: action.value.data, document_filters : action.value.filters};
    case actions.SET_DOCUMENT_DATA_ONDELETE : return {...state, document_data : action.value.data, document_filters : action.value.filters};

    case actions.START_BRANCH_LOADING : return {...state, branch_loading : true, branch_data: []};
    case actions.SET_BRANCH_DATA : return {...state, branch_loading : false, branch_data: action.value.data, branch_filters : action.value.filters};
    case actions.SET_BRANCH_DATA_ONDELETE : return {...state, branch_data : action.value.data, branch_filters : action.value.filters};

    case actions.START_DEPARTMENT_LOADING : return {...state, department_loading : true, department_data: []};
    case actions.SET_DEPARTMENT_DATA : return {...state, department_loading : false, department_data: action.value.data, department_filters : action.value.filters};
    case actions.SET_DEPARTMENT_DATA_ONDELETE : return {...state, department_data : action.value.data, department_filters : action.value.filters};

    case actions.START_EMPLOYEE_TYPE_LOADING : return {...state, employeeType_loading : true, employeeType_data: []};
    case actions.SET_EMPLOYEE_TYPE_DATA : return {...state, employeeType_loading : false, employeeType_data: action.value.data, employeeType_filters : action.value.filters};
    case actions.SET_EMPLOYEE_TYPE_DATA_ONDELETE : return {...state, employeeType_data : action.value.data, employeeType_filters : action.value.filters};

    case actions.START_VERSION_LOADING : return {...state, version_loading : true, version_data: []};
    case actions.SET_VERSION_DATA : return {...state, version_loading : false, version_data: action.value.data, version_filters : action.value.filters};
    case actions.SET_VERSION_DATA_ONDELETE : return {...state, version_data : action.value.data, version_filters : action.value.filters};

    default : return {...state}
  }
}

export default settingsReducer