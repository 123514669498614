import {
  Add,
  CampaignOutlined,
  EventAvailableOutlined,
} from '@mui/icons-material'
import { Box, Button, Grid, Paper, styled } from '@mui/material'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { openModal } from '../../store/actions/modalAction'
import CoffRaiseController from './leaveRaise/CoffRaiseController'
import LeaveRaiseController from './leaveRaise/LeaveRaiseController'
import SummaryMainDialog from './leaveSummary/SummaryMainDialog'
import { center } from '../../assets/css/theme/common'
import { Link } from 'react-router-dom'
import { ActionButton } from '../../components/button/SubmitButton'
import WFHRaiseButton from '../wfh/WFHRaiseButton'

const LeaveButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  width: '33.33%',
  // height: '50px',
  // zIndex: 0,
  // textTransform: 'capitalize',
  // boxShadow: 'none',
  // border: '1px solid #393939',
  // ':hover': {
  //   boxShadow: 'none',
  // },
}))

const LeaveBtnBox = {
  width: '100%',
  
  display: 'flex',
  gap: '14px',
  
  // height: "100%"
}

const LeaveButtons = ({ userId,modal }) => {
  const dispatch = useDispatch()
  // const onRaiseLeaveClick = useCallback(() => {
  //     dispatch(openModal(<LeaveRaiseController userId={userId} />, 'lg'))
  // }, [userId])
  const onSummaryClick = useCallback(() => {
      dispatch(openModal({component:<SummaryMainDialog userId={userId} />, size:"md",title:"Summary"}))
  }, [userId])
  // const onCoffClick = useCallback(() => {
  //     dispatch(openModal(<CoffRaiseController userId={userId} />, 'lg'))
  // }, [userId])
  return (
    <>
      <Box sx={LeaveBtnBox}>

       {!modal &&<><ActionButton          
          variant="contained"
          sx={{ borderRadius: '4px' }}
          LinkComponent={Link}
          icon={ <CampaignOutlined color="inherit" />}
          title={"Apply Leave"}
          to="rais-leave"
        >
          
        </ActionButton>
        <ActionButton
          
          variant="contained"
          sx={{ borderRadius: '4px' }}
          LinkComponent={Link}
          to="comp-off"
          icon={<Add color="inherit" />}
          title='Add Comp-off'
        >
           
        </ActionButton>
        <WFHRaiseButton />
        <ActionButton
          
          variant="contained"
          sx={{ borderRadius: '4px' }}
          LinkComponent={Link}
          to="summary"
          icon={ <EventAvailableOutlined color="inherit" />}
          title={'Summary'}
        >
        
        </ActionButton>
        </> }
        
       {modal &&
         <ActionButton
          
         variant="contained"
         sx={{ borderRadius: '4px' }}
         
         
         icon={ <EventAvailableOutlined color="inherit" />}
         title={'Summary'}
         onClick={onSummaryClick}
       >
       
       </ActionButton>
       }
      </Box>
    </>
  )
}
export default LeaveButtons
