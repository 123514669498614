
// export const INQUIRY_TYPES = {
//     directCustomer: "directCustomer",
//     storeCustomer: "storeCustomer",
//     storeReview: "storeReview",
//     storeManager: "storeManager",
// };

// export const INQUIRY_TYPES_NAME = {
//     directCustomer: "Direct Customer",
//     storeCustomer: "Store Customer",
//     storeReview: "Store Review",
//     storeManager: "Store Manager",
// };


// export const VISIT_TYPES = {
//     customer: "customer",
//     store: "store",
// };

// export const VISIT_STATUS = {
//     started: "started",
//     ended: "ended",
// };

// export const VISIT_FETCH_TYPE = {
//     byDate: "byDate",
//     byVisitType: "byVisitType",
//     fetchStatus: "fetchStatus",
// };

// export const PRODUCT_TYPE = {
//     WeHearOX: "WeHear OX",
//     HearNU: "HearNU",
//     SAFE: "SAFE",
//     WeHear2: "WeHear 2.0",
// };


export const VISIT_TYPES = {
    STORE: 1,
    DOCTOR: 2,
    CUSTOMER: 3
}
export const VISIT_TYPE_NAMES = {
     1:"STORE",
     2:"DOCTOR",
     3:"CUSTOMER"
}
export const VISIT_COLORS = {
    1:"#5E8233",
    2:"#0BABAF",
    3:"#D78329"
    // customer: '#5E8233',
    // store: "#D78329",
}
export const VISIT_SUB_TYPES = {
    STORE_REVIEW: 1,
    MANAGER_MEET: 2,
    CUSTOMER: 3,
    DOCTOR_MEET: 4,
    PATIENT: 5
}
export const VISIT_SUB_TYPE_NAMES = {
    1:'Store Review',
    2:'Manager Meet',
    3:'Customer',
    4:'Dcotor Meet',
    5:'Patient'
}
export const VISIT_STATUS = {
    OPEN: 1,
    CLOSE: 2
}
export const CUSTOMER_STATUS = {
    NONE:0,
    NOT_INTERESTED: 1,
    PURCHASED: 2,
    INTERESTED: 3,
    GIVEN_FOR_DEMO:4

}

export const VISIT_PRODUCTS = {
    OX: 1,
    HEAR_NU: 2,
    SAFE: 3,
    OX_2: 4,
    BTE_PRIME: 5,
    BTE_OPTIMA: 6
}

