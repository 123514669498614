import { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getUserByIdApi, updateUserField } from "../../apis/user.api"
import { callApiAction } from "../../store/actions/commonAction"
import { loggedInUser } from "../../utils/helper"
import ProfileUi from "./ProfileUi"
import { getFileOrImage } from "../../apis/file.api"


//userId props will be passed when it will be opened inside modal in user View module

const ProfileController = ({ userId , isFromViewMore}) => {
    const user = useSelector(state => state.user)
    const dispatch = useDispatch()

    const [state, setState] = useState(null)
    const [loading, setLoading] = useState(false)
    const onUpdateValue = () => {

    }

    const fetchList = () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await getUserByIdApi({ userId }),
            (response) => {
                setState(response)
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
    }
    


    const [fieldLoading, setFieldLoading] = useState({
        fieldName: "",
        loading: false
    })

    const onUpdateField = (fieldName, value, updateType = 'default') => {
        setState({ ...state, [fieldName]: value })
        setFieldLoading({
            fieldName,
            loading: true
        })
        let changedVal = value
        if (value && typeof value === "object") {
            changedVal = value['_id']
        }
        dispatch(callApiAction(
            async () => await updateUserField({ userId, [fieldName]: changedVal, updateType }),
            (response) => {
                setFieldLoading({
                    fieldName: "",
                    loading: false
                })

            },
            (err) => {
                setFieldLoading({
                    fieldName: "",
                    loading: false
                })
            }
        ))

    }

    const getFile = (url) => {
        // const Url = url.split("&#x2F;");
        // let fileUrl = "http://localhost:8000/UploadFile/"
        // fileUrl += Url[Url.length - 1]
        // console.log({ fileUrl })
        setLoading(true)
        dispatch(callApiAction(
            async () => await getFileOrImage({ fileUrl:url }),
            (response) => {
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
    }
    //=== above function is for uodating induvidual field
    //=== below function is for updating more than one fields
    const onUpdateData = (data = {}, updateType, callBack = () => { }, fallBack = () => { }) => {
        dispatch(callApiAction(
            async () => await updateUserField({ userId, ...data, updateType }),
            (response) => {
                callBack(response)
            },
            (err) => {
                fallBack(err)
            }
        ))
    }
    useEffect(() => {
        // if (userId) {
        //     fetchList()
        // } else {
        //     setState(user.data)
        // }
        fetchList()
    }, [])
    return <ProfileUi setState={setState} loading={loading} state={state} onUpdateData={onUpdateData} onUpdateField={onUpdateField} fieldLoading={fieldLoading} isFromModal={userId} onUpdateValue={onUpdateValue} getFile={getFile} isFromViewMore={isFromViewMore}/>
}
export default ProfileController