import { Delete } from "@mui/icons-material"
import { CircularProgress, IconButton } from "@mui/material"
import { useState } from "react"
import { deleteHoliday } from "../../apis/holiday.api"
import { callApiAction } from "../../store/actions/commonAction"
import { callSnackBar } from "../../store/actions/snackbarAction"
import { SNACK_BAR_VARIETNS } from "../../utils/constants"
import { useDispatch, useSelector } from "react-redux"
import { setHolidayDataOnDeleteAction } from "../../store/actions/settingsAction"

const DeleteHolidayButton = ({ params, setParams }) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState()
    const {settings} = useSelector((state) => state)
    const onClick = () => {
        setLoading(true);
        dispatch(
            callApiAction(
                async () => await deleteHoliday({ id: params._id }),
                (response) => {
                    setLoading(false);
                    setParams({})
                    const updatedData = settings.holiday_data.filter(item => item._id != params._id)
                    dispatch(setHolidayDataOnDeleteAction(updatedData, settings.holiday_filters))
                    dispatch(callSnackBar("Holiday Deleted", SNACK_BAR_VARIETNS.suceess))
                },
                (err) => {
                    setLoading(false);
                    dispatch(callSnackBar("Can not delete Holiday", SNACK_BAR_VARIETNS.error))
                }
            )
        );
    }
    if (loading)
        return <CircularProgress />
    return <IconButton onClick={onClick}>
        <Delete color="error" />
    </IconButton>
}
export default DeleteHolidayButton