import { memo, useEffect, useMemo, useState } from 'react'
import useValidate from '../../../store/hooks/useValidator'

import { useDispatch, useSelector } from 'react-redux'
import { callApiAction } from '../../../store/actions/commonAction'

import moment from 'moment'

import { useNavigate } from 'react-router-dom'
import TaskCreateUi from './TaskCreateUi'
import { TASK_PRIORITY, TASK_STATUS, TASK_TYPES } from '../../../utils/task.constants'
import { createTaskApi } from '../../../apis/task.api'
import { callSnackBar } from '../../../store/actions/snackbarAction'
import { SNACK_BAR_VARIETNS } from '../../../utils/constants'

const TaskCreateController = ({ callBack = () => { } }) => {
  const validate = useValidate()
  const dispatch = useDispatch()

  const { leaveBalance, holiday, user } = useSelector((state) => state)
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  const [fields, setFields] = useState({
    err: '',
    start_date: moment(),
    due_date: moment(),
    name: "",
    description: "",
    assigned_to: null,
    priority: TASK_PRIORITY.MEDIUM,
    type: TASK_TYPES.ONCE,
    till_date: moment()

  })

  const weeklyOf = user.data.weekly_of

  const validationSchema = useMemo(
    () => [
      {
        required: true,
        value: fields.name,
        field: 'Task Name ',
      },
      {
        required: true,
        value: fields.start_date,
        field: 'Start Date ',
        custom: () => {
          return !disableDates(fields.start_date)
        },
      },
      {
        required: true,
        value: fields.due_date,
        field: 'Due Date ',
        custom: () => {
          return !disableDates(fields.due_date)
        },
      },
      {
        required: true,
        value: fields.till_date,
        field: 'Assigned Till Date ',
        custom: () => {
          if (fields.type != TASK_TYPES.ONCE)
            return !disableDates(fields.till_date)
          else return true
        },
      },
      {
        required: true,
        value: fields.description,
        field: 'Task Description',
      },
      {
        required: true,
        value: fields.assigned_to,
        field: 'Assigned To ',
      },
    ],
    [fields],
  )

  const disableDates = (date) => {
    if (fields.type != TASK_TYPES.ONCE) {


      let isHoliday = false
      if (holiday.data && Array.isArray(holiday.data)) {
        for (let i = 0; i < holiday.data.length; i++) {
          const item = holiday.data[i]
          const incomingDate = moment(item.date)
          isHoliday =
            incomingDate.get('dates') == date.get('dates') &&
            incomingDate.get('months') == date.get('months') &&
            incomingDate.get('years') == date.get('years')
          if (isHoliday) break
        }
      }
      let isWeeklyOf = false
      if (!isHoliday) {

        if (weeklyOf && Array.isArray(weeklyOf) && weeklyOf.includes(date.day())) {
          isWeeklyOf = true
        } else if (user.data && user.data.weekly_of && Array.isArray(user.data.weekly_of) && user.data.weekly_of.includes(date.day())) {
          isWeeklyOf = true
        }
      }
      return isWeeklyOf || isHoliday
    } return false
  }

  const onSubmit = async (e) => {
    if (holiday.loading) {
      return
    }
    e.preventDefault()

    const validationResponse = validate(validationSchema)

    if (validationResponse === true) {
      const taskData = { ...fields }


      taskData.start_date = fields.start_date.valueOf()
      taskData.due_date = fields.due_date.valueOf()
      taskData.till_date = fields.till_date.valueOf()

      setLoading(true)
      dispatch(
        callApiAction(
          async () => await createTaskApi(taskData),
          async (response) => {

            setLoading(false)
            navigate("/tasks/my-tasks/")
            dispatch(callSnackBar("Task Assigned Success fully", SNACK_BAR_VARIETNS.suceess))
          },
          (err) => {
            setLoading(false)
            setFields({ ...fields, err })
            dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error))
          },
        ),
      )
    } else {
      setFields({ ...fields, err: validationResponse })
    }
  }






  return (
    <TaskCreateUi
      disableDates={disableDates}
      loading={loading}
      fields={fields}
      onSubmit={onSubmit}
      setFields={setFields}
    />
  )
}
export default memo(TaskCreateController)
