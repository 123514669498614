import * as React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Avatar, Badge, Box, ButtonBase, CircularProgress, IconButton, List, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Tooltip } from '@mui/material';
import { FolderShared, KeyboardArrowDown, NotificationsNone, PictureAsPdf } from '@mui/icons-material';
import { center } from '../../assets/css/theme/common';
import responsive from '../../assets/css/responsive';
import { useDispatch } from 'react-redux';
import { callApiAction } from '../../store/actions/commonAction';
import { fetchdocuments } from '../../apis/document.api';
import { CenteredBox } from '../../components/layouts/common/boxes';
import FileDownloadComponent from '../../components/FileDownloadComponent';

export default function CompanyDocuments() {
    const dispatch = useDispatch()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [loading, setLoading] = React.useState(false)
    const [state, setState] = React.useState([])

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const document = {
        backgroundColor: "#F2F2F2",
        borderRadius: "4px",
        padding: "12px 24px",
        ...responsive.document
    }
    const companyDocument = {
        ...responsive.companyDocument,
    }

    const fetchList = () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await fetchdocuments(),
            (response) => {
                setState(response)

                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
    }


    React.useEffect(() => {
        if (open && state.length == 0)
            fetchList()
    }, [open])
    return (
        <>

            <Tooltip title="Company documents" >
                <IconButton aria-describedby={id} onClick={handleClick}  >
                    <Typography variant='h2' >
                        <FolderShared />
                    </Typography>
                    {/* <Typography variant='h4' sx={companyDocument}>Company Documents</Typography>
                <KeyboardArrowDown color='red' /> */}
                </IconButton>
            </Tooltip>
            <Popover
                // sx={(theme) => ({ marginTop: theme.spacing(3) })}
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}

            >
                {
                    loading && state.length == 0 ? <CenteredBox sx={{ width: '380px', height: "180px", maxWidth: '100%', bgcolor: 'background.paper' }}>
                        <CircularProgress>

                        </CircularProgress>
                    </CenteredBox> :
                        <List

                            sx={{ width: '380px', maxWidth: '100%', bgcolor: 'background.paper' }}
                            component="nav"
                            aria-labelledby="nested-list-subheader"
                            subheader={
                                <ListSubheader sx={(theme) => ({ color: theme.palette.text.primary })} color='text.primary'>

                                    <Typography variant='h5'>
                                        Company Documents
                                    </Typography>

                                </ListSubheader>
                            }
                        >
                            {
                                state?.map((item) => {
                                    return <FileDownloadComponent downloadName={item.name + ".pdf"} src={item.url} key={item.name} Component={({ loading, ...props }) => {
                                        return <ListItemButton {...props} disabled={loading} >
                                            {loading ? <CircularProgress size={20} /> : <PictureAsPdf />}
                                            <Box ml={3} sx={{ flexDirection: "column", ...center, alignItems: "flex-start" }}>
                                                <Typography variant='h6' sx={{ lineHeight: "100%" }}>{item.name}</Typography>
                                            </Box>
                                        </ListItemButton>
                                    }} />
                                })
                            }
                            {/* <ListItemButton href='https://firebasestorage.googleapis.com/v0/b/wehear-ox.appspot.com/o/hrms%2FHoliday%20List%20-%202023%20(1).pdf?alt=media&token=0073ee32-f103-439e-a7c3-cec567085cbb' target="_blank" >

                        <PictureAsPdf />
                        <Box ml={3} sx={{ flexDirection: "column", ...center, alignItems: "flex-start" }}>
                            <Typography variant='h6' sx={{ lineHeight: "100%" }}>Company Holidays</Typography>
                        </Box>

                    </ListItemButton>

                    <ListItemButton href='https://firebasestorage.googleapis.com/v0/b/wehear-ox.appspot.com/o/hrms%2FWEHWAR%20EMPLOYEE%20LEAVE%20POLICY%20(3).pdf?alt=media&token=2e1b0114-78b2-4f5b-8f37-3d57ba46b8eb' target="_blank" >

                        <PictureAsPdf />
                        <Box ml={3} sx={{ flexDirection: "column", ...center, alignItems: "flex-start" }}>
                            <Typography variant='h6' sx={{ lineHeight: "100%" }}>Leave Policy</Typography>
                        </Box>

                    </ListItemButton>

                    <ListItemButton href='https://firebasestorage.googleapis.com/v0/b/wehear-ox.appspot.com/o/hrms%2FRevised%20Wehear%20General%20Policies%20WEF%201st%20Nov-2022%20(1).pdf?alt=media&token=1b1cee25-eb87-448f-91d6-d7f659bb3a95' target="_blank" >

                        <PictureAsPdf />
                        <Box ml={3} sx={{ flexDirection: "column", ...center, alignItems: "flex-start" }}>
                            <Typography variant='h6' sx={{ lineHeight: "100%" }}>Attendence Policy</Typography>
                        </Box>

                    </ListItemButton> */}



                        </List>
                }
            </Popover>
        </>
    );
}