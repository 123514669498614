import { combineReducers } from "@reduxjs/toolkit"
import modalReducer from "./modalReducer";
import userReducer from "./userReducer";
import leaveBalanceReducer from "./leaveBalanceReducer";
import notificationReducer from "./notificationReducer";
import holidayReducer from "./holidayReducer";
import snackBarReducer from "./snackbarReducer";
import selectedEmployeeReducer from "./selectedEmployeeReducer";
import announcementReducer from "./announcementReducer";
import birthdayReducer from "./birthdayReducer";
import reimbursementReducer from "./reimbursementReducer";
import usersReducer from "./usersReducer"
import teamReducer from "./teamReducer";
import settingsReducer from "./settingsReducer";
import leaveReducer from "./leaveReducer";


const rootReducer = combineReducers({
    
    user: userReducer,
    modal: modalReducer,
    leaveBalance: leaveBalanceReducer,
    notifications: notificationReducer,
    holiday: holidayReducer,    
    snackBar: snackBarReducer,
    selectedEmployee:selectedEmployeeReducer,
    announcements: announcementReducer,
    birthdays: birthdayReducer,
    reimbursement : reimbursementReducer,
    settings : settingsReducer,
    users : usersReducer,
    team : teamReducer,
    leave : leaveReducer,
})
export default rootReducer;