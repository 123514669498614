import {
  Box,

  Checkbox,
  Collapse,
  Grid,
  Skeleton,

  Typography,
  useTheme,
} from '@mui/material'
import { DesktopDatePicker } from '@mui/x-date-pickers'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import SubmitButton, { ResetButton } from '../../../components/button/SubmitButton'
import CustomInput from '../../../components/inputs/CustomInput'
import CustomRadioButtons from '../../../components/layouts/common/CustomRadioButtons'
import { HALF_TYPE, HALF_TYPE_OBJ, typeS } from '../../../utils/constants'

import { useNavigate } from 'react-router-dom'
import { fetchLeaveBalanceCountAction } from '../../../store/actions/leaveBalanceAction'
import { useEffect } from 'react'
import { LEAVE_NAMES, LEAVE_TYPE } from '../../../utils/leave.constants'

const LeaveBalanceComponent = ({ selectedLeave, leaveCount, userId, from }) => {

  const { leaveBalance } = useSelector(state => state)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchLeaveBalanceCountAction({ userId, date: from.valueOf() }))
  }, [userId, from.get("months")])

  const totalCount = (leaveBalance && leaveBalance.data && Array.isArray(leaveBalance.data) && leaveBalance.data.slice(0, 4).reduce((previosVal, item) => {
    return previosVal + item
  }, 0))

  return <Box sx={(theme) => ({ background: theme.palette.grey['calender'], border: (totalCount - leaveCount < 0) ? ("2px solid " + theme.palette.error.main) : "unset", borderRadius: 1 })} p={3} >
    <Typography variant='subtitle1' fontWeight="bold" mb={3}>
      Total leaves remaining (days)
    </Typography>

    <Box>
      {leaveBalance && leaveBalance.data && Array.isArray(leaveBalance.data) && leaveBalance.data.slice(0, 4).map((item, index) => {
        return (
          <Box key={LEAVE_NAMES[index].label} sx={{ display: "flex", flex: 1, alignItems: "center", justifyContent: "space-between", background: selectedLeave == LEAVE_NAMES[index].value ? "white" : "unset", transition: "all 0.1s linear", px: 2, transform: selectedLeave == LEAVE_NAMES[index].value ? "scaleY(1.2)" : "unset" }} mb={2}>
            <Typography variant='subtitle1' fontWeight={selectedLeave == LEAVE_NAMES[index].value ? "800" : "unset"}   >{LEAVE_NAMES[index].label}</Typography>
            <Typography variant='subtitle1' fontWeight="bold">{item}</Typography>
          </Box>
        )
      })}


      <Box sx={{ display: "flex", flex: 1, alignItems: "center", justifyContent: "space-between", borderTop: 1 }} pt={2} mb={2}>
        <Typography variant='subtitle1' color={totalCount - leaveCount < 0 ? "error" : "dark"} fontWeight="bold">Total</Typography>
        <Typography variant='subtitle1' color={totalCount - leaveCount < 0 ? "error" : "dark"} fontWeight="bold">{totalCount} - {leaveCount} = {(totalCount - leaveCount)}</Typography>
      </Box>


    </Box>
  </Box>
}

const LeaveRaiseUi = ({
  userId,
  modal,
  onSubmit,
  loading,
  fields,
  setFields,
  disableDates,
}) => {
  const { holiday } = useSelector((state) => state)

  const { leaveType, leaveBalance } = useSelector(state => state)
  const navigate = useNavigate()

  const theme = useTheme()

  const onFromDateChange = (e) => {
    const changedVal = {}
    changedVal['from'] = e
    if (fields.moreThanOneDay) {
      changedVal['to'] = moment(e).add(1, 'day')
    } else {
      changedVal['to'] = e
    }

    changedVal['type'] = null
    setFields({ ...fields, err: '', ...changedVal })
  }


  return (
    <Box p={modal ? 0 : 4} mt={1} component="form" onSubmit={onSubmit}>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", flexWrap: "wrap" }}>
        <Box >
          {!modal && <Typography variant='h2'>
            Leave Application
          </Typography>}
          <Typography variant='h6' color="error">
            {fields.err}
          </Typography>
        </Box>

        {!modal && <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <Typography variant='h3' color="grey.main">
            Application Date:
          </Typography >
          <Typography variant='h3' ml={2}>
            {moment().format("DD MMMM YYYY")}
          </Typography>
        </Box>}


      </Box>

      <Box sx={{ display: "flex" }} mt={!modal && 2}>
        <Box sx={{ display: "flex", flex: 1, flexDirection: "column", maxWidth: "100%" }}>
          <Typography variant='h3' mt={!modal && 3}>
            Leave Date:
          </Typography>


          <Box mt={2}>
            <Grid container spacing={2} >
              <Grid item xs={12} sm={4} >
                <DesktopDatePicker

                  shouldDisableDate={disableDates}
                  renderInput={(props) => {
                    return <CustomInput {...props} sx={{ height: '100%' }} />
                  }}
                  inputFormat="DD-MM-yyyy"
                  disabled={
                    loading ||
                    !(holiday.data && Array.isArray(holiday.data)) ||
                    holiday.loading
                  }
                  value={fields.from}
                  onChange={onFromDateChange}
                  type="date"
                />
              </Grid>
              <Grid item xs={12} sm={8} >
                <CustomRadioButtons
                  options={
                    !fields.moreThanOneDay
                      ? HALF_TYPE
                      : [HALF_TYPE[0], HALF_TYPE[2]]
                  }
                  value={fields.from_time_duration}
                  onChange={(e) => {
                    if (fields.moreThanOneDay) {
                      setFields({ ...fields, from_time_duration: e.target.value })
                    } else {
                      setFields({ ...fields, from_time_duration: e.target.value, to_time_duration: e.target.value })
                    }
                  }}
                  sx={{ height: '56px', marginTop: '7px' }}
                />
              </Grid>
            </Grid>
          </Box>

          <Box sx={{ display: 'flex', width: '100%', alignItems: 'center' }}>
            <Checkbox
              checked={fields.moreThanOneDay}

              disabled={
                loading ||
                !(holiday.data && Array.isArray(holiday.data)) ||
                holiday.loading
              }
              onChange={(e) => {
                const changedVal = {}
                changedVal['moreThanOneDay'] = e.target.checked

                if (
                  e.target.checked &&
                  fields.from_time_duration == HALF_TYPE_OBJ.FIRST_HALF
                ) {
                  changedVal['from_time_duration'] = HALF_TYPE_OBJ.SECOND_HALF
                }

                if (e.target.checked) {
                  changedVal['to'] = moment(fields.from).add(
                    1,
                    'days',
                  )
                } else {
                  changedVal['to'] = moment(fields.from)
                }

                changedVal['type'] = (fields.type == LEAVE_TYPE.MULTI_LEAVES ? null : LEAVE_TYPE.MULTI_LEAVES)


                if (e.target.checked) {
                  changedVal['to_time_duration'] = HALF_TYPE_OBJ.FULL_DAY
                } else {
                  changedVal['to_time_duration'] = changedVal['from_time_duration']
                }

                setFields({ ...fields, ...changedVal })
              }}
            />
            <Typography variant="h6">Leave more than one day</Typography>
          </Box>
          {
            <Collapse in={fields.moreThanOneDay}>
              <Box sx={{ display: 'flex', width: '100%' }}>
                <Grid container spacing={2} >
                  <Grid item xs={12} sm={4} >
                    <DesktopDatePicker
                      minDate={
                        fields.moreThanOneDay
                          ? moment(fields.from).add(1, 'days')
                          : undefined
                      }

                      maxDate={
                        fields.moreThanOneDay
                          ? moment(fields.from).endOf("month")
                          : undefined
                      }
                      renderInput={(props) => {
                        return <CustomInput {...props} sx={{ height: '100%' }} />
                      }}
                      shouldDisableDate={disableDates}
                      inputFormat="DD-MM-yyyy"
                      disabled={
                        loading ||
                        !(holiday.data && Array.isArray(holiday.data)) ||
                        holiday.loading
                      }
                      value={fields.to}
                      onChange={(e) =>
                        setFields({ ...fields, err: '', to: e, type: LEAVE_TYPE.MULTI_LEAVES })
                      }
                      type="date"
                    />
                  </Grid>
                  <Grid item xs={12} sm={8} >
                    <CustomRadioButtons
                      options={[...HALF_TYPE].splice(0, 2)}
                      value={fields.to_time_duration}
                      onChange={(e) => {
                        setFields({ ...fields, to_time_duration: e.target.value })
                      }}
                      sx={{ height: '56px', marginTop: '7px' }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Collapse>
          }


          {fields.leave_count <= 1 && fields.type != LEAVE_TYPE.MULTI_LEAVES && <>

            <Typography variant='h3' mt={3}>
              Leave Type:
            </Typography>
            <Box sx={{ display: "flex", flex: "none" }} mt={3}>
              {!leaveBalance.loading ? <CustomRadioButtons
                options={
                  LEAVE_NAMES.slice(0, 4).map((item) => ({
                    value: item.value,
                    label: `${item.label}(${leaveBalance?.data?.[item.value - 1]})`,
                    disabled: leaveBalance?.data?.[item.value - 1] < fields.leave_count
                  }))
                }
                value={fields.type}
                onChange={(e) => {
                  setFields({ ...fields, type: e.target.value, lwp: false })
                }}
              /> :
                <Skeleton sx={{ height: '50px' }} variant="rounded" width='100%' />
              }
            </Box>
          </>}
          {
            leaveBalance && !leaveBalance.loading && leaveBalance.data && fields.type != LEAVE_TYPE.MULTI_LEAVES && Array.isArray(leaveBalance.data) && leaveBalance.data.slice(0, 4).reduce((accumulator, currentValue) => (accumulator + currentValue), 0) <= 0 ?

              <> <Typography variant='h5' color="error" mt={3}>
                OOPS! No Leave Remained.
              </Typography>
                <Typography variant='h3' mt={3}>
                  Apply For LWP
                </Typography>
                <Box>
                  <Checkbox checked={fields.type == LEAVE_TYPE.LWP} onChange={(e) => { setFields({ ...fields, type: fields.type == LEAVE_TYPE.LWP ? null : LEAVE_TYPE.LWP }) }} />
                </Box>
              </>
              : <></>
          }
          <Typography variant='h3' mt={3}>
            Reason:
          </Typography>
          <CustomInput
            disabled={loading}
            value={fields.remarks}
            onChange={(e) =>
              setFields({ ...fields, err: '', remarks: e.target.value })
            }
            type="text"
            multiline
            placeholder="Write reason of leave..."
            rows={2}
          />


          <Box mt={3} sx={{ display: "flex", justifyContent: "flex-end" }}>

            <Box sx={{ display: "flex" }} mr={3}>
              <ResetButton
                loading={loading}
                type="reset"
                variant="text"
                onClick={() => { navigate("/calendar/") }}
                // color="primary"
                title=" Cancel Leave"
              >

              </ResetButton>
            </Box>
            <Box sx={{ display: "flex" }}>
              <SubmitButton
                disabled={loading}
                loading={loading}
                type="submit"
                variant="contained"
                color="primary"
                title=" Confirm Leave"
              >

              </SubmitButton>
            </Box>
          </Box>
        </Box>


        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-end", [theme.breakpoints.down('sm')]: { display: "none" } }} pl={3} pr={3}>
          <LeaveBalanceComponent userId={fields.userId} from={fields.from} selectedLeave={fields.type} leaveCount={fields.leave_count} />
        </Box>
      </Box>
    </Box>
  )
}
export default LeaveRaiseUi
