import { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { callApiAction } from "../../store/actions/commonAction"
import SalaryStructureUI from "./SalaryStructureUI"

import { getUserSalaryStructureData } from "../../apis/user.api"

const SalaryStructureController = ({ userId }) => {
    const user = useSelector(state => state.user)
    const dispatch = useDispatch()

    const [state, setState] = useState([])
    const [loading, setLoading] = useState(false)

    const [filters, setFilters] = useState({
        pageSize: 10,
        pageNo: 1,
        userId:userId??user.data._id,
        sort: "createdAt",
        sortDirection: -1
    })

    const fetchList = () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await getUserSalaryStructureData(filters),
            (response) => {
                setState(response)

                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
    }


    useEffect(() => {
        fetchList()
    }, [filters])

    return <SalaryStructureUI  filters={filters} setFilters={setFilters} s={setState} callBack={fetchList} loading={loading} state={state} />
}
export default SalaryStructureController