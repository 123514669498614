import { Grid, Icon, Paper, Skeleton, styled, Typography, useMediaQuery, useTheme } from '@mui/material'
import { Box } from '@mui/system'
import { memo, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchLeaveBalanceCountAction } from '../../store/actions/leaveBalanceAction'
import moment, { max } from 'moment'
import { LEAVE_NAMES } from '../../utils/leave.constants'
import DashBox from './Dashbox'

export const LeavecountBoxOuter = styled(Box)(({ theme, color, background }) => ({
  display: "flex",
  height: "100%",
  width: '100%',

  position: "relative",
  backdropFilter: "opacity(0)",
  alignItems: "center",
  color: "black",
  [theme.breakpoints.down('md')]: {
    flexDirection: "column"
  },
  "h5": {
    color: color

  },
  '&::before': {
    content: '" "',
    display: 'block',
    position: "absolute",
    left: "0px",
    backgroundColor: color,
    width: "3px",
    borderTopRightRadius: "3px",
    borderBottomRightRadius: "3px",    
    top: "25%",
    height:"50%",
    [theme.breakpoints.down('md')]: {
      left: "25%",
      top: "0%",
      width: "50%",
      height: "3px",
      borderRadius:"3px",
      borderTopRightRadius: "0px",
      borderBottomRightRadius: "0px",
    }
  },

  '&::after': {
    content: '" "',
    display: 'block',
    position: "absolute",
    left: "0px",
    zIndex: -1,
    top: "0px",
    backgroundColor: background,
    borderRadius: theme.shape.borderRadius,
    opacity: 1,
    width: "100%",
    height: "100%",

  }

}))


const LeaveCountBox = ({ title, subTitle, count, color, background }) => {
  return <LeavecountBoxOuter color={color} background={background} p={2}>
    <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }} p={1} pl={2}>
      <Typography variant='h5' >
        {title}
      </Typography>
      {/* <Typography variant='h6' fontWeight={"bold"}>
        ({subTitle})
      </Typography> */}
    </Box>
    <Box sx={{ display: "flex" }} p={1} pr={2}>
      <Typography variant='h1'>
        {count}
      </Typography>
    </Box>
  </LeavecountBoxOuter>
}

const LeaveBalance = ({ direction, date }) => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const { leaveBalance, user } = useSelector((state) => state)
  const userId = user.data?._id

  useEffect(() => {
    const leaveBalanceMonth = moment(leaveBalance.date).month();
    const currentMonth = moment().month();
    if (!leaveBalance.data || leaveBalance.data.length == 0 || leaveBalanceMonth !== currentMonth || leaveBalance.userId != userId) {
      dispatch(fetchLeaveBalanceCountAction({ userId, date: date?.valueOf() ?? moment().valueOf() }))
    }
  }, [userId, date])

  const isSmallView = useMediaQuery(theme.breakpoints.down('md'))

  direction = isSmallView ? "column" : direction

  return (



    <DashBox title={"Leave Balance"} showDate={true}>

      {/* <Box sx={{ display: 'flex'}} marginTop={2} > */}

      <Box p={3} sx={{ display: "flex", flex: 1 }}>

        {
          leaveBalance.loading && <Grid container spacing={2}>
            <Grid item xs={direction == "column" ? 6 : 3}>
              <Skeleton width="100%" height={"65px"} />
            </Grid>
            <Grid item xs={direction == "column" ? 6 : 3}>
              <Skeleton width="100%" height={"65px"} />
            </Grid>
            <Grid item xs={direction == "column" ? 6 : 3}>
              <Skeleton width="100%" height={"65px"} />
            </Grid>
            <Grid item xs={direction == "column" ? 6 : 3}>
              <Skeleton width="100%" height={"65px"} />
            </Grid>

          </Grid>
        }


        {
          !leaveBalance.loading && leaveBalance.data && Array.isArray(leaveBalance.data) && <Grid container spacing={2}>
            {leaveBalance.data?.map((leave, index) => (<Grid item  xs={12 / leaveBalance.data.length} >
              <LeaveCountBox
                title={LEAVE_NAMES[index].label}
                subTitle={LEAVE_NAMES[index].fullname}
                count={leave}
                color={LEAVE_NAMES[index].color}
                background={LEAVE_NAMES[index].background}
              />
            </Grid>
            ))}



          </Grid>
        }


      </Box>


    </DashBox>


  )

}
export default memo(LeaveBalance)
