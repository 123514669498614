import React, { useMemo, useState } from "react";
import { useDispatch } from "react-redux"
import { callApiAction } from "../../store/actions/commonAction";
import CreateAdvancePaymentsUI from "./CreateAdvancePaymentsUI"
import { closeModal } from "../../store/actions/modalAction";
import useValidate from "../../store/hooks/useValidator";
import { createAdvancePaymentApi } from "../../apis/reimbersement.api";
import moment from "moment";

const CreateAdvancePaymentController = ({ callBack }) => {

    const validate = useValidate()
    const dispatch = useDispatch()
    
    const [loading, setLoading] = useState(false)


    const [fields, setFields] = useState({
        amount: "",
        date: moment().toISOString(),
        purpose:"",
        userId:null,
    })

    const validationSchema = useMemo(() => ([
        {
            required: true,
            value: fields.amount,
            field: ' Amount '
        },
        {
            required: true,
            value: fields.userId,
            field: ' Employee '
        },
        {
            required: true,
            value: fields.purpose,
            field: 'Purpose',
        },
    ]), [fields])

    const submitData = (e) => {
        e.preventDefault()
        const validationResponse = validate(validationSchema)

        if (validationResponse === true) {
            setLoading(true)
            dispatch(callApiAction(
                async () => await createAdvancePaymentApi({ ...fields }),
                (response) => {
                    callBack()
                    setLoading(false)
                    dispatch(closeModal())
                },
                (err) => {
                    setLoading(false)
                    setFields({ ...fields, err })
                }
            ))
        } else {
            setFields({ ...fields, 'err': validationResponse })
        }
    }
    return <CreateAdvancePaymentsUI
        loading={loading}
        onSubmit={submitData}
        fields={fields}
        setFields={setFields}
    />
}

export default CreateAdvancePaymentController;