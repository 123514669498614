import { Button, CircularProgress, FormControlLabel, IconButton, Switch, Tooltip } from "@mui/material"
import { useDispatch, useSelector } from "react-redux";

import { useState } from "react";
import { callApiAction } from "../../../store/actions/commonAction";
import { updateUserInOfficePresenece, updateUserState } from "../../../apis/user.api";
import { callSnackBar } from "../../../store/actions/snackbarAction";
import { REIMBURSEMENT_STATUS, SNACK_BAR_VARIETNS, USER_ROLES } from "../../../utils/constants";
import { CancelRounded, CheckCircle, PriceCheck } from "@mui/icons-material";
import { updateReimberesementField } from "../../../apis/reimbersement.api";

const ReimburesementApprovalButton = ({ params, setParams }) => {

    const { user } = useSelector(state => state)

    const [loading, setLoading] = useState(false)
    const [status, setStatus] = useState(params.status)
    const dispatch = useDispatch()

    // const onUpdateField = useCallback(
    //     (fieldName, value, id, callBack = () => { }) => {



    //         dispatch(callApiAction(
    //             async () => await updateReimberesementField({ id, [fieldName]: value }),
    //             (response) => {
    //                 callBack(response)
    //             },
    //             (err) => {

    //                 dispatch(callSnackBar("server Error", SNACK_BAR_VARIETNS.error))
    //             }
    //         ))

    //     }
    //     , [dispatch])
    const onStatusChange = (selectedStatus) => {
        setLoading(true);
        dispatch(
            callApiAction(
                async () => await updateReimberesementField({ id: params._id, status: selectedStatus }),
                (response) => {

                    setLoading(false);
                    setStatus(selectedStatus)
                    dispatch(callSnackBar("Reimburesement status changed.", SNACK_BAR_VARIETNS.suceess))
                },
                (err) => {
                    setLoading(false);
                    dispatch(callSnackBar("Reimburesement status cant be changed.", SNACK_BAR_VARIETNS.error))
                }
            )
        );
    };
    if (loading)
        return <CircularProgress />
    if (status == REIMBURSEMENT_STATUS.approved && (user.data.role == USER_ROLES.hr || user.data.role == USER_ROLES.admin)) {
        return <Tooltip title={"Mark As Amount Paid"}>
            <Button variant="contained" color="success" size="small" onClick={() => {
                onStatusChange(REIMBURSEMENT_STATUS.paid)
            }}>
                {/* <PriceCheck color="success"></PriceCheck> */}
                Paid
            </Button>
        </Tooltip>
    }
    return <>





        {(status == REIMBURSEMENT_STATUS.pending && user.data._id != params.userId) ? <>
            <IconButton onClick={() => {
                onStatusChange(REIMBURSEMENT_STATUS.approved)
            }}>
                <CheckCircle color="success"></CheckCircle>
            </IconButton>
            <IconButton onClick={() => {
                onStatusChange(REIMBURSEMENT_STATUS.rejected)
            }}>
                <CancelRounded color="error"></CancelRounded>
            </IconButton>
        </>
            :
            <></>
        }
    </>
}
export default ReimburesementApprovalButton